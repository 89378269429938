import { http, getAPIDomain } from 'utils/http';
import { PrejoinResponse, ValidateRoomResponse } from 'features/room/types';
import { UserName } from 'features/users/types';

export const validateRoomService = async (roomId: string, token?: string) => {
  const response = await http.get<ValidateRoomResponse>(
    `/room-api/${roomId}/validate?domain=${getAPIDomain()}`,
    {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    }
  );

  return response.data;
};

export const loginRoomService = async (
  roomId: string,
  name?: UserName,
  initials?: string,
  token?: string
) => {
  const response = await http.post<PrejoinResponse>(
    `/room-api/${roomId}/prejoin`,
    {
      name,
      initials,
      domain: getAPIDomain(),
    },
    {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    }
  );

  return response.data;
};
