import { selectCaptionsDisplayed } from 'features/captions/captionsSlice';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';
import { call, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onCaptionsToggledSaga() {
  const captionsDisplayed: boolean = yield select(selectCaptionsDisplayed);

  if (captionsDisplayed) {
    yield call(eventBus.sendMessage, 'captionsEnabled', {});

    yield call(notification, i18n.t('notifications:captions_enabled'));
  } else {
    yield call(eventBus.sendMessage, 'captionsDisabled', {});

    yield call(notification, i18n.t('notifications:captions_disabled'));
  }
}
