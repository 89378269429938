import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  broadcasterLimitReachedNotificationUpdateRequested,
  clearAllActionNotifications,
  joinNotificationTriggered,
  raiseHandNotificationUpdateRequested,
  waitingEntryNotificationUpdateRequested,
} from 'features/notifications/actions';
import { clearAllActionNotificationsSaga } from 'features/notifications/sagas/clearAllActionNotificationsSaga';
import { handleRaiseHandNotificationSaga } from 'features/notifications/sagas/handleRaiseHandNotificationSaga';
import { handleWaitingEntryNotificationSaga } from 'features/notifications/sagas/handleWaitingEntryNotificationSaga';
import { handleJoinNotificationSaga } from 'features/notifications/sagas/handleJoinNotificationSaga';
import { onSimpleNotificationClosedSaga } from 'features/notifications/sagas/onSimpleNotificationClosedSaga';
import { simpleNotificationClosed } from 'features/notifications/notificationsSlice';
import { signalingUserJoined } from 'features/users/actions';
import { onUserJoinedSaga } from 'features/notifications/sagas/onUserJoinedSaga';
import { handleBroadcasterLimitReachedNotificationSaga } from './handleBroadcasterLimitReachedNotificationSaga';

export default function* notificationsWatcher() {
  yield takeLatest(clearAllActionNotifications, clearAllActionNotificationsSaga);
  yield takeEvery(signalingUserJoined, onUserJoinedSaga);

  yield takeEvery(joinNotificationTriggered, handleJoinNotificationSaga);
  yield takeEvery(raiseHandNotificationUpdateRequested, handleRaiseHandNotificationSaga);
  yield takeEvery(
    broadcasterLimitReachedNotificationUpdateRequested,
    handleBroadcasterLimitReachedNotificationSaga
  );
  yield takeEvery(waitingEntryNotificationUpdateRequested, handleWaitingEntryNotificationSaga);

  yield takeEvery(simpleNotificationClosed, onSimpleNotificationClosedSaga);
}
