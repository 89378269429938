import { splashScreenCloseRequested } from 'features/breakout-rooms/actions';
import { selectShowBreakoutsSplash } from 'features/breakout-rooms/breakoutRoomsSlice';
import { signalingRoomJoined } from 'features/room/actions';
import { normalizeBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/normalizeBreakoutRoomsSaga';
import { call, put, select } from 'redux-saga/effects';

export function* onRoomJoinedSaga(action: ReturnType<typeof signalingRoomJoined>) {
  const { breakoutRooms, breakout } = action.payload;

  if (breakoutRooms?.rooms.length && (breakoutRooms?.started || !breakout)) {
    yield call(normalizeBreakoutRoomsSaga, {
      rooms: breakoutRooms.rooms,
      unassignedUsers: breakoutRooms.unassignedUsers,
    });
  }

  const showSplash: boolean = yield select(selectShowBreakoutsSplash);

  if (showSplash) {
    yield put(splashScreenCloseRequested());
  }
}
