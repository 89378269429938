import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { addActionNotification } from 'features/notifications/addActionNotification';
import { signalingQuestionAsked } from 'features/qa/actions';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { ToolbarPanelType } from 'features/toolbar/types';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { FeatureFlag } from 'features/feature-set/types';

export function* onQuestionAskedSaga(action: ReturnType<typeof signalingQuestionAsked>) {
  if (action.payload.local) {
    // don't show the notification to the local user
    return;
  }

  const canModerate: boolean = yield call(hasPermissionsSaga, PermissionTypes.moderateQA);
  const canAnswer: boolean = yield call(hasPermissionsSaga, PermissionTypes.answerQA);

  const { id, question, user } = action.payload;

  if (canAnswer || canModerate) {
    const activePanel: ToolbarPanelType = yield select(selectToolbarPanel);

    const actionNotificationThunk: Action = yield call(
      addActionNotification,
      {
        content: i18n.t('notifications:qa.new_question_posted.content', {
          username: user.name,
          question,
        }),
        okText: i18n.t('notifications:qa.new_question_posted.btn_ok_label'),
        okAction:
          activePanel === 'qa'
            ? undefined
            : toolbarPanelOpened('qa', {
                questionId: id,
              }),
        initiatorId: user.id,
        scope: [FeatureFlag.qa],
        data: {
          questionId: id,
        },
      },
      {
        toastId: id,
      }
    );

    yield put(actionNotificationThunk);
  }
}
