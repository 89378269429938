import { call, put, select } from 'redux-saga/effects';
import {
  activeTabChanged,
  groupChatCounterReset,
  privateChatsCounterReset,
  publicChatCounterReset,
  selectPrivateGroupChat,
} from 'features/chat/chatSlice';
import { PermissionTypes } from 'features/permissions/types';
import { ChatTab, PrivateGroupChatState } from 'features/chat/types';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { User } from 'features/users/types';
import { selectLocalUser } from 'features/users/usersSlice';

export function* syncChatCountersWithPermissionsSaga() {
  const { role }: User = yield select(selectLocalUser);
  let shouldResetTab: ChatTab | null = null;

  const canChatInGeneral: boolean = yield call(hasPermissionsSaga, PermissionTypes.generalChat);
  if (!canChatInGeneral) {
    yield put(publicChatCounterReset());
    shouldResetTab = ChatTab.public;
  }

  const canChatInPrivate: boolean = yield call(hasPermissionsSaga, PermissionTypes.privateChat);
  if (!canChatInPrivate) {
    yield put(privateChatsCounterReset());
    shouldResetTab = ChatTab.public;
  }

  const privateGroupChat: PrivateGroupChatState = yield select(selectPrivateGroupChat);
  const canChatInPrivateGroup = privateGroupChat.roles.includes(role);
  if (!canChatInPrivateGroup) {
    yield put(groupChatCounterReset());
    if (!canChatInPrivate) {
      shouldResetTab = ChatTab.public;
    }
  }

  if (shouldResetTab !== null) {
    yield put(activeTabChanged(shouldResetTab));
  }
}
