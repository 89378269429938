import {
  Dialog,
  DialogCloseCallback,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';
import { BroadcastSetupReason } from 'features/room/types';
import { Trans, useTranslation } from 'react-i18next';
import BroadcastPreview from 'features/room/broadcast-setup/BroadcastPreview';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { PreviewFormValues } from 'features/join/publisher/JoinPreview';
import { useBroadcastSetupPreview } from 'utils/broadcast-setup/useBroadcastSetupPreview';
import { Button, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

type SetupFailureProps = {
  open: boolean;
  onClose: DialogCloseCallback;
  startBroadcast: SubmitHandler<PreviewFormValues>;
  reason: BroadcastSetupReason;
  roomName?: string;
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    margin: 0,
    width: '100%',
  },
  '& .MuiDialogContent-root': {
    paddingBottom: theme.spacing(6),
  },
  [theme.breakpoints.down('sm')]: {
    '& .TabPanelInner-root': {
      maxWidth: 'none',
    },
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3, 6),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3, 8),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8, 8),
  },
  '>:not(:first-of-type)': {
    marginLeft: theme.spacing(3),
  },
  '&& .MuiButton-root': {
    fontWeight: 700,
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
  },
}));

const PreviewModal = ({ open, onClose, startBroadcast, reason, roomName }: SetupFailureProps) => {
  const { t } = useTranslation('room');

  const formMethods = useBroadcastSetupPreview();
  const { handleSubmit } = formMethods;

  const renderText = () => reason === 'breakout' ? (
      <Trans
        i18nKey="room:broadcast_setup.preview.description_breakout"
        t={t}
        values={{
          roomName,
        }}
        components={{
          bold: <strong />,
        }}
      />
    ) : (
      t('broadcast_setup.preview.description')
    );

  return (
    <StyledDialog
      open={open}
      maxWidth="xs"
      aria-labelledby="broadcast-setup-preview-title"
      aria-describedby="broadcast-setup-preview-description"
      onClose={onClose}
      centered={false}
      scroll="paper"
    >
      <DialogTitle id="broadcast-setup-preview-title" onClose={onClose}>
        {reason === 'breakout'
          ? t('broadcast_setup.preview.title_breakout')
          : t('broadcast_setup.preview.title')}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="broadcast-setup-preview-description">
          {renderText()}
        </DialogContentText>
        <FormProvider {...formMethods}>
          <BroadcastPreview />
        </FormProvider>
      </DialogContent>
      <StyledDialogActions>
        <Button type="button" fullWidth variant="outlined" onClick={onClose}>
          {t('broadcast_setup.preview.btn_cancel_label')}
        </Button>
        <Button type="button" fullWidth variant="contained" onClick={handleSubmit(startBroadcast)}>
          {t('broadcast_setup.preview.btn_start_label')}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default PreviewModal;
