import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { openModal } from 'features/modal/modalSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onEndSessionCommandSaga() {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'endSession');

  const canEndSession: boolean = yield call(hasPermissionsSaga, PermissionTypes.endSession);

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'This action is disabled. You’ll need to edit this room’s properties to enable it.',
      'end-session'
    );

    return;
  }

  if (!canEndSession) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to end session',
      'end-session'
    );

    return;
  }

  yield put(openModal('endSessionConfirm'));
}
