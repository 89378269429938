import { createSearchParams, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectRoomToken } from 'features/room/roomSlice';

export const usePageReload = () => {
  const roomToken = useAppSelector(selectRoomToken);
  const location = useLocation();

  const createReloadUrl = () => {
    const newSearchParams = createSearchParams(location.search);

    if (roomToken.jwt) {
      newSearchParams.set('token', roomToken.jwt);
    }

    return `${location.pathname}?${newSearchParams.toString()}`;
  };

  return {
    createReloadUrl,
  };
};
