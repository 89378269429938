import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { selectDeviceType } from 'features/application/applicationSlice';
import { CaptionsParticipantName } from 'features/captions/CaptionsParticipantName';
import { selectCaptionsFontSize, selectCaptionsText } from 'features/captions/captionsSlice';
import { UserId } from 'features/users/types';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const lineHeightFactor = 1.4;
const avatarLineHeight = 40;

const Outer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'smallLayout',
})<{ smallLayout: boolean }>(({ smallLayout }) => ({
  position: 'relative',
  height: smallLayout ? '160px' : '190px',
}));

const Text = styled(Typography)({
  fontSize: 'inherit',
  display: 'inline',
  overflowWrap: 'anywhere',
});

const MeasureText = styled(Text)({
  display: 'block',
  position: 'absolute',
  bottom: '100%',
  width: '100%',
  pointerEvents: 'none',
  opacity: 0,
});

export const CaptionsText = () => {
  const { isMobile } = useBreakpoints();
  const deviceType = useAppSelector(selectDeviceType);
  const smallLayout = isMobile || deviceType === 'mobile';

  const textBox = useRef<HTMLDivElement | null>(null);
  const measureElement = useRef<HTMLSpanElement | null>(null);
  const [lines, setLines] = useState<Record<UserId, number>>({});

  const [order, chunks] = useAppSelector(selectCaptionsText);

  const fontSize = useAppSelector(selectCaptionsFontSize);

  const {
    typography: {
      body1: { lineHeight: themeLineHeight },
    },
  } = useTheme();

  useEffect(() => {
    if (!measureElement.current || !textBox.current) {
      return;
    }

    const lastSpeaker = order[order.length - 1];

    if (!lastSpeaker) {
      return;
    }

    const lineHeight = typeof themeLineHeight === 'number' ? themeLineHeight : lineHeightFactor;
    const lineHeightPx = Math.ceil(fontSize * lineHeight);

    measureElement.current.innerHTML = `${chunks[lastSpeaker].text} ${chunks[lastSpeaker].tempText}`;

    const textLines = Math.ceil(measureElement.current.clientHeight / lineHeightPx);

    const maxSingleSpeakerLines =
      Math.floor((textBox.current.clientHeight - avatarLineHeight) / lineHeightPx) || 1;
    const maxDoubleSpeakerLines =
      Math.floor((textBox.current.clientHeight - avatarLineHeight * 2) / lineHeightPx) || 1;

    if (textLines >= maxSingleSpeakerLines) {
      setLines({ [lastSpeaker]: maxSingleSpeakerLines });
    } else {
      const otherSpeaker = order.find((oid) => oid !== lastSpeaker);

      if (otherSpeaker && maxDoubleSpeakerLines - textLines > 0) {
        measureElement.current.innerHTML = `${chunks[otherSpeaker].text} ${chunks[otherSpeaker].tempText}`;

        const otherSpeakerLines = Math.ceil(measureElement.current.clientHeight / lineHeightPx);

        setLines({
          [lastSpeaker]: textLines,
          [otherSpeaker]: Math.min(otherSpeakerLines, maxDoubleSpeakerLines - textLines),
        });
      } else {
        setLines({ [lastSpeaker]: textLines });
      }
    }
  }, [chunks, order, fontSize, themeLineHeight]);

  const renderChunks = () =>
    order.map((userId) => {
      if (!userId) {
        return null;
      }

      const chunk = chunks[userId];
      const lineCount = lines[userId];

      return chunk && lineCount ? (
        <Box key={userId}>
          <Box>
            <CaptionsParticipantName participant={chunk.participant} />
          </Box>
          <Box
            style={{
              height: lineCount * fontSize * lineHeightFactor,
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              style={{ position: 'absolute', width: '100%', minHeight: '100%', bottom: 0, left: 0 }}
            >
              <Text>{chunk.text}</Text>
              <Text sx={{ opacity: 0.85 }}> {chunk.tempText}</Text>
            </Box>
          </Box>
        </Box>
      ) : null;
    });

  return (
    <Outer ref={textBox} smallLayout={smallLayout}>
      <MeasureText ref={measureElement}> </MeasureText>
      <Box>{renderChunks()}</Box>
    </Outer>
  );
};
