import { captionsToggled } from 'features/captions/captionsSlice';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onToggleCaptionsCommandSaga(show?: boolean) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'captions');

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'This feature is disabled. You’ll need to edit this room’s properties to enable it.',
      'captions'
    );

    return;
  }

  yield put(captionsToggled(show));
}
