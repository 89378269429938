import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import {
  localStreamCollapsed,
  selectCurrentLayoutMode,
} from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';
import { selectSingleUserCanSeeAdditionalControls } from 'features/layout/selectors';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useMinimizeLocalTileControl = (
  source: SourceDetails,
  clickCallback: (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => void
) => {
  const { t } = useTranslation('room');
  const dispatch = useAppDispatch();

  const onClick = (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    clickCallback?.(e);
    dispatch(localStreamCollapsed());
  };

  const enabled = useFeatureFlag('minimizeOwnTile');
  const layoutMode = useAppSelector(selectCurrentLayoutMode);
  const hideSingleUserControls = useAppSelector(selectSingleUserCanSeeAdditionalControls);

  const orderedStreams = useAppSelector(selectOrderedStreams);
  const maximizedStream = useAppSelector(selectMaximizedStream);

  const showControl = () => {
    if (source.kind !== 'local') {
      return false;
    }

    if (layoutMode === LayoutMode.auto) {
      return maximizedStream && orderedStreams.length > 0;
    }

    if (!enabled) {
      return false;
    }

    return !hideSingleUserControls;
  };

  return {
    show: showControl(),
    icon: 'minimize',
    text: t('tooltips.minimize_own'),
    onClick,
  };
};
