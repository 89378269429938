import { Action, AsyncThunkAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import {
  AsyncThunkFulfilledActionCreator,
  AsyncThunkRejectedActionCreator,
} from '@reduxjs/toolkit/dist/createAsyncThunk';
import { logger as customLogger } from 'utils/logger';
import { Dispatch } from 'redux';
import { listenerMiddleware } from 'store/listenerMiddleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// @FIXME fix type
export function initStore(preloadedState?: any) {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
      customLogger.error(error);

      Sentry.captureException(error, {
        level: 'fatal',
      });
    },
  });
  const store = configureStore({
    preloadedState,
    reducer: rootReducer,
    devTools: {
      actionsDenylist: [
        'systemStats/sendingStatsUpdated',
        'systemStats/receivingStatsUpdated',
        'systemStats/updateConnectionQuality',
        'layout/contentWidthChanged',
        'layoutConfig/showHoverChildrenChanged',
        'signaling/pong',
        'signaling/activeSpeakerChanged',
      ],
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['streaming/remoteTrackReceived'],
        },
      })
        .prepend(listenerMiddleware.middleware)
        .concat([
          sagaMiddleware,
          // ...(webrtcAdapter.browserDetails.browser === 'safari' ? [logger] : []),
        ]),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
  });

  sagaMiddleware.run(rootSaga);
  return store;
}

export const store = initStore();
export const { getState, dispatch } = store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// re-export of private type from redux-toolkit
type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export type SagaWrappedThunkAction<
  Returned,
  ThunkArg,
  ThunkApiConfig extends AsyncThunkConfig
> = ReturnType<AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>>;

export type SagaThunkAction<Returned, ThunkArg, ThunkApiConfig extends AsyncThunkConfig> =
  | ReturnType<AsyncThunkFulfilledActionCreator<Returned, ThunkArg>>
  | ReturnType<AsyncThunkRejectedActionCreator<ThunkArg, ThunkApiConfig>>;
