import React from 'react';
import Input from 'components/Input';
import { InputAdornment, InputBaseProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from 'components/Icon';

export type SearchInputProps = InputBaseProps;

const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : theme.palette.grey['200'],
  '&.Mui-focused ': {
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : theme.palette.common.white,
  },
  '& .MuiInputBase-input': {
    borderColor: 'transparent',
  },
  '& .MuiInputAdornment-root': {
    borderColor: 'transparent',
  },
}));

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (props: SearchInputProps, ref) => (
    <StyledInput
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <Icon name="search" />
        </InputAdornment>
      }
      {...props}
      ref={ref}
    />
  )
);

export default SearchInput;
