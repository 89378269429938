import cx from 'clsx';
import { MutableRefObject, useState, VideoHTMLAttributes } from 'react';
import { Video } from 'features/streaming/components/media/Video';
import { useRemoteVideo } from 'features/streaming/useRemoteVideo';
import { SubscriberMid } from 'features/streaming/types';

export const RemoteVideo = ({
  mid,
  ...videoProps
}: { mid: SubscriberMid } & VideoHTMLAttributes<any>) => {
  const videoRef = useRemoteVideo(mid);

  const [fitment, setFitment] = useState('crop');

  const onResize = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    if (e.target && e.target instanceof HTMLVideoElement) {
      const [width, height] = [e.target.videoWidth, e.target.videoHeight];

      if (width > height) {
        setFitment('crop');
      } else {
        setFitment('fit');
      }
    }
  };

  return (
    <Video
      ref={videoRef as MutableRefObject<HTMLVideoElement>}
      onResize={onResize}
      muted
      playsInline
      {...videoProps}
      className={cx(videoProps.className, `fitment-${fitment}`)}
    />
  );
};
