import { useAppSelector } from 'store/hooks';
import { PermissionTypes } from 'features/permissions/types';
import { selectLocalUser } from 'features/users/usersSlice';
import { useCallback } from 'react';
import {
  HasDynamicPermissionsOptions,
  lookupDynamicPermission,
} from 'features/permissions/utils/lookupDynamicPermission';
import { refinePermissions } from 'features/permissions/utils/refinePermissions';
import { HasPermissionsOptions } from 'features/permissions/utils/checkPermissions';
import { selectPermissionsState } from 'features/permissions/permissionsSlice';

export const usePermissions = () => {
  const permissionsState = useAppSelector(selectPermissionsState);
  const localUser = useAppSelector(selectLocalUser);

  const hasPermissions = useCallback(
    (
      permissions: PermissionTypes | PermissionTypes[],
      { targetRole, role, targetUser }: HasPermissionsOptions = {}
    ) =>
      refinePermissions(permissions, {
        permissionsState,
        role,
        targetRole,
        targetUser,
        localUser,
      }),
    [localUser, permissionsState]
  );

  const hasDynamicPermissions = useCallback(
    (permission: PermissionTypes, { targetUser }: HasDynamicPermissionsOptions) => {
      let { dynamicPermissions } = localUser;

      if (targetUser) {
        dynamicPermissions = targetUser.dynamicPermissions;
      }

      return lookupDynamicPermission(permission, dynamicPermissions);
    },
    [localUser]
  );

  return {
    hasPermissions,
    hasDynamicPermissions,
  };
};
