import { createAction } from '@reduxjs/toolkit';
import {
  User,
  UserHandLoweredPayload,
  UserHandRaisedPayload,
  UserId,
  UserLeftPayload,
} from 'features/users/types';
import { EventInitiator } from 'services/signaling';

export const signalingUserJoined = createAction<User>('signaling/userJoined');
export const usersListReceived = createAction<User[]>('signaling/userJoinedList');
export const signalingUserLeft = createAction<UserLeftPayload>('signaling/userLeft');

export const signalingHandRaised = createAction<UserHandRaisedPayload>('signaling/handRaised');
export const signalingHandLowered = createAction<UserHandLoweredPayload>('signaling/handLowered');

export const kickUser = createAction<UserId>('users/kick');
export const signalingUserKicked = createAction<EventInitiator>('signaling/kicked');

// room entry control
export const admitEntry = createAction<UserId>('users/admitEntry');
export const rejectEntry = createAction<UserId>('users/rejectEntry');
export const bulkRejectEntry = createAction('users/bulkRejectEntry');
export const bulkAdmitEntry = createAction('users/bulkAdmitEntry');

export const signalingWaitingUserJoined = createAction<User>('signaling/waitingUserJoined');
export const signalingWaitingUserLeft = createAction<UserLeftPayload>('signaling/waitingUserLeft');
export const signalingWaitingUsersUpdated = createAction<User[]>('signaling/waitingUsersUpdated');
export const clearWaitingUsersList = createAction('users/clearWaitingUsersList');
export const signalingAccessRejected = createAction('signaling/accessRejected');
export const signalingBulkAccessRejected = createAction('signaling/bulkAccessRejected');
