import { MenuFixedItems, ToolbarNavRoot } from 'features/toolbar/Toolbar/styles';
import CameraControl from 'features/toolbar/Toolbar/controls/CameraControl';
import MicControl from 'features/toolbar/Toolbar/controls/MicControl';
import { ToolbarOrientation } from 'features/toolbar/types';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

export type MobileNavProps = {
  orientation: ToolbarOrientation;
  onHamburgerClick: () => void;
};

const MobileNav = ({ orientation, onHamburgerClick }: MobileNavProps) => {
  const { t } = useTranslation('room');

  const { hasPermissions } = usePermissions();

  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);

  return (
    <ToolbarNavRoot orientation={orientation}>
      <MenuFixedItems orientation={orientation}>
        {allowBroadcast && (
          <>
            <CameraControl />
            <MicControl />
          </>
        )}
      </MenuFixedItems>
      <ToolbarMenuItem
        id="mobile-menu"
        ariaLabel={t('toolbar.mobile_menu_trigger')}
        icon="settings1"
        onClick={onHamburgerClick}
      />
    </ToolbarNavRoot>
  );
};

export default MobileNav;
