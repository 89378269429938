import { createAction } from '@reduxjs/toolkit';
import { UserIdPayload } from 'features/users/types';
import { RaiseHandNotificationUpdateReason } from 'features/notifications/types';

export const clearAllActionNotifications = createAction('actionNotifications/clearAll');

export const joinNotificationTriggered = createAction<UserIdPayload>('notifications/joinTriggered');

export const waitingEntryNotificationUpdateRequested = createAction<UserIdPayload | undefined>(
  'actionNotifications/waitingEntryUpdateRequested'
);

export const raiseHandNotificationUpdateRequested = createAction<{
  reason: RaiseHandNotificationUpdateReason;
}>('actionNotifications/raiseHandUpdateRequested');

export const broadcasterLimitReachedNotificationUpdateRequested = createAction(
  'actionNotifications/broadcasterLimitReachedUpdateRequested'
);
