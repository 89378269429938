import { broadcastSetupCancelled } from 'features/room/actions';
import { onRemoteTrackReceivedSaga } from 'features/streaming/sagas/onRemoteTrackReceivedSaga';
import { onStreamingErrorSaga } from 'features/streaming/sagas/onStreamingErrorSaga';
import { toggleLocalScreenshareSaga } from 'features/streaming/sagas/toggleLocalScreenshareSaga';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { toggleLocalVideoSaga } from 'features/streaming/sagas/toggleLocalVideoSaga';
import { toggleLocalAudioSaga } from 'features/streaming/sagas/toggleLocalAudioSaga';
import {
  audioMutedRemotely,
  audioUnmuteAsked,
  localAudioToggled,
  localVideoToggled,
  muteRemoteAudio,
  publisherJoined,
  publisherLeft,
  subscriberAttached,
  askUnmuteRemoteAudio,
  videoroomError,
  remoteAudioUnmuteAccepted,
  streamingDeviceChanged,
  localScreenshareToggled,
  muteRemoteVideo,
  videoMutedRemotely,
  videoUnmuteAsked,
  remoteVideoUnmuteAccepted,
  askUnmuteRemoteVideo,
  publishingConnectionEstablished,
  remoteTrackReceived,
  receivingPluginCleanup,
  streamingError,
  pluginHandleAttachFailed,
  bulkMuteRemoteAudio,
} from 'features/streaming/actions';
import { onPublisherJoinedSaga } from 'features/streaming/sagas/onPublisherJoinedSaga';
import { onPublisherLeavingSaga } from 'features/streaming/sagas/onPublisherLeavingSaga';
import { onSubscriberAttachedSaga } from 'features/streaming/sagas/onSubscriberAttachedSaga';
import { onVideoroomErrorSaga } from 'features/streaming/sagas/onVideoroomErrorSaga';
import { changeStreamingDeviceSaga } from 'features/streaming/sagas/changeStreamingDeviceSaga';
import { hdStreamToggled } from 'features/streaming/streamingSlice';
import { mediaDevicesChangeStarted } from 'features/user-media/actions';
import { handleDeviceAutoChangeSaga } from 'features/streaming/sagas/handleDeviceAutoChangeSaga';
import { toggleHDStreamSaga } from 'features/streaming/sagas/toggleHDStreamSaga';
import { muteRemoteAudioSaga } from 'features/streaming/sagas/remote-audio/muteRemoteAudioSaga';
import { onAudioMutedRemotelySaga } from 'features/streaming/sagas/remote-audio/onAudioMutedRemotelySaga';
import { onAudioUnmuteAskedSaga } from 'features/streaming/sagas/remote-audio/onAudioUnmuteAskedSaga';
import { askUnmuteRemoteAudioSaga } from 'features/streaming/sagas/remote-audio/askUnmuteRemoteAudioSaga';
import { unmuteAudioSaga } from 'features/streaming/sagas/unmuteAudioSaga';
import { muteRemoteVideoSaga } from 'features/streaming/sagas/remote-video/muteRemoteVideoSaga';
import { onVideoMutedRemotelySaga } from 'features/streaming/sagas/remote-video/onVideoMutedRemotelySaga';
import { askUnmuteRemoteVideoSaga } from 'features/streaming/sagas/remote-video/askUnmuteRemoteVideoSaga';
import { onVideoUnmuteAskedSaga } from 'features/streaming/sagas/remote-video/onVideoUnmuteAskedSaga';
import { unmuteVideoSaga } from 'features/streaming/sagas/unmuteVideoSaga';
import { startPublishingSaga } from 'features/streaming/sagas/startPublishingSaga';
import { onBroadcastSetupCancelledSaga } from 'features/streaming/sagas/onBroadcastSetupCancelledSaga';
import { onReceivingPluginCleanupSaga } from 'features/streaming/sagas/onReceivingPluginCleanupSaga';
import { handlePluginAttachFailureSaga } from 'features/streaming/sagas/handlePluginAttachFailureSaga';
import { bulkMuteRemoteAudioSaga } from 'features/streaming/sagas/remote-audio/bulkMuteRemoteAudioSaga';

export default function* streamingWatcher() {
  // local interactions
  yield takeLatest(localVideoToggled, toggleLocalVideoSaga);
  yield takeLatest(localAudioToggled, toggleLocalAudioSaga);
  yield takeLatest(localScreenshareToggled, toggleLocalScreenshareSaga);

  yield takeEvery(publisherJoined, onPublisherJoinedSaga);
  yield takeEvery(publisherLeft, onPublisherLeavingSaga);

  yield takeEvery(publishingConnectionEstablished, startPublishingSaga);

  yield takeEvery(subscriberAttached, onSubscriberAttachedSaga);
  yield takeEvery(videoroomError, onVideoroomErrorSaga);
  yield takeEvery(streamingError, onStreamingErrorSaga);

  yield takeLatest(streamingDeviceChanged, changeStreamingDeviceSaga);
  yield takeLatest(mediaDevicesChangeStarted, handleDeviceAutoChangeSaga);

  yield takeLatest(hdStreamToggled, toggleHDStreamSaga);

  // remote audio mute
  yield takeLatest(muteRemoteAudio, muteRemoteAudioSaga);
  yield takeLatest(bulkMuteRemoteAudio, bulkMuteRemoteAudioSaga);
  yield takeLatest(audioMutedRemotely, onAudioMutedRemotelySaga);

  // remote audio unmute
  yield takeLatest(askUnmuteRemoteAudio, askUnmuteRemoteAudioSaga);
  yield takeLatest(audioUnmuteAsked, onAudioUnmuteAskedSaga);
  yield takeLatest(remoteAudioUnmuteAccepted, unmuteAudioSaga);

  // remote video mute
  yield takeLatest(muteRemoteVideo, muteRemoteVideoSaga);
  yield takeLatest(videoMutedRemotely, onVideoMutedRemotelySaga);

  // remote video unmute
  yield takeLatest(askUnmuteRemoteVideo, askUnmuteRemoteVideoSaga);
  yield takeLatest(videoUnmuteAsked, onVideoUnmuteAskedSaga);
  yield takeLatest(remoteVideoUnmuteAccepted, unmuteVideoSaga);

  yield takeEvery(broadcastSetupCancelled, onBroadcastSetupCancelledSaga);

  yield takeEvery(remoteTrackReceived, onRemoteTrackReceivedSaga);
  yield takeEvery(receivingPluginCleanup, onReceivingPluginCleanupSaga);

  yield takeEvery(pluginHandleAttachFailed, handlePluginAttachFailureSaga);
}
