import { Action, PayloadAction } from '@reduxjs/toolkit';
import { PermissionChangePayload, UserId } from 'features/users/types';
import { call, put, select } from 'redux-saga/effects';
import { selectLocalUserId, userLeft } from 'features/users/usersSlice';
import { broadcastSetupEnded, selectIsBroadcastSetupActive } from 'features/room/roomSlice';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';
import { onUserStoppedBroadcastingSaga } from 'features/layout/sagas/onUserStoppedBroadcastingSaga';
import { closeModal, selectActiveModalName } from 'features/modal/modalSlice';
import { ModalName } from 'features/modal/types';
import { selectNotificationsByScope } from 'features/notifications/notificationsSlice';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';
import { NotificationId } from 'features/notifications/types';

export function* onBroadcastPermissionRevokedSaga({
  payload,
}: PayloadAction<PermissionChangePayload>) {
  const { initiator, id } = payload;

  const localUserId: UserId = yield select(selectLocalUserId);

  if (localUserId === id && initiator) {
    yield call(eventBus.sendMessage, 'permissionsChanged', {}, { broadcast: false });

    // this is the most reliable place to close the settings modal on permission change
    const activeModal: ModalName = yield select(selectActiveModalName);
    if (activeModal === 'roomSettings') {
      yield put(closeModal('roomSettings'));
    }

    // stop the broadcast setup if any
    const isBroadcastSetupActive: boolean = yield select(selectIsBroadcastSetupActive);
    if (isBroadcastSetupActive) {
      yield put(broadcastSetupEnded());
      yield call(RTCClient.publishingFeed.hangup);
      return;
    }

    yield call(RTCClient.publishingFeed.hangup);

    // close broadcast related notifications if any
    const broadcastRelatedNotifications: NotificationId[] = yield select(
      selectNotificationsByScope,
      'broadcast'
    );

    const cleanupNotificationsThunk: Action = yield call(
      closeActionNotifications,
      broadcastRelatedNotifications
    );
    yield put(cleanupNotificationsThunk);
  }

  yield call(onUserStoppedBroadcastingSaga, userLeft(id));
}
