import { useEffect, useRef } from 'react';
import { Box, BoxProps } from '@mui/material';

type LocalVideoProps = BoxProps<
  'video',
  {
    mirror?: boolean;
    srcObject?: MediaStream | null;
  }
>;

function LocalVideo({ srcObject = null, className, mirror, ...props }: LocalVideoProps) {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.srcObject = srcObject;
  }, [srcObject]);

  return (
    <Box
      component="video"
      muted
      autoPlay
      ref={ref}
      playsInline
      sx={mirror ? { transform: 'scaleX(-1)' } : {}}
      {...props}
    />
  );
}

export default LocalVideo;
