import { Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { selectTileDimensions } from 'features/layout/features/dimensions/dimensionsSlice';
import { selectPageStreams } from 'features/layout/features/pagination/paginationSlice';
import PagedTile from 'features/layout/tiles/PagedTile';
import { useHoverParent } from 'features/layout/useHoverParent';
import { makeSourceKey } from 'features/streaming/utils';
import React, { useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useAppSelector } from 'store/hooks';

const TileContainer = styled(TransitionGroup)<{
  cols: number;
  rows: number;
}>(({ cols, rows }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `repeat(${cols || 1}, 1fr)`,
  gridTemplateRows: `repeat(${rows || 1}, 1fr)`,
}));

export const PagedTiles = React.memo(() => {
  const { cols, rows } = useAppSelector(selectTileDimensions);
  const streams = useAppSelector(selectPageStreams);

  const tiles = useMemo(
    () =>
      streams.map((source) => (
        <Fade
          key={makeSourceKey(source)}
          timeout={source.kind === 'local' ? 0 : { enter: 350, exit: 0 }}
        >
          <div style={{ display: 'flex' }}>
            <PagedTile source={source} />
          </div>
        </Fade>
      )),
    [streams]
  );

  const { className, ...mouseCallbacks } = useHoverParent('tiles');

  return (
    <TileContainer className={className} {...mouseCallbacks} cols={cols} rows={rows}>
      {tiles}
    </TileContainer>
  );
});
