import { GDPRConfig, RoomToken } from 'features/room/types';
import { selectIsSDKConnected } from 'features/sdk/sdkStateSlice';
import { put, select } from 'redux-saga/effects';
import { roomStatusUpdated, selectConsentConfig, selectRoomToken } from 'features/room/roomSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { roomJoinRequest } from 'features/room/actions';
import { getConsentFlag } from 'utils/consentFlag';

export function* prepareJoinScreen() {
  const sdkConnected: boolean = yield select(selectIsSDKConnected);
  // if we're in an SDK session, it might not be initialized fully upon signaling login;
  // in that case, we display loader until SDK is configured and then run this saga once againg
  // setting the correct state;

  if (!sdkConnected) {
    return;
  }

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    yield put(roomJoinRequest());
    return;
  }

  const token: RoomToken = yield select(selectRoomToken);
  const loginRequired = !token.parsedData?.u;

  const consentConfig: GDPRConfig | null = yield select(selectConsentConfig);

  const consentFlag = getConsentFlag();

  const consentRequired = consentConfig && !consentFlag;

  if (loginRequired || consentRequired) {
    yield put(roomStatusUpdated('login'));
    return;
  }

  yield put(roomStatusUpdated('join'));
}
