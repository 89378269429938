import { createAction, prepareAutoBatched } from '@reduxjs/toolkit';
import {
  PinControlToggledPayload,
  SourceDetails,
  StreamMaximizedPayload,
} from 'features/layout/types';

export const streamMaximized = createAction<StreamMaximizedPayload>('layout/streamMaximized');
export const streamMinimized = createAction('layout/streamMinimized');
export const orderChanged = createAction(
  'layout/orderChanged',
  prepareAutoBatched<SourceDetails[]>()
);
export const pinControlToggled = createAction<PinControlToggledPayload>('layout/pinControlToggled');
