import { call, fork, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { SignalingRoomJoinedPayload } from 'services/signaling';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { initSframeClientSaga } from 'features/e2ee/sagas/initSframeClientSaga';
import { generateRoomE2eeCode } from 'features/e2ee/sagas/generateRoomE2eeCode';
import { bulkExchangeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkExchangeEncryptionKeysSaga';
import { bulkComputeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkComputeEncryptionKeysSaga';
import { exchangeEncryptionKeysSaga } from 'features/e2ee/sagas/exchangeEncryptionKeysSaga';

export function* onRoomJoinedSaga(action: PayloadAction<SignalingRoomJoinedPayload>) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);

  if (e2eeEnabled) {
    yield fork(generateRoomE2eeCode);

    const {
      users,
      usersAwaitingEntry,
      user: { e2eeId },
      recorder,
    } = action.payload;

    yield call(initSframeClientSaga, e2eeId!);

    yield fork(bulkExchangeEncryptionKeysSaga, users);

    if (recorder) {
      yield fork(exchangeEncryptionKeysSaga, {
        id: recorder.id,
        e2eePublicKey: recorder.e2eePublicKey,
      });
    }

    if (usersAwaitingEntry.length) {
      yield call(bulkComputeEncryptionKeysSaga, usersAwaitingEntry);
    }
  }
}
