import { CaptionsOptions } from 'features/application/types';
import { fontSizeChanged, spokenLanguageChanged } from 'features/captions/captionsSlice';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';

export function* onConfigureCaptionsCommandSaga(options: Partial<CaptionsOptions>) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'captions');

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'This feature is disabled. You’ll need to edit this room’s properties to enable it.',
      'captions'
    );

    return;
  }

  if (options.fontSize) {
    const fontSizePx = { small: 16, medium: 24, large: 32 }[options.fontSize];

    yield put(fontSizeChanged(fontSizePx));
  }

  if (options.spokenLanguage) {
    yield put(spokenLanguageChanged(options.spokenLanguage));
  }
}
