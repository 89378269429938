import { localScreenshareToggled } from 'features/streaming/actions';
import { selectLocalScreenshareBroadcasting } from 'features/streaming/streamingSlice';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { usePermissions } from 'features/permissions/usePermissions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';

const ScreenshareControl = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isBroadcasting = useAppSelector(selectLocalScreenshareBroadcasting);

  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('screenshare');

  const permissionsGranted = hasPermissions(PermissionTypes.screenshare);
  const isFeatureAvailable = isFeatureEnabled && permissionsGranted;

  if (!isFeatureAvailable) {
    return null;
  }

  const toggleScreenshare = async () => {
    dispatch(localScreenshareToggled());
  };

  const tooltip = isBroadcasting
    ? t('room:toolbar.stop_screen_share')
    : t('room:toolbar.screen_share');

  return (
    <ToolbarMenuItem
      id="screenshare"
      overlay={tooltip}
      active={isBroadcasting}
      ariaLabel={tooltip}
      icon="share-screen"
      onClick={toggleScreenshare}
    />
  );
};

export default ScreenshareControl;
