import { useEffect } from 'react';
import { RoomLoginForm } from 'features/room/index';
import { useAppSelector } from 'store/hooks';
import { selectRoomStatus } from 'features/room/roomSlice';
import Room from 'features/room/components/Room';
import JoinScreen from 'features/join/JoinScreen';
import { selectTopbarHeight } from 'features/room/selectTopbarHeight';
import { selectToolbarWidth } from 'features/toolbar/toolbarSlice';
import BrandedLayout from 'layouts/BrandedLayout';

const RoomContainer = () => {
  const roomStatus = useAppSelector(selectRoomStatus);

  const topbarHeight = useAppSelector(selectTopbarHeight);
  const toolbarWidth = useAppSelector(selectToolbarWidth);

  useEffect(() => {
    document.documentElement.style.setProperty('--topbar-height', `${topbarHeight}px`);
  }, [topbarHeight]);

  useEffect(() => {
    document.documentElement.style.setProperty('--toolbar-width', `${toolbarWidth}px`);
  }, [toolbarWidth]);

  const renderContent = () => {
    if (roomStatus === 'login') {
      return (
        <BrandedLayout>
          <RoomLoginForm />
        </BrandedLayout>
      );
    }

    if (roomStatus === 'join') {
      return <JoinScreen />;
    }

    if (roomStatus === 'joined') {
      return <Room />;
    }

    return null;
  };

  return renderContent();
};

export default RoomContainer;
