import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { selectLocalScreenshareBroadcasting } from 'features/streaming/streamingSlice';
import { call, select } from 'redux-saga/effects';
import { eventBus, ScreenshareMessageTypes } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';

export function* onControlScreenshareCommandSaga(command: ScreenshareMessageTypes) {
  const canScreenshare: boolean = yield call(hasPermissionsSaga, PermissionTypes.screenshare);

  if (!canScreenshare) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to screenshare',
      'screenshare'
    );

    return;
  }

  switch (command) {
    case 'startScreenshare': {
      const isBroadcasting: boolean = yield select(selectLocalScreenshareBroadcasting);

      if (!isBroadcasting) {
        yield call(RTCClient.screensharingFeed.shareScreen);
      }
      break;
    }
    case 'stopScreenshare':
      yield call(RTCClient.screensharingFeed.stopScreenshare);
      break;

    default:
      break;
  }
}
