import { PermissionsState, PermissionTypes } from 'features/permissions/types';
import { User } from 'features/users/types';
import { lookupDynamicPermission } from 'features/permissions/utils/lookupDynamicPermission';

export interface TargetRoleConfig {
  coverage: 'all' | 'any';
}

export type HasPermissionsTargetRole = string | TargetRoleConfig;

export interface LookupPermissionOptions {
  permissionsState: PermissionsState;
  permission: PermissionTypes;
  role: string;
  targetRole?: HasPermissionsTargetRole;
  dynamicPermissions?: PermissionTypes[];
}

export interface CheckPermissionsOptions extends Omit<LookupPermissionOptions, 'permission'> {
  permissions: PermissionTypes | PermissionTypes[];
}

export interface HasPermissionsOptions {
  targetRole?: HasPermissionsTargetRole;
  role?: string;
  targetUser?: User;
}

const lookupPermission = (options: LookupPermissionOptions) => {
  const {
    permissionsState: { permissionsMap, targetRolesMap, allRoles },
    permission,
    targetRole,
    role,
    dynamicPermissions,
  } = options;

  if (dynamicPermissions) {
    const granted = lookupDynamicPermission(permission, dynamicPermissions);
    if (granted) {
      return true;
    }
  }

  if (permissionsMap[role][permission]) {
    return true;
  }

  if (targetRole) {
    if (typeof targetRole === 'string') {
      return Boolean(permissionsMap[role][`${permission}_${targetRole}`]);
    }

    const targetPermission = targetRolesMap[role][permission];
    if (!targetPermission) {
      return false;
    }

    if (targetRole.coverage === 'all') {
      return allRoles.length === targetPermission.length;
    }

    if (targetRole.coverage === 'any') {
      return targetPermission.length !== 0;
    }
  }

  return false;
};

export const checkPermissions = ({
  permissions,
  targetRole,
  permissionsState,
  role,
  dynamicPermissions,
}: CheckPermissionsOptions) => {
  if (Array.isArray(permissions)) {
    return permissions.some((permission) =>
      lookupPermission({ permission, targetRole, role, permissionsState, dynamicPermissions })
    );
  }

  return lookupPermission({
    permission: permissions,
    targetRole,
    role,
    permissionsState,
    dynamicPermissions,
  });
};
