import { splashScreenOpenRequested } from 'features/breakout-rooms/actions';
import {
  selectBreakoutTargetRoom,
  splashScreenOpened,
} from 'features/breakout-rooms/breakoutRoomsSlice';
import { selectIsBreakoutRoom } from 'features/room/roomSlice';
import { put, select } from 'redux-saga/effects';

export function* onSplashScreenOpenRequestedSaga(
  action: ReturnType<typeof splashScreenOpenRequested>
) {
  let showSplash = false;
  const screenName = action.payload;

  if (screenName === 'started' || screenName === 'joining') {
    const targetRoom: string = yield select(selectBreakoutTargetRoom);

    if (targetRoom) {
      showSplash = true;
    }
  }

  if (screenName === 'ended' || screenName === 'leaving') {
    const inBreakout: boolean = yield select(selectIsBreakoutRoom);

    if (inBreakout) {
      showSplash = true;
    }
  }

  if (showSplash) {
    yield put(splashScreenOpened(action.payload));
  }
}
