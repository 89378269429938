import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { PublishingFeedStatus, PublishingKind } from 'utils/webrtc/types';
import {
  activeSpeakerChanged,
  publisherJoined,
  publisherJoinedWithoutBroadcast,
} from 'features/streaming/actions';
import invert from 'lodash.invert';
import { UserId } from 'features/users/types';
import {
  FeedId,
  GeneralStreamingUIState,
  LocalFeedUIState,
  LocalFeedUpdated,
  MediaStreamType,
  RemoteFeedUIState,
  RemoteFeedUpdated,
  StreamingState,
} from './types';

// @TODO split into publishing and screen-sharing states
export const localFeedInitialState: LocalFeedUIState = {
  status: PublishingFeedStatus.idle,
  hdStream: false,
  audioEnabled: false,
  videoEnabled: false,
  audioBroadcasting: false,
  videoBroadcasting: false,
  audioProcessing: false,
  videoProcessing: false,
  streams: [],
  publisherJoined: false,
};

export const remoteFeedInitialState: RemoteFeedUIState = {
  feedIdByMid: {},
  streamsByFeedId: {},
};

export const initialState: StreamingState = {
  activeSpeakerId: '',
  userIdByFeedId: {},
  feedIdByUserId: {},
  broadcastingByUserId: {},
  screensharingByUserId: {},
  publishingFeed: { ...localFeedInitialState },
  screensharingFeed: { ...localFeedInitialState },
  remoteFeed: remoteFeedInitialState,
};

export const streamingSlice = createSlice({
  name: 'streaming',
  initialState,
  reducers: {
    remoteFeedConnected(state, action: PayloadAction<RemoteFeedUpdated>) {
      state.remoteFeed = action.payload;
    },
    localFeedDisconnected(state) {
      state.publishingFeed = localFeedInitialState;
    },
    localFeedUpdated(
      state,
      action: PayloadAction<{ kind: PublishingKind; data: LocalFeedUpdated }>
    ) {
      state[`${action.payload.kind}Feed`] = Object.assign(
        state[`${action.payload.kind}Feed`],
        action.payload.data
      );
    },
    generalDataUpdated(state, action: PayloadAction<GeneralStreamingUIState>) {
      state.userIdByFeedId = action.payload.userIdByFeedId;
      state.feedIdByUserId = invert(action.payload.userIdByFeedId);
      state.broadcastingByUserId = action.payload.broadcastingByUserId;
      state.screensharingByUserId = action.payload.screensharingByUserId;
    },
    remoteFeedUpdated(state, action: PayloadAction<RemoteFeedUpdated>) {
      state.remoteFeed = action.payload;
    },
    remoteFeedDisconnected(state) {
      state.remoteFeed = remoteFeedInitialState;
    },
    deviceProcessingStarted(state, action: PayloadAction<'audio' | 'video'>) {
      if (action.payload === 'audio') {
        state.publishingFeed.audioProcessing = true;
      } else {
        state.publishingFeed.videoProcessing = true;
      }
    },
    deviceProcessingStopped(state, action: PayloadAction<'audio' | 'video'>) {
      if (action.payload === 'audio') {
        state.publishingFeed.audioProcessing = false;
      } else {
        state.publishingFeed.videoProcessing = false;
      }
    },
    hdStreamToggled(state) {
      state.publishingFeed.hdStream = !state.publishingFeed.hdStream;
    },
    activeSpeakerReset(state) {
      state.activeSpeakerId = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activeSpeakerChanged, (state, action) => {
        state.activeSpeakerId = action.payload.id;
      })
      .addCase(publisherJoined, (state) => {
        state.publishingFeed.publisherJoined = true;
      })
      .addCase(publisherJoinedWithoutBroadcast, (state) => {
        state.publishingFeed.publisherJoined = true;
      });
  },
});

export const {
  generalDataUpdated,
  localFeedUpdated,
  localFeedDisconnected,
  remoteFeedConnected,
  remoteFeedUpdated,
  deviceProcessingStarted,
  deviceProcessingStopped,
  hdStreamToggled,
  remoteFeedDisconnected,
  activeSpeakerReset,
} = streamingSlice.actions;

export const selectLocalFeed = (state: RootState) => state.streaming.publishingFeed;
export const selectLocalFeedStatus = (state: RootState) => state.streaming.publishingFeed.status;
export const selectLocalFeedStreams = (state: RootState) => state.streaming.publishingFeed.streams;
export const selectLocalScreenshareStreams = (state: RootState) =>
  state.streaming.screensharingFeed.streams;
export const selectLocalFeedId = (state: RootState) => state.streaming.publishingFeed.feedId;

export const selectLocalStream = (
  state: RootState,
  kind: MediaStreamType,
  mode: 'media' | 'screenshare'
) =>
  state.streaming[mode === 'media' ? 'publishingFeed' : 'screensharingFeed'].streams.find(
    (stream) => stream.kind === kind
  );

export const selectFeedIdByUser = (state: RootState, userId: UserId) =>
  state.streaming.feedIdByUserId[userId];

export const selectRemoteStreams = (state: RootState, userId: UserId, requestedFeedId?: FeedId) => {
  const feedId = selectFeedIdByUser(state, userId);

  return state.streaming.remoteFeed.streamsByFeedId[requestedFeedId || feedId];
};

export const selectRemoteFeedByMid = (state: RootState, mid: string) =>
  state.streaming.remoteFeed.feedIdByMid[mid];

export const selectRemoteStreamByMid = (state: RootState, mid: string) => {
  const feedId = selectRemoteFeedByMid(state, mid);

  if (!feedId) {
    return undefined;
  }

  return state.streaming.remoteFeed.streamsByFeedId[feedId].find((stream) => stream.mid === mid);
};

export const selectLocalVideoBroadcasting = (state: RootState) =>
  state.streaming.publishingFeed.videoBroadcasting;

export const selectLocalPublisherJoined = (state: RootState) =>
  state.streaming.publishingFeed.publisherJoined;

export const selectLocalScreenshareBroadcasting = (state: RootState) =>
  state.streaming.screensharingFeed.videoBroadcasting;
export const selectLocalAudioBroadcasting = (state: RootState) =>
  state.streaming.publishingFeed.audioBroadcasting;

export const selectLocalVideoEnabled = (state: RootState) =>
  state.streaming.publishingFeed.videoEnabled;
export const selectLocalAudioEnabled = (state: RootState) =>
  state.streaming.publishingFeed.audioEnabled;

export const selectHDStreamStatus = (state: RootState) => state.streaming.publishingFeed.hdStream;

export const selectUserIdByFeed = (state: RootState, feedId: FeedId) =>
  state.streaming.userIdByFeedId[feedId];

export const selectRemoteBroadcastingState = (
  state: RootState,
  userId: UserId,
  type: MediaStreamType
) => state.streaming.broadcastingByUserId[userId]?.[type];

export const selectAllBroadcasters = (state: RootState) => state.streaming.broadcastingByUserId;

export const selectRemoteScreensharingState = (
  state: RootState,
  userId: UserId,
  type: MediaStreamType
) => state.streaming.screensharingByUserId[userId]?.[type];

export const selectIsActiveSpeaker = (state: RootState, userId: UserId) =>
  state.streaming.activeSpeakerId === userId;

export default streamingSlice.reducer;
