import { closeModal } from 'features/modal/modalSlice';
import { call, put, select } from 'redux-saga/effects';
import i18n from 'i18n';
import { PayloadAction } from '@reduxjs/toolkit';
import { RecordingStartedPayload } from 'features/room/types';
import { recordingStarted } from 'features/recorder/recorderSlice';
import { notification } from 'features/notifications/toast/notification';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';
import { selectLocalUserId, selectUserById } from 'features/users/usersSlice';
import { User, UserId } from 'features/users/types';

export function* onRecordingStartedSaga(action: PayloadAction<RecordingStartedPayload>) {
  const { initiator } = action.payload;

  logger.remote().info('Recording started');

  yield put(recordingStarted());

  const localUserId: UserId = yield select(selectLocalUserId);

  yield call(eventBus.sendMessage, 'recordingStarted', { userId: initiator.id });

  if (localUserId === initiator.id) {
    return;
  }

  const initiatorUser: User = yield select(selectUserById, initiator.id);

  yield put(closeModal('recordingE2eeConsent'));

  if (initiatorUser.name) {
    yield call(
      notification,
      i18n.t('notifications:recording_started', { username: initiatorUser.name })
    );
  } else {
    yield call(notification, i18n.t('notifications:recording_started_unnamed'));
  }
}
