import { speechCaptioned } from 'features/captions/actions';
import {
  captionsToggled,
  fontSizeChanged,
  spokenLanguageChanged,
} from 'features/captions/captionsSlice';
import { onCaptionsToggledSaga } from 'features/captions/sagas/onCaptionsToggledSaga';
import { onFontSizeChangedSaga } from 'features/captions/sagas/onFontSizeChangedSaga';
import { onSpeechCaptionedSaga } from 'features/captions/sagas/onSpeechCaptionedSaga';
import { onSpokenLanguageChangedSaga } from 'features/captions/sagas/onSpokenLanguageChangedSaga';
import { takeEvery } from 'redux-saga/effects';

export default function* captionsWatcher() {
  yield takeEvery(speechCaptioned, onSpeechCaptionedSaga);
  yield takeEvery(captionsToggled, onCaptionsToggledSaga);
  yield takeEvery(spokenLanguageChanged, onSpokenLanguageChangedSaga);
  yield takeEvery(fontSizeChanged, onFontSizeChangedSaga);
}
