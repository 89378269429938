import { useAppSelector } from 'store/hooks';
import { selectPrivateGroupChat } from 'features/chat/chatSlice';
import { PermissionTypes } from 'features/permissions/types';
import { selectLocalUserRole } from 'features/users/usersSlice';
import { usePermissions } from 'features/permissions/usePermissions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { selectIsBreakoutRoom } from 'features/room/roomSlice';

export const useChat = () => {
  const { hasPermissions } = usePermissions();

  const publicChatEnabled = useFeatureFlag('generalChat');
  const canChatInGeneral = hasPermissions(PermissionTypes.generalChat);
  const publicChatAvailable = publicChatEnabled && canChatInGeneral;

  const privateChatEnabled = useFeatureFlag('privateChat');
  const canChatInPrivate = hasPermissions(PermissionTypes.privateChat);
  const privateChatAvailable = privateChatEnabled && canChatInPrivate;

  const privateGroupChatEnabled = useFeatureFlag('privateGroupChat');
  const privateGroupChat = useAppSelector(selectPrivateGroupChat);
  const localRole = useAppSelector(selectLocalUserRole);
  const privateGroupChatAvailable =
    privateGroupChatEnabled && privateGroupChat.roles.includes(localRole);

  const isBreakoutRoom = useAppSelector(selectIsBreakoutRoom);

  const chatAvailable = isBreakoutRoom
    ? publicChatAvailable
    : publicChatAvailable || privateChatAvailable || privateGroupChatAvailable;

  return {
    chatAvailable,
    publicChatAvailable,
    privateChatAvailable,
    privateGroupChatAvailable,
    privateGroupChat,
  };
};
