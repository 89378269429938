import React from 'react';
import { Box } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import { ToolbarPlacement } from 'features/toolbar/types';
import RecordingIndicator from 'features/toolbar/RecordingIndicator';
import SlidingRecordingIndicator from 'features/room/components/topbar/SlidingRecordingIndicator';
import { styleConstants } from 'theme/styleConstants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRoomLogoEnabled } from 'features/room/roomSlice';
import { selectToolbarPlacement } from 'features/toolbar/toolbarSlice';
import { selectRecorderStatus } from 'features/recorder/recorderSlice';
import LayoutModeControl from 'features/layout/features/config/LayoutModeControl';
import { openModal } from 'features/modal/modalSlice';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { selectLayoutModeControlEnabled } from 'features/layout/features/config/configSlice';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip';
import Logo from 'features/room/components/topbar/Logo';
import E2eeBadge from 'features/e2ee/E2eeBadge';

const recordingBadgeTranslate = styleConstants.featureBadge.width + 16;

const SlidingWrapper = styled('div')<{
  visible: boolean;
}>(({ theme, visible }) => ({
  display: 'flex',
  alignItems: 'center',
  transform: visible ? 'translateX(0)' : `translateX(-${recordingBadgeTranslate}px)`,
  transition: theme.transitions.create('transform'),
}));

const Root = styled('div')<{ toolbarPlacement: ToolbarPlacement }>(
  ({ theme, toolbarPlacement }) => {
    const paddingLeft =
      toolbarPlacement === 'left'
        ? `calc(${theme.layout.innerPadding} + ${theme.toolbar.width})`
        : theme.layout.innerPadding;

    return {
      color: theme.palette.common.white,
      paddingLeft,
      background: theme.room.background,
      zIndex: 2,
    };
  }
);

const StyledE2eeBadge = styled(E2eeBadge, {
  shouldForwardProp: (prop) => prop !== 'recording',
})<{ recording?: boolean }>(({ theme, recording }) => {
  let bgColor = '#25D366';

  if (recording) {
    bgColor = theme.palette.grayscale.main;
  }

  return {
    backgroundColor: bgColor,
    '&:hover': {
      backgroundColor: lighten(bgColor, theme.palette.action.focusOpacity),
    },
    '&:focus-visible': {
      backgroundColor: lighten(bgColor, theme.palette.action.focusOpacity),
    },
  };
});

const Inner = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: theme.topBar.height,
  paddingTop: theme.spacing(2),
}));

const RoomTopBar = () => {
  const { t } = useTranslation('room');

  const dispatch = useAppDispatch();

  const logoEnabled = useAppSelector(selectRoomLogoEnabled);

  const toolbarPlacement = useAppSelector(selectToolbarPlacement);

  const recorderStatus = useAppSelector(selectRecorderStatus);

  const modeControlEnabled = useAppSelector(selectLayoutModeControlEnabled);

  const e2eeEnabled = useAppSelector(selectE2eeEnabled);

  const openRoomSecurityCode = () => {
    dispatch(openModal('roomE2eeCode'));
  };

  const logo = logoEnabled ? <Logo data-testid="topbar-logo" /> : null;

  const recorderIsActive = recorderStatus !== 'stopped';

  const renderE2eeBadgeTooltip = () => {
    if (recorderIsActive) {
      return t('topbar.e2ee_button_tooltip_recording');
    }

    return t('topbar.e2ee_button_tooltip');
  };

  return (
    <Root toolbarPlacement={toolbarPlacement}>
      {toolbarPlacement === 'left' && <SlidingRecordingIndicator />}
      <Inner>
        {toolbarPlacement === 'left' ? (
          <Box
            sx={{
              flex: 1,
            }}
          >
            {logo}
          </Box>
        ) : (
          <Box
            sx={{
              flex: 1,
            }}
          >
            <SlidingWrapper visible={recorderIsActive} data-testid="topbar-sliding-logo">
              <Box
                sx={{
                  pr: 4,
                }}
              >
                <RecordingIndicator
                  recorderStatus={recorderStatus}
                  data-testid="recording-indicator"
                />
              </Box>

              {logo}
            </SlidingWrapper>
          </Box>
        )}
        {e2eeEnabled && (
          <Tooltip title={renderE2eeBadgeTooltip()} placement="bottom">
            <StyledE2eeBadge recording={recorderIsActive} onClick={openRoomSecurityCode} />
          </Tooltip>
        )}
        {modeControlEnabled && (
          <Box
            sx={{
              ml: { xs: 2, sm: 5 },
              textAlign: 'right',
            }}
          >
            <LayoutModeControl />
          </Box>
        )}
      </Inner>
    </Root>
  );
};

export default RoomTopBar;
