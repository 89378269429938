import { selectVideoFacingMode } from 'features/user-media/userMediaSlice';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { usePublishingFeed } from 'hooks/usePublishingFeed';
import { Video } from 'features/streaming/components/media/Video';
import { useAppSelector } from 'store/hooks';

interface LocalStreamProps {
  mode: 'media' | 'screenshare';
  id: string;
}

const LocalVideoStream = ({ mode, id }: LocalStreamProps) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const { control } = usePublishingFeed(mode);
  const [aspectMode, setAspectMode] = useState(mode === 'screenshare' ? 'fit' : 'crop');

  const facingMode = useAppSelector(selectVideoFacingMode);

  const shouldMirror = mode === 'media' && facingMode !== 'environment';

  useEffect(() => {
    control.mediaController.attachToElement(id, ref);
  }, [ref, control, id]);

  const handleResize = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
    if (mode === 'screenshare') {
      return;
    }

    const video = e.target as HTMLVideoElement;
    const newAspectMode = video.videoWidth > video.videoHeight! ? 'crop' : 'fit';
    if (newAspectMode !== aspectMode) {
      setAspectMode(newAspectMode);
    }
  };

  return (
    <Video
      className={`fitment-${aspectMode}`}
      muted
      playsInline
      mirror={shouldMirror}
      ref={ref}
      onTimeUpdate={handleResize}
    />
  );
};

export default LocalVideoStream;
