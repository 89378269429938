import ParticipantsControl from 'features/toolbar/Toolbar/controls/participants/ParticipantsControl';
import ChatControl from 'features/toolbar/Toolbar/controls/chat/ChatControl';
import QAControl from 'features/toolbar/Toolbar/controls/qa/QAControl';
import ContentLibraryControl from 'features/toolbar/Toolbar/controls/ContentLibrary/ContentLibraryControl';
import RaiseHandControl from 'features/toolbar/Toolbar/controls/raise-hand/RaiseHandControl';
import RecordingControl from 'features/toolbar/Toolbar/controls/recording/RecordingControl';
import CaptionsControl from 'features/toolbar/Toolbar/controls/captions/CaptionsControl';
import WhiteboardControl from 'features/toolbar/Toolbar/controls/whiteboard/WhiteboardControl';
import OverflowMenu from 'components/OverflowMenu/OverflowMenu';
import { useParticipantsControl } from 'features/toolbar/Toolbar/controls/participants/useParticipantsControl';
import { useChatControl } from 'features/toolbar/Toolbar/controls/chat/useChatControl';
import { useQAControl } from 'features/toolbar/Toolbar/controls/qa/useQAControl';
import { useContentLibraryControl } from 'features/toolbar/Toolbar/controls/ContentLibrary/useContentLibraryControl';
import { useRaiseHandControl } from 'features/toolbar/Toolbar/controls/raise-hand/useRaiseHandControl';
import { useRecordingControl } from 'features/toolbar/Toolbar/controls/recording/useRecordingControl';
import { useCaptionsControl } from 'features/toolbar/Toolbar/controls/captions/useCaptionsControl';
import { useWhiteboardControl } from 'features/toolbar/Toolbar/controls/whiteboard/useWhiteboardControl';
import React from 'react';

type OverflowMenuContainerProps = {
  leading: boolean;
};

const OverflowMenuContainer = React.memo(({ leading }: OverflowMenuContainerProps) => {
  const { isFeatureAvailable: participantsAvailable } = useParticipantsControl();
  const { isFeatureAvailable: chatAvailable } = useChatControl();
  const { isFeatureAvailable: qaAvailable } = useQAControl();
  const { isFeatureAvailable: contentLibraryAvailable } = useContentLibraryControl();
  const { isFeatureAvailable: raiseHandAvailable } = useRaiseHandControl();
  const { isFeatureAvailable: recordingAvailable } = useRecordingControl();
  const { isFeatureAvailable: captionsAvailable } = useCaptionsControl();
  const { isFeatureAvailable: whiteboardAvailable } = useWhiteboardControl();

  return (
    <OverflowMenu leading={leading}>
      {participantsAvailable && <ParticipantsControl />}
      {chatAvailable && <ChatControl />}
      {qaAvailable && <QAControl />}
      {contentLibraryAvailable && <ContentLibraryControl />}
      {raiseHandAvailable && <RaiseHandControl />}
      {recordingAvailable && <RecordingControl />}
      {captionsAvailable && <CaptionsControl />}
      {whiteboardAvailable && <WhiteboardControl />}
    </OverflowMenu>
  );
});

export default OverflowMenuContainer;
