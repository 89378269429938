import React from 'react';
import UserAvatar from 'components/UserAvatar';
import { Box, ListItemAvatar, ListItemText, ListItemTextProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

export type AvatarEntityProps = ListItemTextProps & {
  name: string;
  initials?: string;
  color?: string;
  src?: string;
  local?: boolean;
  avatar?: React.ReactElement;
};

const primaryTypographyProps = {
  noWrap: true,
};

export const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: '52px',
  '& .MuiAvatar-root': {
    fontSize: '1rem',
    border: `2px solid ${theme.palette.common.white}`,
  },
}));

const AvatarNameEntity = ({
  color,
  src,
  name,
  initials,
  local,
  avatar,
  ...props
}: AvatarEntityProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <StyledListItemAvatar>
        {avatar || <UserAvatar src={src} color={color!} name={name} initials={initials} />}
      </StyledListItemAvatar>
      <ListItemText
        primaryTypographyProps={primaryTypographyProps}
        primary={
          <>
            {name}
            {local && (
              <Box
                data-testid="local-user-label"
                component="span"
                sx={{
                  ml: 1,
                  color: 'grey.400',
                }}
              >
                ({t('common:you')})
              </Box>
            )}
          </>
        }
        {...props}
      />
    </>
  );
};

export default AvatarNameEntity;
