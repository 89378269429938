import {
  checkPermissions,
  CheckPermissionsOptions,
  HasPermissionsOptions,
} from 'features/permissions/utils/checkPermissions';
import { PermissionsState, PermissionTypes } from 'features/permissions/types';
import { User } from 'features/users/types';

export interface RefinePermissionsOptions extends HasPermissionsOptions {
  permissionsState: PermissionsState;
  localUser: User;
}

export const refinePermissions = (
  permissions: PermissionTypes | PermissionTypes[],
  { targetRole, role, targetUser, permissionsState, localUser }: RefinePermissionsOptions
) => {
  const options: CheckPermissionsOptions = {
    permissionsState,
    permissions,
    targetRole,
    role: localUser.role,
    dynamicPermissions: localUser.dynamicPermissions,
  };

  if (role) {
    options.role = role;
    options.dynamicPermissions = undefined;
  }

  if (targetUser) {
    options.role = targetUser.role;
    options.dynamicPermissions = targetUser.dynamicPermissions;
  }

  return checkPermissions(options);
};
