import { useAppDispatch } from 'store/hooks';
import { ActionNotification } from 'features/notifications/types';
import { actionNotificationClosed } from 'features/notifications/notificationsSlice';
import { User } from 'features/users/types';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, Paper, Stack, StackProps, Typography } from '@mui/material';
import CloseButton from 'components/CloseButton';
import UserAvatar from 'components/UserAvatar';
import React from 'react';

const Actions = styled((props: StackProps) => <Stack spacing={4} direction="row" {...props} />)(
  ({ theme }) => ({
    padding: theme.spacing(2, 3),
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    '& .MuiButton-root': {
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      fontWeight: 700,
      padding: theme.spacing(2),
      fontSize: 14,
    },
  })
);

export type NotificationProps = {
  // toastProps?: ToastProps;
  closeToast?: () => void;
  user?: User;
} & ActionNotification;

const Notification = ({
  closeToast,
  okAction,
  cancelAction,
  id,
  user,
  preventCloseOnAction,
  content,
  okText,
  cancelText,
}: NotificationProps) => {
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(actionNotificationClosed(id));
    closeToast?.();
  };

  const handleOk = () => {
    if (okAction) {
      dispatch(okAction);
    }

    if (!preventCloseOnAction) {
      close();
    }
  };

  const handleClose = () => {
    if (cancelAction) {
      dispatch(cancelAction);
    }

    close();
  };

  const theme = useTheme();

  return (
    <Paper
      elevation={theme.palette.mode === 'dark' ? 6 : 0}
      sx={{
        position: 'relative',
        borderRadius: '8px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          top: 6,
          right: 6,
          position: 'absolute',
        }}
      >
        <CloseButton onClick={handleClose} />
      </Box>
      <Box
        sx={{
          padding: theme.spacing(3, 10, 4, 3),
        }}
      >
        <Stack spacing={3} direction="row">
          {user && (
            <UserAvatar
              src={user.avatarUrl}
              initials={user.initials}
              name={user.name}
              color={user.avatarColor}
              sx={{ width: 28, height: 28, fontSize: 12 }}
            />
          )}
          <Box
            sx={{
              pt: 0.5,
            }}
          >
            {typeof content === 'string' ? (
              <Typography
                variant="body2"
                data-testid="notification-text-content"
                sx={{
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'anywhere',
                }}
              >
                {content}
              </Typography>
            ) : (
              content
            )}
          </Box>
        </Stack>
      </Box>
      {okText || cancelText ? (
        <Actions>
          {cancelText && (
            <Button color="grayscale" onClick={handleClose}>
              {cancelText}
            </Button>
          )}
          {okText && okAction && <Button onClick={handleOk}>{okText}</Button>}
        </Actions>
      ) : null}
    </Paper>
  );
};

export default Notification;
