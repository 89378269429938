import * as Sentry from '@sentry/react';
import { selectDeviceInfo } from 'features/application/applicationSlice';
import { openModal } from 'features/modal/modalSlice';
import { notification } from 'features/notifications/toast/notification';
import { screenshareStopped } from 'features/streaming/actions';
import { getDisplayMedia } from 'features/user-media/utils/getDisplayMedia';
import { selectLocalUserId } from 'features/users/usersSlice';
import i18n from 'i18n';
import { store } from 'store/store';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';
import { isDomException } from 'utils/types';
import { FeedMediaState, RTCClient } from 'utils/webrtc/index';
import { BasePublishing } from 'utils/webrtc/publishing';
import webrtcAdapter from 'webrtc-adapter';

export class ScreensharingFeed extends BasePublishing {
  defaultMediaState: FeedMediaState = {
    enabled: true,
    allowed: true,
    broadcasting: false,
    captured: false,
  };

  mediaStates = {
    video: { ...this.defaultMediaState },
    audio: { ...this.defaultMediaState },
  };

  savedStream: MediaStream | null = null;

  constructor() {
    super('screensharing');
  }

  getActiveMediaConfig = () => {
    if (this.savedStream) {
      const clonedStream = new MediaStream();

      this.savedStream.getTracks().forEach((track) => clonedStream.addTrack(track.clone()));

      this.mediaController.storedMediaStreams[clonedStream.id] = clonedStream;

      return {
        stream: clonedStream,
      };
    }

    // Ideally, we should never end up here with 'saveStream()' in 'shareScreen()'
    logger.remote().error(`Missing stored stream in screensharing feed`);

    return {
      video: false,
      audio: false,
    };
  };

  shareScreen = async () => {
    this.broadcastIntended = true;

    if (!this.savedStream) {
      await this.saveStream();
    }

    if (!this.savedStream) {
      return;
    }
    // user shared screen before, we cleanup the handle and just publish media.
    if (this.handle) {
      // mark media as not broadcasted. It will be marked as broadcasted when media hits the server.
      this.resetMediaStates();

      await this.requestPublish({
        media: { replaceVideo: true, replaceAudio: true },
      });
      // user didn't share screen before, attachPlugin will trigger publishing. Supposedly all good with this branch.
    } else {
      this.attachPlugin();
    }
  };

  stopScreenshare = async (notifySignaling: boolean = true) => {
    this.broadcastIntended = false;

    this.savedStream?.getTracks().forEach((track) => {
      track.stop();
    });

    this.savedStream = null;

    if (this.handle?.feedId) {
      if (notifySignaling) {
        this.signalingBus.addMessage(
          'stop',
          this.handle.feedId,
          Object.values(this.handle.streams)
        );
      }

      const userId = selectLocalUserId(store.getState());

      store.dispatch(
        screenshareStopped({
          id: userId,
          feedId: this.handle.feedId,
          streams: Object.values(this.handle.streams),
        })
      );
    }

    // this is valid, we remove local media from the UI
    this.cleanupStreams();

    if (this.mediaStates.video.broadcasting) {
      await this.stopVideoBroadcasting();
    } else {
      this.resetMediaStates('video');
    }

    if (this.mediaStates.audio.broadcasting) {
      await this.stopAudioBroadcasting();
    } else {
      this.resetMediaStates('audio');
    }

    this.updateRedux();
  };

  toggleScreenshare = async () => {
    if (this.mediaStates.video.broadcasting) {
      await this.stopScreenshare();
    } else {
      await this.shareScreen();
    }
  };

  onMediaState = async (medium: 'audio' | 'video', on: boolean, mid?: string) => {
    logger
      .remote()
      .debug(`Janus ${on ? 'started' : 'stopped'} receiving our ${medium} (mid=${mid})`);

    const feed = this.handle;

    if (!feed) {
      return;
    }

    feed.streams = feed.streams || {};

    if (on) {
      if (mid) {
        if (medium === 'audio' && this.negotiationMeta.pendingAudioBroadcastState !== null) {
          logger.warn(
            'ON_MEDIA_STATE tweaking audio with pending state...',
            this.negotiationMeta.pendingAudioBroadcastState
          );

          if (this.negotiationMeta.pendingAudioBroadcastState) {
            feed.videoroom.unmuteAudio(mid);
          } else {
            feed.videoroom.muteAudio(mid);
          }

          this.negotiationMeta.pendingAudioBroadcastState = null;
        }

        feed.streams[mid] = { type: medium, mid, on: true };
      }

      if (!feed.connected && !feed.connecting) {
        feed.connecting = true;
      }
    } else {
      feed.streams[mid!].on = false;
    }

    const shouldSendUpdate = on ? !this.mediaStates[medium].captured : false;

    this.mediaStates[medium].broadcasting = on;
    this.mediaStates[medium].captured = on;

    if (mid && shouldSendUpdate) {
      this.signalingBus.addMessage(on ? 'start' : 'stop', feed.feedId, [{ type: medium, mid }]);
    }

    this.updateRedux();
  };

  cleanupStreams = () => {
    Object.keys(this.mediaController.streams).forEach((id) => {
      this.mediaController.removeStream(id);
    });

    Object.keys(this.mediaController.storedMediaStreams).forEach((id) => {
      this.mediaController.stopStreamTracks(this.mediaController.storedMediaStreams[id]);
    });
  };

  cleanupConnection = () => {
    logger.debug('Screensharing Feed: cleaning up connection');
    const feed = this.handle;
    this.resetMediaStates();

    this.handleCleanup();

    this.reconnectionReset();

    if (!feed) {
      return;
    }

    if (feed.feedId) {
      RTCClient.disconnectFeed(feed.feedId);
    }

    feed.videoroom.hangup();
  };

  saveStream = async () => {
    try {
      this.savedStream = await getDisplayMedia();
    } catch (e) {
      if (isDomException(e)) {
        eventBus.error({
          name: 'permissions-rejected',
          message: 'Screenshare permissions have been rejected',
        });

        const deviceInfo = selectDeviceInfo(store.getState());

        if (deviceInfo.os.name === 'Mac OS') {
          if (
            (webrtcAdapter.browserDetails.browser === 'chrome' && e.message.includes('system')) ||
            (webrtcAdapter.browserDetails.browser === 'firefox' && e.name === 'NotFoundError')
          ) {
            store.dispatch(openModal('screensharePermissionsError'));
          } else {
            notification(i18n.t('notifications:screen_permissions_not_granted'));
          }
        } else {
          notification(i18n.t('notifications:screen_permissions_not_granted'));
        }
      } else {
        Sentry.captureException(e);
        notification(i18n.t('notifications:screen_permissions_not_granted'));
      }
    }
  };
}
