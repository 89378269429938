import { useGDPRConfig } from 'hooks/useGDPRConfig';
import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import Input from 'components/Input';
import { Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormFooter from 'components/FormFooter';
import { styleConstants } from 'theme/styleConstants';
import { Control } from 'react-hook-form/dist/types/form';

export interface RoomLoginFormValues {
  login: string;
  consentGiven?: boolean;
}

interface RoomLoginFormProps {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: FieldErrors<RoomLoginFormValues>;
  control: Control<RoomLoginFormValues>;
  isSubmitting: boolean;
  showNameField: boolean;
}

const RoomLoginForm = ({
  onSubmit,
  errors,
  control,
  isSubmitting,
  showNameField,
}: RoomLoginFormProps) => {
  const { t } = useTranslation(['join', 'common']);

  const { consentConfig, consentRequired } = useGDPRConfig();

  const renderConsentCheckbox = () => {
    if (!consentRequired || !consentConfig) return null;

    return (
      <Box>
        <Typography
          sx={{ margin: '1rem 0 .75rem', textAlign: 'left' }}
          dangerouslySetInnerHTML={{
            __html: consentConfig.message,
          }}
        />
        <FormControl fullWidth>
          <Controller
            name="consentGiven"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ textAlign: 'left' }}
                control={<Checkbox {...field} />}
                label={consentConfig.checkboxMessage}
              />
            )}
          />
          <FormHelperText>{errors?.login?.message}</FormHelperText>
        </FormControl>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        maxWidth: styleConstants.narrowContainerWidth,
        m: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" sx={{ mb: { xs: 8, lg: 11 } }}>
        {t('login.title')}
      </Typography>
      {errors.root?.serverError && (
        <Typography sx={{ color: 'error.main', mb: 5 }}>
          {errors.root.serverError.message}
        </Typography>
      )}
      <form onSubmit={onSubmit}>
        {showNameField ? (
          <FormControl fullWidth error={!!errors?.login}>
            <Controller
              name="login"
              control={control}
              rules={{ required: t<string>('common:validation.field_required') }}
              render={({ field }) => (
                <Input
                  fullWidth
                  {...field}
                  autoFocus
                  placeholder={t('login.input_name_placeholder')}
                  autoComplete="name"
                />
              )}
            />
            <FormHelperText>{errors?.login?.message}</FormHelperText>
          </FormControl>
        ) : null}
        {renderConsentCheckbox()}
        <FormFooter>
          <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
            {t('login.btn_label_join')}
          </Button>
        </FormFooter>
      </form>
    </Box>
  );
};

export default RoomLoginForm;
