import React, { useCallback, useState } from 'react';
import ToolbarControl from 'features/toolbar/ToolbarControl';
import { ToolbarItemSharedProps } from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { PopperPlacementType } from '@mui/material';
import Menu, { MenuProps } from 'components/Menu';
import Tooltip, { TooltipPlacement } from 'components/Tooltip';
import Popper from 'components/Popper';
import { ToolbarOrientation } from 'features/toolbar/types';

export type ToolbarSubmenuProps = Omit<MenuProps, 'trigger'> &
  ToolbarItemSharedProps & {
    children: React.ReactNode;
    placement: PopperPlacementType;
    overlayPlacement?: PopperPlacementType | TooltipPlacement;
    orientation: ToolbarOrientation;
  };

const ToolbarSubmenu = ({
  children,
  id,
  overlay,
  icon,
  ariaLabel,
  placement = 'bottom-start',
  overlayPlacement,
  overlayType,
  orientation,
  disabled,
  ...props
}: ToolbarSubmenuProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleTooltipToggle = () => {
    if (!menuOpen) {
      setTooltipOpen((prevOpen) => !prevOpen);
    }
  };

  const handleMenuToggle = useCallback(() => {
    if (tooltipOpen) {
      setTooltipOpen(false);
    }
    setMenuOpen((prevOpen) => !prevOpen);
  }, [tooltipOpen]);

  const trigger = (
    <ToolbarControl
      icon={icon}
      onClick={handleMenuToggle}
      orientation={orientation}
      disabled={disabled}
    />
  );

  const renderTrigger = () => {
    if (!overlay) {
      return trigger;
    }

    if (overlayType === 'popper') {
      return (
        <Popper id={id} content={overlay} placement={overlayPlacement} triggerType="hover">
          {trigger}
        </Popper>
      );
    }

    return (
      <Tooltip
        open={tooltipOpen}
        title={overlay}
        onOpen={handleTooltipToggle}
        onClose={handleTooltipToggle}
        placement={overlayPlacement as TooltipPlacement}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 24],
              },
            },
          ],
        }}
      >
        {trigger}
      </Tooltip>
    );
  };

  return (
    <Menu
      {...props}
      open={menuOpen}
      id={id}
      onClose={handleMenuToggle}
      placement={placement}
      trigger={<div>{renderTrigger()}</div>}
    >
      {children}
    </Menu>
  );
};

export default ToolbarSubmenu;
