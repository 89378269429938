import { GlobalStyles } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { fileOpenRequested } from 'features/content-library/actions';
import { selectContentLibraryActivePage } from 'features/content-library/contentLibrarySlice';
import { GalleryPagination } from 'features/content-library/file-panel/GalleryPagination';
import { Slide, SlideImg } from 'features/content-library/file-panel/SlideImg';
import { ThumbnailList } from 'features/content-library/file-panel/ThumbnailList';
import { ToggleThumbnailsControl } from 'features/content-library/file-panel/ToggleThumbnailsControl';
import { ContentLibraryFileList } from 'features/content-library/types';
import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { StreamBadge } from 'features/streaming/components/stream-display/StreamBadge';
import React, { useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Keyboard, Mousewheel, Pagination } from 'swiper/modules';
import { breakpointValues } from 'theme/themeBaseOptions';
import { scrollIntoViewIfVisible } from 'utils/dom/scrollIntoViewIfVisible';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/virtual';

const handleKeyboard = (slider: SwiperClass, keyCode: number) => {
  switch (keyCode) {
    case 37:
      slider.slidePrev();
      break;
    case 39:
      slider.slideNext();
      break;
    default:
      break;
  }
};

export const GalleryFile = ({
  file,
  size,
}: {
  file: ContentLibraryFileList;
  size: { width?: number; height?: number };
}) => {
  const dispatch = useAppDispatch();
  const swiperRef = useRef<SwiperClass>();
  const activeIndex = useAppSelector(selectContentLibraryActivePage);
  const activeIndexRef = useRef(0);
  const ignoreUpdatesRef = useRef(false);

  const { hasPermissions } = usePermissions();
  const canPresentFiles = hasPermissions(PermissionTypes.presentFiles);

  const goToPage = (idx: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(idx);
    }
  };

  useEffect(() => {
    if (activeIndex !== activeIndexRef.current) {
      if (swiperRef.current) {
        activeIndexRef.current = activeIndex;

        ignoreUpdatesRef.current = true;
        swiperRef.current.slideTo(activeIndex);
      }
    }
  }, [activeIndex]);

  const swiperConfig = useMemo(() => {
    if (canPresentFiles) {
      return {
        keyboard: {
          enabled: true,
        },
        modules: [Keyboard, Mousewheel, Pagination /* FreeMode */],
      };
    }
    return {};
  }, [canPresentFiles]);

  useEffect(() => {
    if (canPresentFiles) {
      swiperRef.current?.on('keyPress', handleKeyboard);
    } else {
      swiperRef.current?.off('keyPress');
    }
  }, [canPresentFiles]);

  if (!file) {
    return null;
  }

  const handleActiveSlideChange = (e: SwiperClass) => {
    if (ignoreUpdatesRef.current) {
      ignoreUpdatesRef.current = false;
    } else {
      activeIndexRef.current = e.activeIndex;

      dispatch(fileOpenRequested({ id: file.id, page: e.activeIndex }));
    }

    const activeThumb = document.querySelector(`.slider-thumbnail-${e.activeIndex}`);

    if (activeThumb) {
      scrollIntoViewIfVisible(activeThumb);
    }
  };

  const handleSwiper = (swiper: SwiperClass) => {
    swiperRef.current = swiper;

    swiperRef.current?.on('slideChange', handleActiveSlideChange);
  };

  const showThumbs = size?.width && size.width > breakpointValues.md;

  return (
    <GridPanelContainer id="slide-container">
      <GlobalStyles
        styles={(theme) => ({
          '#slide-container .swiper-wrapper': {
            maxHeight: `${size.height}px`,
          },
          '.grid-panel-controls-container .wb-control': {
            color: theme.palette.common.black,
            backgroundColor: alpha(theme.palette.common.black, 0.2),
          },
          '.grid-panel-controls-container .wb-control:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.1),
          },
        })}
      />
      {showThumbs ? <ThumbnailList pages={file.pages} goToPage={goToPage} /> : null}
      <div
        style={{
          flexGrow: '1',
          position: 'relative',
          pointerEvents: canPresentFiles ? 'all' : 'none',
        }}
      >
        {showThumbs ? <ToggleThumbnailsControl /> : null}
        <StreamBadge label={file.name} ChipProps={{ sx: { textTransform: 'none' } }} />
        <GalleryPagination goToPage={goToPage} />
        <Swiper
          direction="vertical"
          spaceBetween={10}
          slidesPerView={1}
          mousewheel
          freeMode
          onSwiper={handleSwiper}
          initialSlide={activeIndex}
          key={`gallery-${canPresentFiles ? 'present' : 'view'}`}
          {...swiperConfig}
        >
          {file.pages.map((img, idx) => (
            <SwiperSlide key={idx}>
              {/* TODO: BAD BAD BAD */}
              <Slide sx={{ height: `${size.height ? size.height - 20 : 0}px` }}>
                <SlideImg src={img.url} />
              </Slide>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </GridPanelContainer>
  );
};
