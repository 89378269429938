import { AppThunk } from 'store/store';
import { selectNotificationsByUser } from 'features/notifications/notificationsSlice';
import { UserId } from 'features/users/types';
import { userLeft } from 'features/users/usersSlice';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';

export const cleanupUser =
  (userId: UserId): AppThunk<void> =>
  (dispatch, getState) => {
    const notifications = selectNotificationsByUser(getState(), userId);

    dispatch(closeActionNotifications(notifications));

    dispatch(userLeft(userId));
  };
