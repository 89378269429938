import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { User, UserId } from 'features/users/types';
import { call, put, select } from 'redux-saga/effects';
import { selectHandRaisedUsers } from 'features/users/selectors/selectHandRaisedUsers';
import { ActionNotification } from 'features/notifications/types';
import { selectHandRaisedNotification } from 'features/notifications/notificationsSlice';
import { HAND_RAISED_NOTIFICATION_ID } from 'features/notifications/constants';
import { Action } from '@reduxjs/toolkit';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';
import { ToolbarPanelType } from 'features/toolbar/types';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { addActionNotification } from 'features/notifications/addActionNotification';
import i18n from 'i18n';
import { updateActionNotification } from 'features/notifications/updateActionNotification';
import { selectLocalUserId } from 'features/users/usersSlice';
import { raiseHandNotificationUpdateRequested } from 'features/notifications/actions';

export function* handleRaiseHandNotificationSaga(
  action: ReturnType<typeof raiseHandNotificationUpdateRequested>
) {
  try {
    const users: User[] = yield select(selectHandRaisedUsers);
    const localUserId: UserId = yield select(selectLocalUserId);

    // skip local user
    const handRaisedUsers = users.filter((user) => user.id !== localUserId);

    const handRaisedUsersCount = handRaisedUsers.length;

    const activeNotification: ActionNotification = yield select(selectHandRaisedNotification);

    if (handRaisedUsersCount === 0 && activeNotification) {
      // close the notification if the last user lowered hand
      const cleanupNotificationThunk: Action = yield call(
        closeActionNotification,
        activeNotification.id
      );
      yield put(cleanupNotificationThunk);

      return;
    }

    if (handRaisedUsersCount === 0) {
      // there are no users with the raised hand, no need to create or update the notification
      return;
    }

    const featuredUser = handRaisedUsers[0];
    if (!featuredUser) {
      // no user to be displayed on the notification
      return;
    }

    if (!activeNotification) {
      // show the notification only for the hand-raised requests
      if (action.payload.reason !== 'hand-raised') {
        return;
      }

      const activePanel: ToolbarPanelType = yield select(selectToolbarPanel);

      const actionNotificationThunk: Action = yield call(
        addActionNotification,
        {
          content: i18n.t('notifications:hand_raised.content', {
            username: featuredUser.name,
            count: handRaisedUsersCount - 1,
            context: handRaisedUsersCount > 1 ? 'grouped' : undefined,
          }),
          okText: i18n.t('notifications:hand_raised.btn_ok_label'),
          okAction: activePanel === 'users' ? undefined : toolbarPanelOpened('users'),
          initiatorId: featuredUser.id,
          userAgnostic: true,
          preventCloseOnAction: true,
        },
        {
          toastId: `${HAND_RAISED_NOTIFICATION_ID}_${Date.now()}`,
        }
      );
      yield put(actionNotificationThunk);

      return;
    }

    // group incoming notifications
    const updateNotificationThunk: Action = yield call(
      updateActionNotification,
      activeNotification.id,
      {
        content: i18n.t('notifications:hand_raised.content', {
          username: featuredUser.name,
          count: handRaisedUsersCount - 1,
          context: handRaisedUsersCount > 1 ? 'grouped' : undefined,
        }),
        initiatorId: featuredUser.id,
      }
    );
    yield put(updateNotificationThunk);
  } catch (error) {
    Sentry.captureException(error);
    logger.remote({ tier: 1 }).error('Cannot handle raise hand notification', error);
  }
}
