import { selectSDKInitState } from 'features/sdk/sdkStateSlice';
import { call, put, select } from 'redux-saga/effects';
import i18n, { criticalNamespaces } from 'i18n';
import { eventBus, SDKInitState } from 'utils/eventBus';
import { SupportedLanguages } from 'features/application/types';
import { supportedLanguagesLoaded } from 'features/application/applicationSlice';

export function* loadLocalizationSaga(
  dashboardLanguage: string | undefined,
  supportedLanguages: SupportedLanguages
) {
  yield put(supportedLanguagesLoaded(supportedLanguages));

  const initState: SDKInitState = yield select(selectSDKInitState);
  let language: string | undefined = initState.appLanguage;

  const languageExists = supportedLanguages.find((lang) => lang.value === language);
  if (language && !languageExists) {
    yield call(eventBus.error, {
      name: 'locale',
      message: `Requested language (${
        initState.appLanguage
      }) is not supported, falling back to default. Supported languages are: ${supportedLanguages.join(
        ','
      )}`,
    });

    language = undefined;
  }

  if (!language) {
    language = dashboardLanguage || 'en';
  }

  yield call(i18n.changeLanguage, language);
  yield call(i18n.loadNamespaces, criticalNamespaces);
}
