import { Box, Typography } from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import { CaptionsParticipantPayload } from 'features/captions/types';
import React from 'react';

export const CaptionsParticipantName = ({
  participant,
}: {
  participant: CaptionsParticipantPayload | null;
}) => {
  if (!participant) {
    return <Typography> </Typography>;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 1 }}>
      <UserAvatar
        src={participant.avatarUrl}
        name={participant.name}
        color={participant.avatarColor}
        sx={{ width: 28, height: 28, fontSize: 12, mr: 4 }}
      />
      <Typography sx={{ fontSize: 12, color: 'grey.400' }}>{participant.name}</Typography>
    </Box>
  );
};
