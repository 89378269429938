import { useVideoInputField } from 'hooks/useVideoInputField';
import VideoRenderer from 'components/VideoRenderer';
import { MediaDeviceChangeHandler } from 'features/join/publisher/JoinPreview';
import { Control } from 'react-hook-form/dist/types/form';
import { TabPanelInner } from 'components/Tabs';
import DeviceSelect from 'components/DeviceSelect';
import { useTranslation } from 'react-i18next';
import React from 'react';

export type VideoTabProps = {
  videoStream: MediaStream | null;
  onVideoInputChange: MediaDeviceChangeHandler;
  control: Control<any>;
  cameraDisabled?: boolean;
  cameraToggle?: React.ReactNode;
};

const VideoTab = ({
  videoStream,
  onVideoInputChange,
  cameraDisabled,
  control,
  cameraToggle,
}: VideoTabProps) => {
  const { t } = useTranslation('settings');
  const { videoInputOptions, videoInputPlaceholder } = useVideoInputField();

  return (
    <TabPanelInner>
      <VideoRenderer mediaStream={videoStream} sx={{ mb: 8 }} />
      {cameraToggle && cameraToggle}
      <DeviceSelect
        id="settings-video-select"
        name="videoinput"
        control={control}
        icon="camera"
        onChange={onVideoInputChange}
        options={videoInputOptions}
        disabled={cameraDisabled}
        placeholder={videoInputPlaceholder}
        ariaLabel={t('device_dropdowns.videoinput.aria_label')}
      />
    </TabPanelInner>
  );
};

export default VideoTab;
