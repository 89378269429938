import { PayloadAction } from '@reduxjs/toolkit';
import { streamMaximized } from 'features/layout/actions';
import {
  contentWidthChanged,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import {
  orderedStreamAdded,
  selectOrderedStreams,
} from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';
import { StreamingStarted } from '../types';

export function* onScreensharingStartSaga(action: PayloadAction<StreamingStarted>) {
  const { id: userId, feedId, streams } = action.payload;

  // TODO [screensharing] - pay attention to this;
  yield call(RTCClient.receivingFeed.addAvailableStreams, feedId, streams);

  if (!RTCClient.localFeeds[feedId]) {
    yield call(RTCClient.subscribe, action.payload, true);
    //
    //   yield call(RTCClient.screensharingFeed.stopScreenshare);
  }

  // this updates values in userId <-> screensharing boolean that then goes to redux and is used in UI.
  yield call(RTCClient.enableScreensharingMedia, userId, streams);

  const orderedStreams: SourceDetails[] = yield select(selectOrderedStreams);

  const streamExists = orderedStreams.find(
    (stream) => stream.userId === userId && stream.feedId === feedId
  );

  const source: SourceDetails = {
    userId: action.payload.id,
    feedId,
    kind: `screenshare-${RTCClient.localFeeds[feedId] ? 'local' : 'remote'}`,
  };

  if (!streamExists) {
    yield put(orderedStreamAdded(source));
  }

  const pinnedContentWidth: number = yield select(selectPinnedContentWidth);

  yield put(contentWidthChanged(pinnedContentWidth));

  yield put(streamMaximized({ source }));

  yield call(eventBus.sendMessage, 'screenshareStarted', { userId });
}
