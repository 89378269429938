import React from 'react';
import { useCaptionsControl } from 'features/toolbar/Toolbar/controls/captions/useCaptionsControl';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';

const CaptionsControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { active, label, toggleCaptions, isFeatureAvailable } = useCaptionsControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    toggleCaptions();
  };
  return (
    <MobileMenuItem
      id="captions-control"
      selected={active}
      icon="closed-caption"
      onClick={handleClick}
    >
      {label}
    </MobileMenuItem>
  );
};

export default CaptionsControlMobile;
