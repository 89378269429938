import { captionsToggled, selectCaptionsDisplayed } from 'features/captions/captionsSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';

export const useCaptionsControl = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('room');

  const captionsEnabled = useAppSelector(selectCaptionsDisplayed);

  const isFeatureEnabled = useFeatureFlag('captions');

  const isFeatureAvailable = isFeatureEnabled;

  const label = captionsEnabled ? t('toolbar.disable_captions') : t('toolbar.enable_captions');

  const toggleCaptions = () => {
    dispatch(captionsToggled());
  };

  return {
    active: captionsEnabled,
    label,
    toggleCaptions,
    isFeatureAvailable,
  };
};
