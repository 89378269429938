import { Box, Chip as MuiChip, CircularProgress } from '@mui/material';
import { chipClasses } from '@mui/material/Chip';
import { alpha, styled } from '@mui/material/styles';
import {
  selectContentLibraryActivePage,
  selectShowContentLibraryThumbnails,
} from 'features/content-library/contentLibrarySlice';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { Suspense } from 'react';
import { useImage } from 'react-image';
import { useAppSelector } from 'store/hooks';
import { SlideThumbnailImg } from './SlideImg';

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'handRaised',
})(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.palette.common.black, 0.7),
  fontSize: theme.typography.pxToRem(14),
  height: 26,
  textTransform: 'capitalize',
  transition: 'none',
  [`& .${chipClasses.labelMedium}`]: {
    padding: theme.spacing(0, 3),
  },
  marginTop: '.75rem',
}));

const SkeletonThumbnailOuter = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '200px',
  background: theme.palette.grey['200'],
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SkeletonThumbnail = () => (
  <SkeletonThumbnailOuter>
    <CircularProgress sx={{ color: 'grey.700' }} disableShrink />
  </SkeletonThumbnailOuter>
);

const Thumbnail = ({
  src,
  active,
  children,
}: {
  src: string;
  active: boolean;
  children: React.ReactNode;
}) => {
  const image = useImage({
    srcList: src,
  });

  return (
    <Box
      sx={{
        width: '150px',
        height: '220px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box>
        <SlideThumbnailImg
          sx={(theme) => ({
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '4px',
            borderColor: active ? theme.palette.primary.main : theme.palette.grey['300'],
            outline: active ? `1px solid ${theme.palette.primary.main}` : 'none',
          })}
          src={image.src}
          alt={`slide-${src}`}
        />
      </Box>
      {children}
    </Box>
  );
};

interface ThumbnailListProps {
  goToPage: (index: number) => void;

  pages: {
    thumbnailUrl: string;
    url: string;
  }[];
}

export const ThumbnailList = ({ pages, goToPage }: ThumbnailListProps) => {
  const { hasPermissions } = usePermissions();
  const canPresentFiles = hasPermissions(PermissionTypes.presentFiles);
  const activeIndex = useAppSelector(selectContentLibraryActivePage);
  const showList = useAppSelector(selectShowContentLibraryThumbnails);
  const { isMobile } = useBreakpoints();

  if (isMobile || !showList || !canPresentFiles) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        minWidth: '190px',
        width: '190px',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRight: `1px solid ${theme.palette.grey['400']}`,
        height: '100%',
        overflow: 'auto',
        padding: '5px 0',
      })}
    >
      {pages.map((img, idx) =>
        img.url ? (
          <button
            type="button"
            style={{
              padding: '1rem 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: 'none',
              border: 'none',
            }}
            className={`slider-thumbnail-${idx}`}
            key={idx}
            onClick={() => goToPage(idx)}
          >
            <Suspense fallback={<SkeletonThumbnail />}>
              <Thumbnail src={img.url} active={idx === activeIndex}>
                <Chip label={idx + 1} />
              </Thumbnail>
            </Suspense>
          </button>
        ) : null
      )}
    </Box>
  );
};
