import { selectZenModeEnabled, zenModeChanged } from 'features/layout/features/config/configSlice';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select';
import { TabPanelInner } from 'components/Tabs';
import { Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import InputLabel from 'components/InputLabel';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { languageChanged } from 'features/room/actions';
import { logger } from 'utils/logger';
import { setZenModeSetting } from 'utils/zenMode';
import SettingControl from 'components/SettingControl';
import { Switch } from 'components/Switch';
import { selectSupportedLanguages } from 'features/application/applicationSlice';

const WorkspaceTab = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation('settings');
  const [language, setLanguage] = useState(i18n.language);

  const supportedLanguages = useAppSelector(selectSupportedLanguages);
  const languages = [...supportedLanguages];
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV !== 'production') {
    languages.unshift({ value: 'ach', label: 'In-Context editor' });
  }

  const handleLanguageChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    logger
      .remote({ action: true, tier: 1 })
      .info(`User changed language from ${language} to ${value}`);

    setLanguage(value);

    dispatch(languageChanged(value));
  };

  const zenModeEnabled = useAppSelector(selectZenModeEnabled);

  const handleZenModeChange = (checked: boolean) => {
    setZenModeSetting(checked);

    dispatch(zenModeChanged(!checked));
  };

  return (
    <TabPanelInner>
      <Box>
        <FormControl fullWidth>
          <InputLabel id="language-switcher-label">
            {t('workspace.language_switch_label')}
          </InputLabel>
          <Select
            labelId="language-switcher-label"
            id="language-switcher"
            onChange={handleLanguageChange}
            value={language}
          >
            {languages.map(({ value, label }, i) => (
              <MenuItem key={i} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 8 }}>
        <SettingControl
          label={t('workspace.zen_mode_switch_label')}
          helperText={t('workspace.zen_mode_switch_help')}
          alignItems="flex-start"
          control={
            <Switch
              onChange={(e) => handleZenModeChange(e.target.checked)}
              checked={!zenModeEnabled}
              inputProps={{
                'aria-label': t('workspace.zen_mode_switch_label'),
              }}
            />
          }
        />
      </Box>
    </TabPanelInner>
  );
};

export default WorkspaceTab;
