import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { PermissionsState, PermissionTypes, RoleEntries } from 'features/permissions/types';
import { RootState } from 'store/store';
import { normalizePermissions } from 'features/permissions/utils/normalizePermissions';
import { selectLocalUser, selectLocalUserRole } from 'features/users/usersSlice';
import { HasPermissionsOptions } from 'features/permissions/utils/checkPermissions';
import { refinePermissions } from 'features/permissions/utils/refinePermissions';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { selectIsRoomPublic } from 'features/room/roomSlice';

export const initialState: PermissionsState = {
  byRole: {},
  permissionsMap: {},
  allRoles: [],
  targetRolesMap: {},
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    rolesLocalized(state, action: PayloadAction<RoleEntries>) {
      state.byRole = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authorizeUser.fulfilled, (state, action) => {
      const { byRole, permissionsMap, allRoles, targetRolesMap } = normalizePermissions(
        action.payload.roles
      );

      state.permissionsMap = permissionsMap;
      state.byRole = byRole;
      state.allRoles = allRoles;
      state.targetRolesMap = targetRolesMap;
    });
  },
});

export const { rolesLocalized } = permissionsSlice.actions;

export const selectPermissionsState = (state: RootState) => state.permissions;

export const selectRoleEntries = (state: RootState) => state.permissions.byRole;
export const selectAllRoleNames = (state: RootState) => state.permissions.allRoles;

export const selectTargetRolesMap = (state: RootState) => state.permissions.targetRolesMap;

export const selectLocalBroadcastPermissions = createSelector(
  [selectPermissionsState, selectLocalUser],
  (permissionsState, localUser) =>
    refinePermissions(PermissionTypes.broadcast, {
      permissionsState,
      localUser,
    })
);

export const selectHasPermissions = createSelector(
  [
    selectPermissionsState,
    selectLocalUser,
    (state, permissions: PermissionTypes | PermissionTypes[]) => permissions,
    (state, options: HasPermissionsOptions = {}) => options,
  ],
  (permissionsState, localUser, permissions, options) =>
    refinePermissions(permissions, {
      permissionsState,
      localUser,
      ...options,
    })
);

export const selectInviteAvailable = createSelector(
  [
    selectIsRoomPublic,
    (state) => selectFeatureFlag(state, 'invite'),
    (state) => selectHasPermissions(state, PermissionTypes.inviteParticipant),
  ],
  (roomPublic, inviteEnabled, canInviteParticipant) =>
    !!(roomPublic && inviteEnabled && canInviteParticipant)
);

export const selectRolesToManage = createSelector(
  [selectLocalUserRole, selectTargetRolesMap, selectRoleEntries],
  (localRole, targetRolesMap, entries) => {
    const rolesToManage = targetRolesMap[localRole][PermissionTypes.manageRoles];

    if (!rolesToManage) {
      return null;
    }

    return rolesToManage.map((role) => ({
      value: role,
      displayName: entries[role].displayName,
    }));
  }
);

export const selectRoleDisplayName = createSelector(
  [selectRoleEntries, (state, role) => role],
  (entries, role) => entries[role].displayName
);

export default permissionsSlice.reducer;
