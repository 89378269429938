import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { eventBus } from 'utils/eventBus';
import { NotificationId } from 'features/notifications/types';
import { selectNotificationsByUser } from 'features/notifications/notificationsSlice';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';
import {
  joinNotificationTriggered,
  waitingEntryNotificationUpdateRequested,
} from 'features/notifications/actions';
import { signalingUserJoined } from 'features/users/actions';

export function* onUserJoinedSaga(action: ReturnType<typeof signalingUserJoined>) {
  const { id } = action.payload;

  yield call(eventBus.sendMessage, 'userJoined', { userId: id });

  yield put(joinNotificationTriggered({ id }));

  yield put(waitingEntryNotificationUpdateRequested());

  // cleanup stale notifications if the user was reconnected
  const notifications: NotificationId[] = yield select(selectNotificationsByUser, id);
  if (notifications.length) {
    const cleanupNotificationsThunk: Action = yield call(closeActionNotifications, notifications);
    yield put(cleanupNotificationsThunk);
  }
}
