import { createSelector, createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import { layoutModeChanged } from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import {
  Fitment,
  LayoutContentDimensionsState,
} from 'features/layout/features/contentDimensions/types';
import { selectPageStreams } from 'features/layout/features/pagination/paginationSlice';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { FeedId } from 'features/streaming/types';
import { RootState } from 'store/store';
import { layoutConfig } from 'utils/layout';

const DEFAULT_CONTENT_RATIO = 9 / 16;

export const initialState: LayoutContentDimensionsState = {
  pinnedWidth: layoutConfig.defaultPinWidth,
  width: layoutConfig.defaultPinWidth,
  videoFitment: 'crop',
  remoteVideoDimensions: {},
};

export const layoutContentDimensionsSlice = createSlice({
  name: 'layoutContentDimensions',
  initialState,
  reducers: {
    exposedVideoFitmentChanged(state, action: PayloadAction<Fitment>) {
      state.videoFitment = action.payload;
    },
    remoteVideoRatioChanged(
      state,
      action: PayloadAction<{
        feedId: FeedId;
        ratio: number;
      }>
    ) {
      state.remoteVideoDimensions[action.payload.feedId] = action.payload.ratio;
    },
    contentWidthChanged: {
      reducer(state, action: PayloadAction<number>) {
        state.width = action.payload;

        if (action.payload !== 1) {
          state.pinnedWidth = action.payload;
        }
      },
      prepare: prepareAutoBatched<number>(),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(layoutModeChanged, (state, action) => {
      // GO to default width in auto mode, go to previous width when switching back to tiled
      if (action.payload === LayoutMode.auto) {
        state.pinnedWidth = state.width === 1 ? state.pinnedWidth : state.width;
        // @FIXME: add a new action to calculate dynamic width so that sidebar dowsn;t overflow.
        //  Use it everywhere where this const is used
        // state.width = layoutConfig.maxContentWidth;

        state.width = layoutConfig.defaultPinWidth;
      } else if (state.pinnedWidth !== 0) {
        [state.pinnedWidth, state.width] = [state.width, state.pinnedWidth];
      }
    });
  },
});

export const { exposedVideoFitmentChanged, contentWidthChanged, remoteVideoRatioChanged } =
  layoutContentDimensionsSlice.actions;

export const selectContentWidth = (state: RootState) => state.layout.contentDimensions.width;

export const selectPinnedContentWidth = (state: RootState) =>
  state.layout.contentDimensions.pinnedWidth;

export const selectExposedVideoFitment = (state: RootState) =>
  state.layout.contentDimensions.videoFitment;

export const selectContentRatio = (state: RootState, source: SourceDetails) => {
  const ratio = source.feedId
    ? state.layout.contentDimensions.remoteVideoDimensions[source?.feedId]
    : undefined;

  return ratio || DEFAULT_CONTENT_RATIO;
};

export const selectExposedMode = createSelector(
  [selectMaximizedStream, selectContentWidth, selectPageStreams],
  (stream, width, streams) => {
    if (!stream) {
      return null;
    }

    if (!streams.length) {
      return 'maximize';
    }

    if (width === 1) {
      return 'maximize';
    }

    return 'pin';
  }
);

export default layoutContentDimensionsSlice.reducer;
