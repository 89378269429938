import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  signalingUserJoined,
  signalingUserLeft,
  signalingHandRaised,
  signalingHandLowered,
  kickUser,
  signalingUserKicked,
  signalingWaitingUserJoined,
  signalingWaitingUsersUpdated,
  signalingWaitingUserLeft,
} from 'features/users/actions';
import { onUserJoinedSaga } from 'features/users/sagas/onUserJoinedSaga';
import { onUserLeftSaga } from 'features/users/sagas/onUserLeftSaga';
import { raiseHandSaga } from 'features/users/sagas/raiseHandSaga';
import { onHandRaisedSaga } from 'features/users/sagas/onHandRaisedSaga';
import { lowerHandSaga } from 'features/users/sagas/lowerHandSaga';
import { onHandLoweredSaga } from 'features/users/sagas/onHandLoweredSaga';
import { handRaiseDismissed, lowerHand, raiseHand } from 'features/users/usersSlice';
import { kickUserSaga } from 'features/users/sagas/kickUserSaga';
import { onUserKickedSaga } from 'features/users/sagas/onUserKickedSaga';
import { onWaitingUserJoinedSaga } from 'features/users/sagas/onWaitingUserJoinedSaga';
import { onWaitingUsersUpdatedSaga } from 'features/users/sagas/onWaitingUsersUpdatedSaga';
import { onWaitingUserLeftSaga } from 'features/users/sagas/onWaitingUserLeftSaga';
import { onHandRaiseDismissedSaga } from 'features/users/sagas/onHandRaiseDismissedSaga';

export default function* usersWatcher() {
  yield takeEvery(signalingUserJoined, onUserJoinedSaga);
  yield takeEvery(signalingUserLeft, onUserLeftSaga);

  yield takeEvery(raiseHand, raiseHandSaga);
  yield takeEvery(signalingHandRaised, onHandRaisedSaga);
  yield takeEvery(lowerHand, lowerHandSaga);
  yield takeEvery(signalingHandLowered, onHandLoweredSaga);
  yield takeEvery(handRaiseDismissed, onHandRaiseDismissedSaga);

  yield takeLatest(kickUser, kickUserSaga);
  yield takeLatest(signalingUserKicked, onUserKickedSaga);

  yield takeEvery(signalingWaitingUserJoined, onWaitingUserJoinedSaga);
  yield takeEvery(signalingWaitingUserLeft, onWaitingUserLeftSaga);
  yield takeEvery(signalingWaitingUsersUpdated, onWaitingUsersUpdatedSaga);
}
