import React from 'react';
import {
  Badge,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  BadgeProps as MuiBadgeProps,
} from '@mui/material';
import Icon, { IconProps } from 'components/Icon';
import { styled } from '@mui/material/styles';
import { badgeClasses } from '@mui/material/Badge';
import { ControlBadgeProps } from 'features/toolbar/types';

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme: { palette, toolbar, spacing }, active }) => {
  const badgeBg = palette.getContrastText(toolbar.background);

  return {
    [`& .${badgeClasses.badge}`]: {
      border: `2px solid ${toolbar.background}`,
      padding: spacing(0, 1),
      fontSize: 10,
      fontWeight: 'bold',
      height: 17,
      lineHeight: '17px',
      minWidth: 24,
      borderRadius: '8px',
      backgroundColor: badgeBg,
      color: palette.getContrastText(badgeBg),
      ...(active && {
        backgroundColor: toolbar.badge.active.background,
        // color: palette.getContrastText(toolbar.badge.unread.background),
        color: palette.common.white,
      }),
    },
  };
});

type MobileMenuItemProps = MenuItemProps<
  'li',
  ControlBadgeProps & {
    icon: string | React.ReactElement<IconProps>;
    BadgeProps?: Omit<MuiBadgeProps, 'badgeContent'>;
  }
>;

const MobileMenuItem = ({
  children,
  badgeContent,
  badgeActive,
  icon,
  BadgeProps,
  ...props
}: MobileMenuItemProps) => (
  <MenuItem {...props}>
    <ListItemIcon>
      <StyledBadge
        {...BadgeProps}
        badgeContent={badgeContent}
        active={badgeActive}
        max={9999}
        data-testid="toolbar-control-badge"
      >
        {typeof icon === 'string' ? (
          <Icon
            name={icon}
            sx={{
              fontSize: 32,
            }}
          />
        ) : (
          icon
        )}
      </StyledBadge>
    </ListItemIcon>
    <ListItemText>{children}</ListItemText>
  </MenuItem>
);

export default MobileMenuItem;
