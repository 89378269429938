import { call } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';

export function* startPublishingSaga() {
  // 'broadcastIntended' allows us to pre-set flag that we want to automatically start publishing once we've connected to janus.
  // check for handle to avoid attaching plugin multiple times (like when saga is dispatched on granting dynamic permissions)
  if (RTCClient.publishingFeed.broadcastIntended && !RTCClient.publishingFeed.handle) {
    yield call(RTCClient.publishingFeed.attachPlugin);
  }
}
