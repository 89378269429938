import { ChatState } from 'features/chat/types';
import { initialState } from 'features/chat/chatSlice';

export const resetMessages = (state: ChatState) => {
  state.entities = {};
  state.publicIds = [];
  state.privateChat.chats = {};
  state.privateChat.activeView = initialState.privateChat.activeView;
  state.activeTab = initialState.activeTab;
};
