import { call } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { UserId } from 'features/users/types';
import { refineSframeStatus } from 'features/e2ee/sagas/refineSframeStatus';

export function* cleanupE2eeUserSaga(id: UserId, e2eeId: number) {
  yield call(refineSframeStatus);

  yield call(E2EEManager.removeReceiver, id);
  yield call(SFrameManager.deleteReceiver, e2eeId);
}
