import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GDPRConfig,
  InitRoomPayload,
  RoomState,
  RoomStatus,
  RoomTokenData,
} from 'features/room/types';
import { RootState } from 'store/store';
import { ErrorPayload } from 'features/application/types';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import {
  sessionEnded,
  sessionLeft,
  signalingEntryAccessAwaitingStarted,
  signalingRoomJoined,
  signalingRoomJoinPaused,
  signalingRoomLocked,
  signalingRoomUnlocked,
  signalingSessionAwaitingStarted,
} from 'features/room/actions';
import { UserName } from 'features/users/types';

export const initialState: RoomState = {
  private: false,
  id: '',
  status: 'initializing',
  error: {
    global: false,
    name: '',
    message: '',
  },
  token: {},
  joinScreenEnabled: true,
  logoEnabled: false,
  logoUrl: '',
  topbarEnabled: false,
  broadcastSetup: {
    reason: null,
    initiator: '',
    active: false,
  },
  awaitingJoinReason: null,
  consentConfig: null,
  locked: false,
  entryProgress: null,
  broadcasterLimit: 1,
  breakout: false,
  transcriptionEnabled: false,
};

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    initRoomStarted(state, action: PayloadAction<InitRoomPayload>) {
      state.id = action.payload.roomId;
      if (action.payload.token) {
        state.token.jwt = action.payload.token;
      }
    },
    roomStatusUpdated(state, action: PayloadAction<RoomStatus>) {
      state.status = action.payload;
    },
    roomJoinStarted(state) {
      state.entryProgress = 'joining';
    },
    loginStarted(state) {
      state.entryProgress = 'authorizing';
    },
    loginFinished(state) {
      state.entryProgress = null;
      state.status = 'join';
    },
    loginFailed(state) {
      state.entryProgress = null;
    },
    roomJoined(state) {
      state.status = 'joined';
      state.entryProgress = null;
      state.consentConfig = null;
    },
    roomTokenParsed(state, action: PayloadAction<RoomTokenData>) {
      state.token.parsedData = action.payload;
    },
    roomError(state, action: PayloadAction<ErrorPayload | undefined>) {
      state.status = 'error';
      if (action.payload) {
        state.error.name = action.payload.name;
        state.error.title = action.payload.title;
        state.error.message = action.payload.message;

        if (action.payload.global) {
          state.error.global = action.payload.global;
        }
      }
    },
    broadcastSetupStarted(state, action: PayloadAction<{ initiator?: UserName; room?: string }>) {
      state.broadcastSetup.active = true;

      if (action.payload.initiator !== undefined) {
        state.broadcastSetup.initiator = action.payload.initiator;
        state.broadcastSetup.reason = 'promotion';
      } else {
        state.broadcastSetup.reason = 'breakout';
      }
    },
    broadcastSetupEnded(state) {
      state.broadcastSetup.active = false;
      state.broadcastSetup.initiator = '';
      state.broadcastSetup.reason = null;
    },
    consentConfigReceived(state, action: PayloadAction<GDPRConfig>) {
      // [fun stuff]
      const linkTag = action.payload.message.match(/(\[link).+(\[\/link\])/)?.[0];

      if (linkTag) {
        // ugly regexes because sfafari doesn't support lookbehind operator until the very recent version.
        const linkText = linkTag.match(/(?:\]).+?(?=\[)/)?.[0].slice(1);
        const linkHref = linkTag.match(/(?:\[link ).+?(?=\])/)?.[0].split(' ')?.[1];

        if (linkHref && linkText) {
          action.payload.message = action.payload.message.replace(
            linkTag,
            `<a href="${linkHref}" target="_blank" rel="noopener nofollow">${linkText}</a>`
          );
        }
      }
      // [/fun stuff]

      state.consentConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorizeUser.fulfilled, (state, action) => {
        console.log('action', action);
        state.joinScreenEnabled = action.payload.settings.joinScreenEnabled;
        state.logoEnabled = action.payload.settings.logoEnabled;
        state.logoUrl = action.payload.settings.logoUrl;
        state.topbarEnabled = action.payload.settings.topbarEnabled;
        state.transcriptionEnabled = action.payload.settings.transcriptionEnabled;
        state.private = action.payload.private;
      })
      .addCase(sessionEnded, (state) => {
        state.status = 'ended';
      })
      .addCase(signalingSessionAwaitingStarted, (state) => {
        state.awaitingJoinReason = 'session-start';
      })
      .addCase(signalingEntryAccessAwaitingStarted, (state) => {
        state.awaitingJoinReason = 'entry-access';
      })
      .addCase(signalingRoomJoinPaused, (state) => {
        state.awaitingJoinReason = 'scale-server-boot';
      })
      .addCase(signalingRoomJoined, (state, action) => {
        state.awaitingJoinReason = null;

        state.id = action.payload.roomId;

        state.locked = action.payload.roomLocked;
        state.broadcasterLimit = action.payload.broadcastersLimit;
        state.name = action.payload.roomName;
        state.breakout = action.payload.breakout;
      })
      .addCase(sessionLeft, (state) => {
        state.status = 'left';
      })
      .addCase(signalingRoomLocked, (state) => {
        state.locked = true;
      })
      .addCase(signalingRoomUnlocked, (state) => {
        state.locked = false;
      });
  },
});

export const {
  initRoomStarted,
  roomStatusUpdated,
  roomJoinStarted,
  roomJoined,
  roomError,
  roomTokenParsed,
  broadcastSetupStarted,
  broadcastSetupEnded,
  consentConfigReceived,
  loginFinished,
  loginStarted,
  loginFailed,
} = roomSlice.actions;

export const selectIsRoomPublic = (state: RootState) => !state.room.private;

export const selectRoomName = (state: RootState) => state.room.name;

export const selectRoomStatus = (state: RootState) => state.room.status;
export const selectRoomError = (state: RootState) => state.room.error;
export const selectRoomEntryProgress = (state: RootState) => state.room.entryProgress;
export const selectRoomToken = (state: RootState) => state.room.token;
export const selectRoomId = (state: RootState) => state.room.id;
export const selectRoomLogoEnabled = (state: RootState) => state.room.logoEnabled;
export const selectJoinScreenEnabled = (state: RootState) => state.room.joinScreenEnabled;

export const selectTranscriptionEnabled = (state: RootState) => state.room.transcriptionEnabled;

export const selectTopBarVisibility = (state: RootState) => state.room.topbarEnabled;

export const selectIsBroadcastSetupActive = (state: RootState) => state.room.broadcastSetup.active;
export const selectIsBroadcastSetupInitiator = (state: RootState) =>
  state.room.broadcastSetup.initiator;

export const selectAwaitingRoomJoin = (state: RootState) => state.room.awaitingJoinReason;

export const selectCustomLogoUrl = (state: RootState) => state.room.logoUrl;

export const selectConsentConfig = (state: RootState) => state.room.consentConfig;
export const selectRoomLocked = (state: RootState) => state.room.locked;

export const selectBroadcasterLimit = (state: RootState) => state.room.broadcasterLimit;

export const selectIsBreakoutRoom = (state: RootState) => state.room.breakout;

export const selectBroadcastSetupReason = (state: RootState) => state.room.broadcastSetup.reason;

export default roomSlice.reducer;
