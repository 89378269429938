import { createSelector, createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  ActionNotification,
  ActionNotificationUpdatePayload,
  NotificationId,
  NotificationName,
  NotificationsState,
  SimpleNotification,
  SimpleNotificationUpdatePayload,
} from 'features/notifications/types';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { InteractionKind } from 'features/application/types';
import { UserId } from 'features/users/types';
import { HAND_RAISED_NOTIFICATION_ID } from 'features/notifications/constants';
import { signalingUserJoined, signalingUserLeft } from 'features/users/actions';

export const initialState: NotificationsState = {
  actionNotifications: {
    panelVisible: false,
    byId: {},
    allIds: [],
    bufferIds: [],
  },
  simpleNotifications: {
    allIds: [],
    byId: {},
  },
  blacklist: [],
  leftUserCount: 0,
  joinedUsersCount: 0,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    actionNotificationDisplayed: {
      reducer(state, action: PayloadAction<ActionNotification>) {
        state.actionNotifications.allIds.unshift(action.payload.id);
        state.actionNotifications.byId[action.payload.id] = action.payload;

        if (!state.actionNotifications.panelVisible) {
          state.actionNotifications.bufferIds.unshift(action.payload.id);
        }
      },
      prepare: prepareAutoBatched<ActionNotification>(),
    },
    actionNotificationUpdated: {
      reducer(state, action: PayloadAction<ActionNotificationUpdatePayload>) {
        Object.assign(state.actionNotifications.byId[action.payload.id], action.payload);
      },
      prepare: prepareAutoBatched<ActionNotificationUpdatePayload>(),
    },
    actionNotificationClosed: {
      reducer(state, action: PayloadAction<NotificationId>) {
        const allIds = state.actionNotifications.allIds.filter((id) => id !== action.payload);

        state.actionNotifications.allIds = allIds;
        state.actionNotifications.bufferIds = state.actionNotifications.bufferIds.filter(
          (id) => id !== action.payload
        );
        delete state.actionNotifications.byId[action.payload];

        if (allIds.length === 0) {
          state.actionNotifications.panelVisible = false;
        }
      },
      prepare: prepareAutoBatched<NotificationId>(),
    },
    showNotificationsPanel(state) {
      state.actionNotifications.panelVisible = true;
      state.actionNotifications.bufferIds = [];
    },
    simpleNotificationDisplayed: {
      reducer(state, action: PayloadAction<SimpleNotification>) {
        state.simpleNotifications.allIds.push(action.payload.id);
        state.simpleNotifications.byId[action.payload.id] = action.payload;
      },
      prepare: prepareAutoBatched<SimpleNotification>(),
    },

    simpleNotificationUpdated: {
      reducer(state, action: PayloadAction<SimpleNotificationUpdatePayload>) {
        Object.assign(state.simpleNotifications.byId[action.payload.id], action.payload);
      },
      prepare: prepareAutoBatched<SimpleNotificationUpdatePayload>(),
    },
    simpleNotificationClosed: {
      reducer(state, action: PayloadAction<NotificationId>) {
        state.simpleNotifications.allIds = state.simpleNotifications.allIds.filter(
          (id) => id !== action.payload
        );
        delete state.simpleNotifications.byId[action.payload];
      },
      prepare: prepareAutoBatched<NotificationId>(),
    },
    resetJoinedUsersCount(state) {
      state.joinedUsersCount = 0;
    },
    resetLeftUsersCount(state) {
      state.leftUserCount = 0;
    },
    actionNotificationsReset(state) {
      state.actionNotifications = initialState.actionNotifications;
    },
    simpleNotificationsReset(state) {
      state.simpleNotifications = initialState.simpleNotifications;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorizeUser.fulfilled, (state, action) => {
        state.blacklist = action.payload.notificationsBlacklist;
      })
      .addCase(signalingUserJoined, (state) => {
        state.joinedUsersCount += 1;
      })
      .addCase(signalingUserLeft, (state) => {
        state.leftUserCount += 1;
      });
  },
});

export const {
  actionNotificationDisplayed,
  showNotificationsPanel,
  actionNotificationClosed,
  actionNotificationUpdated,
  simpleNotificationUpdated,
  simpleNotificationDisplayed,
  simpleNotificationClosed,
  resetLeftUsersCount,
  resetJoinedUsersCount,
  actionNotificationsReset,
  simpleNotificationsReset,
} = notificationsSlice.actions;

export const selectAllNotificationIds = (state: RootState) =>
  state.notifications.actionNotifications.allIds;
export const selectNotificationEntries = (state: RootState) =>
  state.notifications.actionNotifications.byId;

export const selectNotificationById = (state: RootState, id: NotificationId) =>
  state.notifications.actionNotifications.byId[id];

export const selectHandRaisedNotification = (state: RootState) => {
  const allIds = selectAllNotificationIds(state);

  const handRaisedId = allIds.find(
    (id) => typeof id === 'string' && id.includes(HAND_RAISED_NOTIFICATION_ID)
  );

  if (!handRaisedId) {
    return undefined;
  }

  return selectNotificationById(state, handRaisedId);
};

export const selectActionNotifications = createSelector(
  [selectNotificationEntries, selectAllNotificationIds],
  (byId, ids) => ids.map((id) => byId[id])
);

export const selectBufferNotificationIds = (state: RootState) =>
  state.notifications.actionNotifications.bufferIds;

export const selectActionNotificationPanel = (state: RootState) =>
  state.notifications.actionNotifications.panelVisible;

export const selectNotificationsByInteraction = createSelector(
  [selectNotificationEntries, (state, interaction: InteractionKind) => interaction],
  (byId, interaction) => {
    const ids: NotificationId[] = [];
    for (const notification of Object.values(byId)) {
      if (notification.interaction === interaction) {
        ids.push(notification.id);
      }
    }
    return ids;
  }
);
export const selectNotificationsByUser = createSelector(
  [selectNotificationEntries, (state, userId: UserId) => userId],
  (byId, userId) => {
    const ids: NotificationId[] = [];
    for (const notification of Object.values(byId)) {
      if (notification.initiatorId === userId && !notification.userAgnostic) {
        ids.push(notification.id);
      }
    }
    return ids;
  }
);

export const selectNotificationsByScope = createSelector(
  [selectNotificationEntries, (state, scope: string) => scope],
  (byId, scope) => {
    const ids: NotificationId[] = [];

    for (const notification of Object.values(byId)) {
      if (notification.scope?.includes(scope)) {
        ids.push(notification.id);
      }
    }

    return ids;
  }
);

export const selectIsNotificationBlacklisted = (state: RootState, name: NotificationName) =>
  state.notifications.blacklist.includes(name);

export const selectSimpleNotificationById = (state: RootState, id: NotificationId) =>
  state.notifications.simpleNotifications.byId[id];

export const selectAllSimpleNotificationIds = (state: RootState) =>
  state.notifications.simpleNotifications.allIds;

export const selectJoinedUsersCount = (state: RootState) => state.notifications.joinedUsersCount;
export const selectLeftUsersCount = (state: RootState) => state.notifications.leftUserCount;

export default notificationsSlice.reducer;
