import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import Icon from 'components/Icon';
import React from 'react';

const StreamControlMenuItemOuter = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },
}));

interface StreamControlMenuItemProps {
  icon: string;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => void;
}

export const StreamControlMenuItem = ({ icon, text, onClick }: StreamControlMenuItemProps) => (
  <StreamControlMenuItemOuter onClick={onClick}>
    <ListItemIcon>
      <Icon name={icon} />
    </ListItemIcon>
    <ListItemText>{text}</ListItemText>
  </StreamControlMenuItemOuter>
);
