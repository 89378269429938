import { Dialog as MDialog } from '@mui/material';
import { styled } from '@mui/material/styles';

const Dialog = styled(MDialog, {
  shouldForwardProp: (prop) => prop !== 'centered',
})<{ centered?: boolean }>(({ centered = true, theme }) => ({
  '&& .MuiDialog-paperScrollBody': {
    verticalAlign: centered ? 'middle' : 'top',
  },
  '& .MuiDialogContentText-root': {
    whiteSpace: 'pre-wrap',
  },
  '& .MuiDialogActions-root': {
    '> *': {
      fontWeight: 500,
    },
  },
  '& .MuiIconButton-root': {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  '& .MuiDialogTitle-root': {
    paddingTop: theme.spacing(6),
    whiteSpace: 'pre-wrap',
  },
}));

export default Dialog;
