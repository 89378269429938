import { useCaptionsControl } from 'features/toolbar/Toolbar/controls/captions/useCaptionsControl';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import React from 'react';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';

const CaptionsControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { active, label, toggleCaptions, isFeatureAvailable } = useCaptionsControl();

  if (!isFeatureAvailable) {
    return null;
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    toggleCaptions();
  };

  return (
    <ToolbarMenuItem
      id="captions-control"
      overlay={label}
      ariaLabel={label}
      active={active}
      icon="closed-caption"
      onClick={handleClick}
    />
  );
};

export default CaptionsControl;
