import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import {
  selectHistoricalSpeakers,
  selectOrderedStreams,
  speakersUpdated,
} from 'features/layout/features/order/orderSlice';
import { put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { orderChanged, streamMinimized } from 'features/layout/actions';
import { UserId } from 'features/users/types';
import { SourceDetails } from '../types';

export function* onUserStoppedBroadcastingSaga(action: PayloadAction<UserId>) {
  const streams: SourceDetails[] = yield select(selectOrderedStreams);
  const speakers: SourceDetails[] = yield select(selectHistoricalSpeakers);
  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);

  yield put(orderChanged(streams.filter((stream) => stream.userId !== action.payload)));
  yield put(speakersUpdated(speakers.filter((stream) => stream.userId !== action.payload)));

  if (maximizedStream?.userId === action.payload) {
    yield put(streamMinimized());
  }
}
