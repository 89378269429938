import { PayloadAction } from '@reduxjs/toolkit';
import { eventBus } from 'utils/eventBus';
import { loadInContextEditor } from 'utils/loadInContextEditor';
import i18n from 'i18n';
import { call, put, select } from 'redux-saga/effects';
import { rolesLocalized, selectRoleEntries } from 'features/permissions/permissionsSlice';
import { RoleEntries } from 'features/permissions/types';
import { localizeRoles } from 'features/permissions/utils/localizeRoles';

export function* changeLanguageSaga(action: PayloadAction<string>) {
  yield call(i18n.changeLanguage, action.payload);

  yield call(eventBus.sendMessage, 'appLanguageChanged', {}, { language: action.payload });

  if (action.payload === 'ach') {
    yield call(loadInContextEditor);
  }

  // localize roles on every language change
  const roles: RoleEntries = yield select(selectRoleEntries);
  const localizedRoles: RoleEntries = yield call(localizeRoles, roles);

  yield put(rolesLocalized(localizedRoles));
}
