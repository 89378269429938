import { Tab, TabPanel } from 'components/Tabs';
import { FeatureGuard } from 'features/feature-set/FeatureGuard';
import VideoTab from 'features/room/settings/tabs/VideoTab';
import { selectVirtualBackgroundAvailable } from 'features/virtual-backgrounds/selectors';
import { useForm } from 'react-hook-form';
import SettingsTabs from 'components/SettingsTabs';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import WorkspaceTab from 'features/room/settings/tabs/WorkspaceTab';
import AudioTabContainer from 'features/room/settings/tabs/AudioTabContainer';
import { selectActiveMediaDevices } from 'features/user-media/userMediaSlice';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { selectDevModeEnabled } from 'features/dev-mode/devModeSlice';
import DevModeTab from 'features/room/settings/tabs/DevModeTab';
import { canExposeInternals } from 'utils/development';
import SystemStatsTab from 'features/room/settings/tabs/SystemStatsTab';
import VirtualBackground from 'features/room/settings/tabs/VirtualBackground';
import Captions from 'features/room/settings/tabs/Captions';
import React from 'react';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import ComponentsTab from 'features/room/settings/tabs/ComponentsTab';

export interface RoomSettingsFormValues {
  videoinput: string;
  audiooutput: string;
  audioinput: string;
}

interface RoomSettingsProps {
  activeTabIndex?: number;
}

const RoomSettings = ({ activeTabIndex }: RoomSettingsProps) => {
  const { t } = useTranslation('settings');
  const languageSelectionEnabled = useFeatureFlag('languageSelection');

  const activeMediaDevices = useAppSelector(selectActiveMediaDevices);
  const devModeEnabled = useAppSelector(selectDevModeEnabled);

  // @TODO handle tab form values separately once we have more features, consider removing the form if there is no fields validation
  const { control, setValue } = useForm<RoomSettingsFormValues>({
    defaultValues: {
      audioinput: activeMediaDevices.audioinput || '',
      audiooutput: activeMediaDevices.audiooutput || '',
      videoinput: activeMediaDevices.videoinput || '',
    },
  });

  const { hasPermissions } = usePermissions();
  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);

  const VBAvailable = useAppSelector(selectVirtualBackgroundAvailable);
  const showVB = allowBroadcast && VBAvailable;

  const captionsEnabled = useFeatureFlag('captions');

  const canControlComponents = hasPermissions(PermissionTypes.controlRoomComponents);

  return (
    <SettingsTabs
      defaultActiveIndex={activeTabIndex || (languageSelectionEnabled ? 0 : 1)}
      renderPanels={(activeTab) => (
        <>
          {devModeEnabled && (
            <TabPanel
              value={activeTab}
              index={99}
              id="devmode-tabpanel"
              aria-labelledby="devmode-tab"
            >
              <DevModeTab />
            </TabPanel>
          )}
          {/* @TODO FIXME get rid of language selection dependency once we have more options*/}
          {languageSelectionEnabled && (
            <TabPanel
              value={activeTab}
              index={0}
              id="workspace-tabpanel"
              aria-labelledby="workspace-tab"
            >
              <WorkspaceTab />
            </TabPanel>
          )}

          {allowBroadcast && (
            <TabPanel value={activeTab} index={1} id="video-tabpanel" aria-labelledby="video-tab">
              <VideoTab control={control} setValue={setValue} />
            </TabPanel>
          )}
          <TabPanel value={activeTab} index={2} id="audio-tabpanel" aria-labelledby="audio-tab">
            <AudioTabContainer control={control} setValue={setValue} />
          </TabPanel>
          {showVB && (
            <TabPanel value={activeTab} index={3} id="vb-tabpanel" aria-labelledby="vb-tab">
              <VirtualBackground />
            </TabPanel>
          )}
          <FeatureGuard feature="captions">
            <TabPanel
              value={activeTab}
              index={4}
              id="captions-tabpanel"
              aria-labelledby="captions-tab"
            >
              <Captions />
            </TabPanel>
          </FeatureGuard>
          {canControlComponents && (
            <TabPanel
              value={activeTab}
              index={5}
              id="components-tabpanel"
              aria-labelledby="components-tab"
            >
              <ComponentsTab />
            </TabPanel>
          )}
          {allowBroadcast && canExposeInternals() ? (
            <TabPanel value={activeTab} index={100} id="stats-tabpanel" aria-labelledby="stats-tab">
              <SystemStatsTab />
            </TabPanel>
          ) : null}
        </>
      )}
    >
      {devModeEnabled && (
        <Tab
          label={t('dev_mode.tab_label')}
          value={99}
          id="devmode-tab"
          aria-controls="devmode-tabpanel"
        />
      )}
      {languageSelectionEnabled && (
        <Tab
          label={t('workspace.tab_label')}
          value={0}
          id="workspace-tab"
          aria-controls="workspace-tabpanel"
        />
      )}
      {allowBroadcast && (
        <Tab label={t('video.tab_label')} value={1} id="video-tab" aria-controls="video-tabpanel" />
      )}
      <Tab label={t('audio.tab_label')} value={2} id="audio-tab" aria-controls="audio-tabpanel" />
      {showVB && (
        <Tab label={t('virtual_bg.tab_label')} value={3} id="vb-tab" aria-controls="vb-tabpanel" />
      )}
      {captionsEnabled && (
        <Tab
          label={t('captions.tab_label')}
          value={4}
          id="captions-tab"
          aria-controls="captions-tabpanel"
        />
      )}
      {canControlComponents && (
        <Tab
          label={t('components.tab_label')}
          value={5}
          id="components-tab"
          aria-controls="components-tabpanel"
        />
      )}
      {allowBroadcast && canExposeInternals() ? (
        <Tab
          label={t('system_stats.tab_label')}
          value={100}
          id="stats-tab"
          aria-controls="stats-tabpanel"
        />
      ) : null}
    </SettingsTabs>
  );
};

export default RoomSettings;
