import Plausible from 'plausible-tracker';
import { useCallback } from 'react';

export const useTracker = () => {
  const init = useCallback(() => {
    window.tracker = Plausible({
      domain: process.env.REACT_APP_TRACKING_DOMAIN,
      apiHost: 'https://plausible.wbcnf.net',
    });

    window.tracker.enableAutoPageviews();
  }, []);

  return { init };
};
