import { refineMediaDevices } from 'features/user-media/utils/refineMediaDevices';
import webrtcAdapter from 'webrtc-adapter';
import {
  enumerateDevices,
  getDefaultActiveDevices,
  getDefaultMediaDevice,
  getDevicePermissions,
  getDevicePromptConstraints,
  isMediaDeviceAvailable,
  stopStreamTracks,
} from 'features/user-media/utils';
import { getUserMedia } from 'features/user-media/utils/getUserMedia';
import { roomJoinRequest } from 'features/room/actions';
import { useMediaDevices } from 'hooks/useMediaDevices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  activeMediaDeviceUpdated,
  facingModeChanged,
  mediaDevicesUpdated,
  selectJoinMediaDefaults,
} from 'features/user-media/userMediaSlice';
import { isDomException } from 'utils/types';
import { logger } from 'utils/logger';
import { getDefaultPromptedDevice } from 'features/user-media/utils/getDefaultPromptedDevice';
import { environment } from 'utils/webrtc/environment';
import * as Sentry from '@sentry/react';
import { useSingularUserMedia } from 'hooks/useSingularUserMedia';
import { useEffect } from 'react';
import { findDeviceDifferences } from 'features/user-media/utils/findDeviceDifferences';
import { useBroadcastSetupMedia } from 'utils/broadcast-setup';
import { usePublishingFeed } from 'hooks/usePublishingFeed';
import { PublishingFeed } from 'utils/webrtc/publishing/PublishingFeed';

export const useFastJoinMedia = () => {
  const appDispatch = useAppDispatch();

  const {
    audioStream,
    videoStream,
    dispatch,
    state,
    enableAudio,
    enableCamera,
    disableCamera,
    disableAudio,
  } = useBroadcastSetupMedia();

  const mediaDefaults = useAppSelector(selectJoinMediaDefaults);
  const { updateMediaDevices, activeMediaDevices, mediaDevices, micPermissions, camPermissions } =
    useMediaDevices();

  const requestSingularUserMedia = useSingularUserMedia();

  const feed = usePublishingFeed('media');
  const publishingFeed = feed.control as PublishingFeed;

  useEffect(() => {
    const listener = async () => {
      const updatedDevices = await enumerateDevices();
      const { removedActiveDevices, addedDevices } = findDeviceDifferences(
        updatedDevices,
        mediaDevices,
        activeMediaDevices
      );

      if (Object.keys(removedActiveDevices).length) {
        if (removedActiveDevices.audioinput) {
          try {
            const deviceId = getDefaultMediaDevice(updatedDevices, 'audioinput');

            if (!deviceId) {
              throw new Error('Could not find audio device');
            }

            disableAudio();
            await enableAudio(deviceId);
          } catch (error) {
            Sentry.captureException(error);
          }
        }

        if (removedActiveDevices.videoinput) {
          try {
            const deviceId = getDefaultMediaDevice(updatedDevices, 'videoinput');

            if (!deviceId) {
              throw new Error('Could not find video device');
            }

            await disableCamera();
            await enableCamera(deviceId);
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      } else if (Object.keys(addedDevices).length) {
        if (micPermissions !== 'granted' && addedDevices.audioinput) {
          try {
            const permissions = getDevicePermissions(updatedDevices, 'audioinput');

            if (permissions === 'granted') {
              const deviceId = getDefaultMediaDevice(updatedDevices, 'audioinput');

              if (!deviceId) {
                throw new Error('Could not find audio device');
              }

              await enableAudio(deviceId);
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        }

        if (camPermissions !== 'granted' && addedDevices.videoinput) {
          try {
            const permissions = getDevicePermissions(updatedDevices, 'videoinput');

            if (permissions === 'granted') {
              const deviceId = getDefaultMediaDevice(updatedDevices, 'videoinput');

              if (!deviceId) {
                throw new Error('Could not find video device');
              }

              await enableCamera(deviceId);
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      }

      if (removedActiveDevices.audiooutput || addedDevices.audiooutput) {
        const deviceId = getDefaultMediaDevice(updatedDevices, 'audiooutput');

        if (!deviceId) {
          throw new Error('Error updating media devices');
        }

        appDispatch(
          activeMediaDeviceUpdated({
            kind: 'audiooutput',
            id: deviceId,
          })
        );
      }

      appDispatch(
        mediaDevicesUpdated(updatedDevices, {
          updatePermissions: true,
        })
      );
    };

    navigator.mediaDevices.addEventListener('devicechange', listener);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', listener);
    };
  }, [
    activeMediaDevices,
    appDispatch,
    camPermissions,
    disableAudio,
    disableCamera,
    enableAudio,
    enableCamera,
    mediaDevices,
    micPermissions,
  ]);

  useEffect(() => {
    const videoTrack = videoStream?.getVideoTracks()[0];
    if (!videoTrack) {
      return undefined;
    }

    const listener = async () => {
      const updatedDevices = await enumerateDevices();
      const permissions = getDevicePermissions(updatedDevices, 'videoinput');

      if (permissions !== 'granted') {
        appDispatch(
          mediaDevicesUpdated(updatedDevices, {
            updateActiveDevices: true,
            updatePermissions: true,
          })
        );

        await disableCamera();
      }
    };

    videoTrack.addEventListener('ended', listener);

    return () => {
      videoTrack.removeEventListener('ended', listener);
    };
  }, [appDispatch, disableCamera, videoStream]);

  useEffect(() => {
    const audioTrack = audioStream?.getAudioTracks()[0];
    if (!audioTrack) {
      return undefined;
    }

    const listener = async () => {
      const updatedDevices = await enumerateDevices();
      const permissions = getDevicePermissions(updatedDevices, 'audioinput');

      if (permissions !== 'granted') {
        appDispatch(
          mediaDevicesUpdated(updatedDevices, {
            updateActiveDevices: true,
            updatePermissions: true,
          })
        );

        disableAudio();
      }
    };

    audioTrack.addEventListener('ended', listener);

    return () => {
      audioTrack.removeEventListener('ended', listener);
    };
  }, [appDispatch, disableAudio, audioStream]);

  const handleMediaError = async (error: unknown) => {
    if (isDomException(error)) {
      logger.error(`${error.name}: ${error.message}`);

      const audioRequest = requestSingularUserMedia({
        audio: {
          deviceId: {
            exact: getDefaultPromptedDevice(mediaDevices, 'audioinput'),
          },
        },
      });

      const videoRequest = requestSingularUserMedia({
        video: {
          ...environment.videoConstraints,
          deviceId: {
            exact: getDefaultPromptedDevice(mediaDevices, 'videoinput'),
          },
        },
      });

      const [audio, video] = await Promise.all([audioRequest, videoRequest]);
      await updateMediaDevices();

      if (video.stream) {
        if (mediaDefaults.video) {
          publishingFeed.setJoinMedia(video.stream, 'video');
          dispatch({
            type: 'mediaUpdated',
            payload: { type: 'video', activateMedia: true },
          });
        } else {
          stopStreamTracks(video.stream);
        }
      }

      if (audio.stream) {
        if (mediaDefaults.audio) {
          publishingFeed.setJoinMedia(audio.stream, 'audio');
          dispatch({
            type: 'mediaUpdated',
            payload: { type: 'audio', activateMedia: true },
          });
        } else {
          stopStreamTracks(audio.stream);
        }
      }

      if (audio.stream || video.stream) {
        appDispatch(roomJoinRequest());
      } else if (audio.error.name === 'NotAllowedError' && video.error.name === 'NotAllowedError') {
        dispatch({ type: 'statusUpdated', payload: 'denied' });
      } else {
        dispatch({ type: 'statusUpdated', payload: 'failure' });
      }
    } else {
      logger.error(error);
      Sentry.captureException(error);
      dispatch({ type: 'statusUpdated', payload: 'failure' });
    }
  };

  const requestPermissions = async () => {
    try {
      const updatedMediaDevices = await refineMediaDevices(updateMediaDevices);

      // check against the media defaults from the backend
      // if all values are false, obtain only device permissions and list of devices
      if (!mediaDefaults.video && !mediaDefaults.audio) {
        if (webrtcAdapter.browserDetails.browser === 'firefox') {
          const constraints = getDevicePromptConstraints(updatedMediaDevices);

          const stream = await getUserMedia(constraints);
          const activeDevices = getDefaultActiveDevices(updatedMediaDevices);

          await updateMediaDevices({
            updatePermissions: true,
            updateActiveDevices: true,
            activeDevices,
          });
          stopStreamTracks(stream);

          appDispatch(roomJoinRequest());
          return;
        }

        const activeDevices = getDefaultActiveDevices(updatedMediaDevices);

        await updateMediaDevices({
          updatePermissions: true,
          updateActiveDevices: true,
          activeDevices,
        });

        appDispatch(roomJoinRequest());
        return;
      }

      const constraints = {
        audio: isMediaDeviceAvailable(mediaDevices, 'audioinput')
          ? {
              deviceId: {
                exact: getDefaultPromptedDevice(mediaDevices, 'audioinput'),
              },
            }
          : false,
        video: isMediaDeviceAvailable(mediaDevices, 'videoinput')
          ? {
              ...environment.videoConstraints,
              deviceId: {
                exact: getDefaultPromptedDevice(mediaDevices, 'videoinput'),
              },
            }
          : false,
      };

      const stream = await getUserMedia(constraints);

      const activeDevices = getDefaultActiveDevices(mediaDevices);

      await updateMediaDevices({
        updatePermissions: true,
        updateActiveDevices: true,
        activeDevices,
      });

      if (mediaDefaults.video) {
        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack) {
          const newVideoStream = new MediaStream();
          newVideoStream.addTrack(videoTrack.clone());

          const settings = videoTrack.getSettings();

          if (settings.facingMode) {
            appDispatch(facingModeChanged(settings.facingMode));
          }
          publishingFeed.setJoinMedia(newVideoStream, 'video');
          dispatch({
            type: 'mediaUpdated',
            payload: { type: 'video', activateMedia: true },
          });
        }
      }

      if (mediaDefaults.audio) {
        const audioTrack = stream.getAudioTracks()[0];
        if (audioTrack) {
          const newAudioStream = new MediaStream();
          newAudioStream.addTrack(audioTrack.clone());
          publishingFeed.setJoinMedia(newAudioStream, 'audio');
          dispatch({
            type: 'mediaUpdated',
            payload: { type: 'audio', activateMedia: true },
          });
        }
      }

      stopStreamTracks(stream);

      appDispatch(roomJoinRequest());
    } catch (error) {
      await handleMediaError(error);
    }
  };

  return {
    requestPermissions,
    state,
  };
};
