import { UserEntries } from 'features/users/types';
import { selectUsers } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { sha256 } from 'features/e2ee/utils/sha256';
import { chunkSubstring } from 'utils/chunkSubstring';
import { roomE2eeCodeGenerated } from 'features/e2ee/e2eeSlice';

export function* generateRoomE2eeCode() {
  const users: UserEntries = yield select(selectUsers);

  // sort users by e2ee id
  const sortedUsers = Object.values(users).sort((a, b) => a.e2eeId! - b.e2eeId!);

  // room security code will be based on all public keys
  let concatenatedPublicKeys = '';
  for (const user of sortedUsers) {
    concatenatedPublicKeys += user.e2eePublicKey;
  }

  // make it beauty
  const hex: string = yield call(sha256, concatenatedPublicKeys);
  const decimalString = BigInt(`0x${hex}`).toString(10);
  const chunks: string[] = yield call(chunkSubstring, decimalString, 5);

  yield put(roomE2eeCodeGenerated(chunks));
}
