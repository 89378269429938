import { PayloadAction } from '@reduxjs/toolkit';
import {
  captionsReceived,
  captionsReset,
  selectCaptionsResetTimer,
} from 'features/captions/captionsSlice';
import { CaptionsSpeechChunk } from 'features/captions/types';
import { UserId } from 'features/users/types';
import { put, select } from 'redux-saga/effects';
import { store } from 'store/store';

const poorMansPut = (userId: UserId) =>
  window.setTimeout(() => {
    store.dispatch(captionsReset(userId));
  }, 12 * 1000);

export function* onSpeechCaptionedSaga(action: PayloadAction<CaptionsSpeechChunk>) {
  const userId = action.payload.participant.id;
  const existingTimer: number | undefined = yield select(selectCaptionsResetTimer, userId);

  window.clearTimeout(existingTimer);

  const timer = poorMansPut(userId);

  yield put(captionsReceived({ chunk: action.payload, timer }));
}
