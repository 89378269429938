import { AppStartListening } from 'store/listenerMiddleware';
import {
  selectHandRaisedNotification,
  selectNotificationById,
  selectNotificationsByScope,
} from 'features/notifications/notificationsSlice';
import { WAITING_ENTRY_NOTIFICATION_ID } from 'features/notifications/constants';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { updateActionNotification } from 'features/notifications/updateActionNotification';

export const panelChangeListener = (startListening: AppStartListening) =>
  startListening({
    predicate: (action, currentState, originalState) => {
      const originalPanel = selectToolbarPanel(originalState);
      const currentPanel = selectToolbarPanel(currentState);

      return currentPanel !== originalPanel;
    },
    effect: (action, { dispatch, getState }) => {
      const state = getState();

      const activePanel = selectToolbarPanel(state);

      // waiting notification
      const waitingEntryNotification = selectNotificationById(state, WAITING_ENTRY_NOTIFICATION_ID);
      if (waitingEntryNotification) {
        dispatch(
          updateActionNotification(WAITING_ENTRY_NOTIFICATION_ID, {
            okAction: activePanel === 'users' ? undefined : toolbarPanelOpened('users'),
          })
        );
      }

      // hand raised notification
      const handRaisedNotification = selectHandRaisedNotification(state);
      if (handRaisedNotification) {
        dispatch(
          updateActionNotification(handRaisedNotification.id, {
            okAction: activePanel === 'users' ? undefined : toolbarPanelOpened('users'),
          })
        );
      }

      // live answer notification
      const liveAnswerNotifications = selectNotificationsByScope(state, 'qa');
      liveAnswerNotifications.forEach((id) => {
        const notification = selectNotificationById(state, id);
        if (notification) {
          dispatch(
            updateActionNotification(id, {
              okAction:
                activePanel === 'qa'
                  ? undefined
                  : toolbarPanelOpened('qa', {
                      questionId: notification.data?.questionId,
                    }),
            })
          );
        }
      });
    },
  });
