import React, { ComponentPropsWithRef, forwardRef, useState } from 'react';
import cx from 'clsx';
import ToolbarControl from 'features/toolbar/ToolbarControl';
import ToolbarSubmenu from 'features/toolbar/ToolbarSubmenu';
import { styled } from '@mui/material/styles';
import { TooltipProps as MuiTooltipProps } from '@mui/material';
import Popper from 'components/Popper';
import Tooltip, { TooltipPlacement } from 'components/Tooltip';
import { MenuProps as MenuPropsType } from 'components/Menu';
import { useAppSelector } from 'store/hooks';
import { selectToolbarOrientation } from 'features/toolbar/toolbarSlice';
import { IconProps } from 'components/Icon';

export type ToolbarItemSharedProps = {
  id: string;
  icon: string | React.ReactElement<IconProps>;
  ariaLabel?: string;
  overlay?: React.ReactNode;
  overlayType?: 'tooltip' | 'popper';
  disabled?: boolean;
  controlColor?: string;
};

export type ToolbarItemProps = Omit<ComponentPropsWithRef<'div'>, 'onClick'> &
  ToolbarItemSharedProps & {
    active?: boolean;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    badgeContent?: number;
    badgeActive?: boolean;
    menu?: boolean;
    error?: boolean;
    TooltipProps?: Omit<MuiTooltipProps, 'children' | 'title' | 'placement'>;
    MenuProps?: Omit<MenuPropsType, 'id' | 'trigger' | 'placement'>;
  };

const Root = styled('div')({
  flexShrink: 0,
  padding: 'var(--toolbar-item-padding) 0',
  '& > *': {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
});

const ToolbarMenuItem = forwardRef<HTMLDivElement, ToolbarItemProps>(
  (
    {
      children,
      badgeActive,
      icon,
      overlay,
      onClick,
      badgeContent,
      menu,
      id,
      error,
      overlayType = 'tooltip',
      ariaLabel,
      MenuProps,
      TooltipProps,
      active,
      disabled,
      controlColor,
      className,
      ...props
    },
    ref
  ) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toolbarOrientation = useAppSelector(selectToolbarOrientation);
    const overlayPlacement = toolbarOrientation === 'vertical' ? 'right' : 'top';
    const menuPlacement = toolbarOrientation === 'vertical' ? 'right-start' : 'top';

    const handleControlClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setTooltipOpen(false);

      if (onClick) {
        onClick(event);
      }
    };

    const handleTooltipOpen = () => {
      setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
      setTooltipOpen(false);
    };

    const trigger = (
      <ToolbarControl
        icon={icon}
        controlColor={controlColor}
        active={active}
        badgeActive={badgeActive}
        badgeContent={badgeContent}
        onClick={handleControlClick}
        error={error}
        disabled={disabled}
        aria-label={overlayType === 'tooltip' ? ariaLabel : undefined}
        orientation={toolbarOrientation}
      />
    );

    const renderOverlay = () => {
      if (!overlay) {
        return trigger;
      }

      if (overlayType === 'popper') {
        return (
          <Popper id={id} content={overlay} placement={overlayPlacement} triggerType="hover">
            {({ onMouseOut, ...rest }) => (
              <div
                {...rest}
                onMouseOut={!error ? onMouseOut : undefined}
                data-testid="toolbar-item-trigger-wrapper"
                aria-label={ariaLabel}
              >
                {trigger}
              </div>
            )}
          </Popper>
        );
      }

      return (
        <Tooltip
          title={<span className="Tooltip-title">{overlay}</span>}
          placement={overlayPlacement as TooltipPlacement}
          open={tooltipOpen}
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 24],
                },
              },
            ],
          }}
          {...TooltipProps}
        >
          <div>{trigger}</div>
        </Tooltip>
      );
    };

    return (
      <Root {...props} ref={ref} className={cx('ToolbarItem-root', className)}>
        {menu ? (
          <ToolbarSubmenu
            {...MenuProps}
            id={id}
            overlay={overlay}
            placement={menuPlacement}
            overlayPlacement={overlayPlacement}
            icon={icon}
            ariaLabel={ariaLabel}
            orientation={toolbarOrientation}
            disabled={disabled}
          >
            {children}
          </ToolbarSubmenu>
        ) : (
          <div>{renderOverlay()}</div>
        )}
      </Root>
    );
  }
);

export default ToolbarMenuItem;
