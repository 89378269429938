import { PayloadAction } from '@reduxjs/toolkit';
import { ChatMessage } from 'features/chat/types';
import { call, put, select } from 'redux-saga/effects';
import { messageSent } from 'features/chat/chatSlice';
import { decryptE2eeMessageSaga } from 'features/chat/sagas/decryptE2eeMessageSaga';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';

export function* onChatMessageSentSaga(action: PayloadAction<ChatMessage>) {
  let { payload } = action;

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    payload = yield call(decryptE2eeMessageSaga, action.payload);
  }

  if (payload) {
    yield put(messageSent(payload));
  }
}
