import { usePermissions } from 'features/permissions/usePermissions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';

export const useQA = () => {
  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('qa');

  const canAnswer = hasPermissions(PermissionTypes.answerQA);
  const canModerate = hasPermissions(PermissionTypes.moderateQA);

  const isFeatureAvailable = isFeatureEnabled;

  return {
    isFeatureAvailable,
    canAnswer,
    canModerate,
  };
};
