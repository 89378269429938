import cx from 'clsx';
import Menu from 'components/Menu';
import { RoomLayoutValuesContext } from 'features/layout/Context';
import { SourceDetails } from 'features/layout/types';
import { useFullScreenControl } from 'features/streaming/components/controls/useFullScreenControl';
import { useMinimizeLocalTileControl } from 'features/streaming/components/controls/useMinimizeLocalTileControl';
import { usePinControl } from 'features/streaming/components/controls/usePinControl';
import { useRemoveUserControl } from 'features/streaming/components/controls/useRemoveUserControl';
import { StreamControlButton } from 'features/streaming/components/StreamControlButton';
import { StreamControlMenuItem } from 'features/streaming/components/StreamControlMenuItem';
import React, { useContext, useEffect, useState } from 'react';

export const TileControls = ({
  source,
  collapse,
}: {
  source: SourceDetails;
  collapse: boolean;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { roomWidth, roomHeight } = useContext(RoomLayoutValuesContext);

  const closeMenu = () => setMenuOpen(false);

  const { show: showPin, ...pinControl } = usePinControl(source, closeMenu);
  const { show: showFullscreen, ...fullscreenControl } = useFullScreenControl(source, closeMenu);
  const { show: showMinimizeLocal, ...minimizeLocalTileControl } = useMinimizeLocalTileControl(
    source,
    closeMenu
  );

  const { show: showRemoveUser, ...removeUserControl } = useRemoveUserControl(source, closeMenu);

  const controls: React.ReactNode[] = [];
  const Control = collapse ? StreamControlMenuItem : StreamControlButton;

  if (showPin) {
    controls.push(<Control key="pin" {...pinControl} />);
  }

  if (showFullscreen) {
    controls.push(<Control key="fullscreen" {...fullscreenControl} />);
  }

  if (showMinimizeLocal) {
    controls.push(<Control key="minimize" {...minimizeLocalTileControl} />);
  }

  if (showRemoveUser) {
    controls.push(<Control key="remove" {...removeUserControl} />);
  }

  const haveControls = controls.length;

  // TODO: this will cause some issues in the future.
  // there's no good way to handle it now. let's add more confusing behaviors
  useEffect(() => {
    closeMenu();
  }, [roomWidth, roomHeight]);

  const renderCollapsedMenu = () =>
    haveControls ? (
      <Menu
        id={`tile-controls-${source.userId}`}
        open={menuOpen}
        onClose={closeMenu}
        placement="top-start"
        trigger={
          <StreamControlButton
            icon="settings1"
            onClick={() => setMenuOpen((open) => !open)}
            className={cx({ 'hover-child': !menuOpen })}
          />
        }
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.grey['900'],
          }),
        }}
        sx={(theme) => ({
          color: theme.palette.common.white,
        })}
      >
        {controls}
      </Menu>
    ) : null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return collapse ? renderCollapsedMenu() : <>{controls}</>;
};
