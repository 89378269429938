export const simulcastMaxBitrates = {
  high: 1500000,
  medium: 700000,
  low: 128000,
};

export const environment = {
  simulcastMaxBitrates,
  encodings: [
    { rid: 'h', active: false, maxBitrate: simulcastMaxBitrates.high },
    {
      rid: 'm',
      active: true,
      maxBitrate: simulcastMaxBitrates.medium,
      scaleResolutionDownBy: 2,
    },
    {
      rid: 'l',
      active: true,
      maxBitrate: simulcastMaxBitrates.low,
      scaleResolutionDownBy: 4,
    },
  ],
  publishersCount: 1000,
  videoConstraints: {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    advanced: [
      { frameRate: { min: 24 } },
      { width: { min: 1280 } },
      { height: { min: 720 } },
      { frameRate: { max: 24 } },
      { width: { max: 1280 } },
      { height: { max: 720 } },
      { aspectRatio: { exact: 1.77778 } },
    ],
  },
};
