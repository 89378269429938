import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import i18n from 'i18n';
import { selectAppReconnecting } from 'features/application/applicationSlice';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { notification } from 'features/notifications/toast/notification';
import { call, select } from 'redux-saga/effects';

export function* notifyCaptionStateSaga() {
  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  const isReconnecting: boolean = yield select(selectAppReconnecting);
  const captionsEnabled: boolean = yield select(selectFeatureFlag, 'captions');

  if (captionsEnabled && !isReconnecting && !isRecorderSession) {
    yield call(notification, i18n.t('notifications:speech_captioned'));
  }
}
