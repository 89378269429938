import { PayloadAction } from '@reduxjs/toolkit';
import { streamMinimized } from 'features/layout/actions';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectActiveSpeaker } from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { activeSpeakerReset } from 'features/streaming/streamingSlice';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { StreamingReceiver } from 'utils/webrtc/receiving/StreamingReceiver';
import { StreamingStopped } from '../types';

export function* onStreamingStopSaga(action: PayloadAction<StreamingStopped>) {
  const { mid, feedId, id: userId, type } = action.payload;

  yield call(RTCClient.removeBroadcastingDevice, userId, type);

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    yield call(RTCClient.receivingFeed.removeE2eePendingSubscription, {
      userId,
      feedId,
      mid,
    });
  }

  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);

  if (maximizedStream?.userId === userId && maximizedStream.feedId === feedId) {
    yield put(streamMinimized());
  }

  if (action.payload.type === 'video') {
    yield call(RTCClient.receivingFeed.removeStreamTrack, feedId, mid);

    return;
  }

  if (action.payload.type === 'audio') {
    if (RTCClient.receivingFeed.receiver instanceof StreamingReceiver) {
      RTCClient.receivingFeed.unloadFeedAudio(userId);
      RTCClient.receivingFeed.media.freeStreamBySubscribedMid(feedId, mid);
      RTCClient.receivingFeed.updateRedux();
    }

    const activeSpeaker: SourceDetails = yield select(selectActiveSpeaker);

    if (
      activeSpeaker?.userId === userId &&
      // prevents from resetting if active speaker stopped screenshare
      // if that screenshare had audio
      activeSpeaker.feedId === feedId
    ) {
      yield put(activeSpeakerReset);
    }
  }
}
