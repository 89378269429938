import {
  signalingUserUnassignedFromBreakoutRoom,
  splashScreenOpenRequested,
} from 'features/breakout-rooms/actions';
import { selectIsBreakoutRoom } from 'features/room/roomSlice';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { put, select } from 'redux-saga/effects';
import { roomMovementRequested } from '../breakoutRoomsSlice';

export function* onUserUnassignedFromBreakoutRoomSaga(
  action: ReturnType<typeof signalingUserUnassignedFromBreakoutRoom>
) {
  const localUserId: UserId = yield select(selectLocalUserId);
  const inBreakout: boolean = yield select(selectIsBreakoutRoom);

  if (inBreakout && localUserId === action.payload.userId) {
    yield put(splashScreenOpenRequested('leaving'));

    yield put(roomMovementRequested());
  }
}
