import { singleton } from 'utils/singleton';
import { canExposeInternals } from 'utils/development';
import Socket from 'services/signaling/SignalingSocket';

export * from 'services/signaling/eventMap';
export * from 'services/signaling/roomOnlyEvents';
export * from './types';

export const SignalingSocket = singleton<Socket>(() => new Socket());

if (canExposeInternals()) {
  window.Signaling = SignalingSocket;
}
