import { selectIsBreakoutRoom, selectRoomId } from 'features/room/roomSlice';
import { put, select } from 'redux-saga/effects';
import { signalingUserJoined } from 'features/users/actions';
import {
  roomUserJoined,
  selectBreakoutRoomsCreated,
  unassignedUserJoined,
} from 'features/breakout-rooms/breakoutRoomsSlice';

export function* onUserJoinedSaga(action: ReturnType<typeof signalingUserJoined>) {
  const roomsCreated: boolean = yield select(selectBreakoutRoomsCreated);
  const inBreakout: boolean = yield select(selectIsBreakoutRoom);

  if (!roomsCreated) {
    return;
  }

  const { breakoutRoomId } = action.payload;

  if (inBreakout) {
    const roomId: string = yield select(selectRoomId);

    yield put(
      roomUserJoined({
        roomId,
        user: action.payload,
      })
    );

    return;
  }

  // TODO
  if (breakoutRoomId) {
    yield put(
      roomUserJoined({
        roomId: breakoutRoomId,
        user: action.payload,
      })
    );
  } else {
    yield put(unassignedUserJoined(action.payload));
  }
}
