import { Button, IconButton } from '@mui/material';
import { alpha, styled, getContrastRatio } from '@mui/material/styles';
import {
  layoutModeChanged,
  selectCurrentLayoutMode,
} from 'features/layout/features/config/configSlice';
import { LayoutMode } from 'features/layout/types';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

const Control = styled(Button)(({ theme }) => {
  const bgColor =
    getContrastRatio(theme.palette.common.white, theme.room.background) >
    theme.palette.contrastThreshold
      ? alpha(theme.palette.common.white, 0.2)
      : alpha(theme.palette.action.active, theme.palette.action.hoverOpacity);

  return {
    color: theme.palette.getContrastText(theme.room.background),
    whiteSpace: 'nowrap',
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: bgColor,
    },
    '&:focus': {
      backgroundColor: bgColor,
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.grey['600'],
    },
  };
});

const LayoutModeControl = () => {
  const dispatch = useAppDispatch();
  const currentMode = useAppSelector(selectCurrentLayoutMode);
  const { t } = useTranslation('room');

  const handleToggle = () => {
    if (currentMode === LayoutMode.auto) {
      dispatch(layoutModeChanged(LayoutMode.tiled));
    } else {
      dispatch(layoutModeChanged(LayoutMode.auto));
    }
  };

  const renderTooltipText = () =>
    currentMode === LayoutMode.auto
      ? t('tooltips.switch_to_custom_mode')
      : t('tooltips.switch_to_auto_mode');

  const { isMobile } = useBreakpoints();

  const renderIcon = () => (
    <Icon name={currentMode === LayoutMode.auto ? 'active-speaker-mode' : 'tiled-mode'} />
  );

  const renderButtonText = () => {
    if (isMobile) {
      return renderIcon();
    }

    return currentMode === LayoutMode.auto ? t('topbar.auto_mode') : t('topbar.custom_mode');
  };

  const renderControl = () =>
    isMobile ? (
      <Control
        data-testid="layout-mode-control"
        as={IconButton}
        onClick={handleToggle}
        sx={{ fontSize: 24 }}
      >
        {renderIcon()}
      </Control>
    ) : (
      <Control
        data-testid="layout-mode-control"
        onClick={handleToggle}
        startIcon={renderIcon()}
        sx={{
          py: 1,
          px: 4,
          borderRadius: '20px',
          fontSize: 14,
        }}
      >
        {renderButtonText()}
      </Control>
    );

  return (
    <Tooltip title={renderTooltipText()} placement="bottom">
      {renderControl()}
    </Tooltip>
  );
};

export default LayoutModeControl;
