import { AvatarSize, LayoutDimensionsState } from 'features/layout/features/dimensions/types';
import { layoutConfig } from 'utils/layout';

const avatarSizes: [number, number, AvatarSize][] = [
  [Number.MIN_SAFE_INTEGER, 90, 'xxs'],
  [91, 150, 'xs'],
  [151, 220, 'sm'],
  [221, 450, 'md'],
  [451, 650, 'lg'],
  [651, Number.MAX_SAFE_INTEGER, 'xl'],
];

export const matchAvatarSize = (tileHeight: number) => {
  const height = Math.floor(tileHeight);
  const match = avatarSizes.find(([from, to]) => from <= height && to >= height);

  return match?.[2] || 'xxs';
};

export const fitToRenderArea: (
  width: number,
  height: number,
  tiles: number,
  deviceType: string
) => LayoutDimensionsState = (width, height, tiles, deviceType) => {
  const { minFeasibleWidth, minFeasibleHeight, minFeasibleRatio } = layoutConfig;

  let maxRows = 7;
  const maxCols = 7;

  const mobileMaxNumberOfTiles = width * 1.2 > height ? 6 : 4;

  // how many tiles our device supports
  const deviceMaxNumberOfTiles =
    deviceType === 'mobile' ? mobileMaxNumberOfTiles : maxRows * maxCols;

  // how many minimum area tiles we can potentially fit on screen
  const maxRenderAreaTiles = Math.floor((width * height) / (minFeasibleWidth * minFeasibleHeight));

  // establis upper limit of tiles that need to be fitted, work against it
  let approxMaxTiles = Math.min(deviceMaxNumberOfTiles, tiles, maxRenderAreaTiles);

  let cols = 1;
  let rows = 1;
  let fitted = 1;

  while (fitted < approxMaxTiles) {
    const newRows = rows + 1;
    const newCols = cols + 1;

    // allow for more rows if it makes sense in more vertical layouts;
    if (cols === 7) {
      maxRows = 7;
    } else {
      maxRows = deviceMaxNumberOfTiles;
    }

    const growHorizontallyWidth = width / newCols;
    const canGrowHorizontally = minFeasibleWidth <= growHorizontallyWidth && cols < maxCols;

    const growVerticallyHeight = height / newRows;
    const growVerticallyRatio = growVerticallyHeight / (width / cols);
    const canGrowVertically =
      minFeasibleHeight <= growVerticallyHeight &&
      growVerticallyRatio >= minFeasibleRatio &&
      rows < maxRows;

    if (!(canGrowHorizontally || canGrowVertically)) {
      approxMaxTiles = cols * rows;
      fitted = approxMaxTiles;
    } else {
      if (canGrowHorizontally && !canGrowVertically) {
        cols = newCols;
      } else if (!canGrowHorizontally && canGrowVertically) {
        rows = newRows;
      } else {
        const growHorizontallyHeight = growHorizontallyWidth * (9 / 16);
        const growHorizontallyArea = growHorizontallyWidth * growHorizontallyHeight * 1.2; // favor cols

        const growVerticallyWidth = growVerticallyHeight * (16 / 9);
        const growVerticallyArea = growVerticallyWidth * growVerticallyHeight;

        if (growVerticallyArea > growHorizontallyArea) {
          rows = newRows;
        } else {
          cols = newCols;
        }
      }

      fitted = cols * rows;
    }
  }

  approxMaxTiles ||= 1;

  rows = Math.ceil(approxMaxTiles / cols) || 1;

  const tileHeight = height / rows || 0;

  const tileWidth = width / cols;

  const maxHeight = tileWidth * 0.95;

  const maxWidth = Math.min(tileHeight, maxHeight) * 2.25;

  const correctionHeight = tileHeight > maxHeight ? ((tileHeight - maxHeight) * rows) / 2 : 0;
  const correctionWidth =
    tileWidth > maxWidth ? Math.min(((tileWidth - maxWidth) * cols) / 2, width * 0.25) : 0;

  const avatarSize = matchAvatarSize(Math.min(tileHeight, maxHeight));

  return {
    tileHeight,
    tileWidth,
    avatarSize,
    cols,
    rows,
    maxTiles: approxMaxTiles,
    correctionHeight,
    correctionWidth,
    renderArea: { width, height },
  };
};
