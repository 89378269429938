import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { pinControlToggled } from 'features/layout/actions';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectExposedMode } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { selectSingleUserCanSeeAdditionalControls } from 'features/layout/selectors';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const usePinControl = (
  source: SourceDetails,
  clickCallback: (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => void
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('room');

  const enabled = useFeatureFlag('pin');
  const layoutMode = useAppSelector(selectCurrentLayoutMode);
  const hideSingleUserControls = useAppSelector(selectSingleUserCanSeeAdditionalControls);

  const maximizedFeed = useAppSelector(selectMaximizedStream);
  const exposedMode = useAppSelector(selectExposedMode);

  const controlActive = sourceMatches(maximizedFeed, source) && exposedMode === 'pin';

  const getText = () => {
    if (!source) {
      return '';
    }

    const isScreenshare =
      source.kind === 'screenshare-local' || source.kind === 'screenshare-remote';

    if (controlActive) {
      return isScreenshare ? t('tooltips.unpin_screenshare') : t('tooltips.unpin');
    }

    return isScreenshare ? t('tooltips.pin_screenshare') : t('tooltips.pin');
  };

  // eslint-disable-next-line no-nested-ternary
  const onClick = (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    clickCallback?.(e);
    dispatch(pinControlToggled({ controlActive, source }));
  };

  const showControl = () => {
    if (!enabled) {
      return false;
    }

    if (layoutMode === LayoutMode.auto) {
      return false;
    }

    return !hideSingleUserControls;
  };

  return {
    show: showControl(),
    icon: controlActive ? 'unpin' : 'pin',
    onClick,
    text: getText(),
  };
};
