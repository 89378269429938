import React, { Suspense } from 'react';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'features/modal/modalSlice';
import SettingsModal from 'features/join/publisher/settings/SettingsModal';
import { useMediaDevices } from 'hooks/useMediaDevices';
import Header from 'features/join/publisher/components/Header';

import BlockedCameraPopper from 'features/join/publisher/components/poppers/BlockedCameraPopper';
import UnavailableCameraPopper from 'features/join/publisher/components/poppers/UnavailableCameraPopper';
import Icon from 'components/Icon';
import BlockedMicPopper from 'features/join/publisher/components/poppers/BlockedMicPopper';
import UnavailableMicPopper from 'features/join/publisher/components/poppers/UnavailableMicPopper';
import FormFooter from 'components/FormFooter';
import VUMeter from 'components/VUMeter';
import { useTranslation } from 'react-i18next';
import { JoinScreenInner, JoinScreenInnerWide } from 'features/join/styles';
import { Button, Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Popper from 'components/Popper';
import VideoRenderer from 'components/VideoRenderer';
import { useBroadcastSetup } from 'utils/broadcast-setup';
import { TooltipIconTrigger } from 'components/TooltipIconTrigger';
import { useBroadcastSetupPreview } from 'utils/broadcast-setup/useBroadcastSetupPreview';
import { selectAwaitingRoomJoin } from 'features/room/roomSlice';
import AwaitingRoomJoin from 'features/join/shared/AwaitingRoomJoin';
import SettingControl from 'components/SettingControl';
import { Switch } from 'components/Switch';

const PreviewSettingsIcon = styled(Icon)({
  marginRight: '2px',
  marginLeft: '-4px',
  fontSize: '26px',
});

export type MediaDeviceChangeHandler = (value: string) => void;

export type PreviewProps = {
  joinRoom: SubmitHandler<PreviewFormValues>;
};

export type PreviewFormValues = {
  videoinput: string;
  audiooutput: string;
  audioinput: string;
};

const JoinPreview = ({ joinRoom }: PreviewProps) => {
  const { t } = useTranslation(['join', 'common']);
  const appDispatch = useAppDispatch();

  const { micPermissions, camPermissions } = useMediaDevices();

  const { audio, video, onAudioToggle, onVideoToggle, videoStream, audioStream } =
    useBroadcastSetup();

  const formMethods = useBroadcastSetupPreview();
  const { handleSubmit } = formMethods;

  const awaitingRoomJoin = useAppSelector(selectAwaitingRoomJoin);

  const openSettings = () => {
    appDispatch(openModal('publisherSettings'));
  };

  const renderCameraPopover = () =>
    camPermissions === 'denied' ? <BlockedCameraPopper /> : <UnavailableCameraPopper />;

  const renderMicPopover = () =>
    micPermissions === 'denied' ? <BlockedMicPopper /> : <UnavailableMicPopper />;

  const renderJoinButton = () => {
    if (awaitingRoomJoin) {
      return <AwaitingRoomJoin />;
    }

    return (
      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={audio.processing || video.processing}
      >
        {t('publisher.btn_label_join')}
      </Button>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <JoinScreenInnerWide>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Header />
        </Box>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} lg={7}>
            <Box
              sx={{
                mb: { xs: 8, lg: 0 },
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 1,
              }}
            >
              <VideoRenderer mediaStream={videoStream} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={5} flexShrink={0}>
            <JoinScreenInner sx={{ ml: { lg: 10 } }}>
              <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Header />
              </Box>
              <form onSubmit={handleSubmit(joinRoom)}>
                <Box
                  sx={{
                    mb: 8,
                  }}
                >
                  <SettingControl
                    label={t('publisher.camera_control.label')}
                    control={
                      <Switch
                        onChange={(e) => onVideoToggle(e.target.checked)}
                        checked={video.active}
                        inputProps={{
                          'aria-label': video.active
                            ? t('publisher.camera_control.enabled_aria_label')
                            : t('publisher.camera_control.disabled_aria_label'),
                        }}
                        disabled={video.disabled}
                      />
                    }
                    infoIcon={
                      camPermissions !== 'granted' ? (
                        <Popper
                          id={camPermissions === 'denied' ? 'blockedCamera' : 'unavailableCamera'}
                          placement="right"
                          content={renderCameraPopover()}
                        >
                          <TooltipIconTrigger>
                            <Icon name="warning" />
                          </TooltipIconTrigger>
                        </Popper>
                      ) : undefined
                    }
                  />
                </Box>
                <div>
                  <SettingControl
                    label={t('publisher.mic_control.label')}
                    control={
                      <Switch
                        onChange={(e) => onAudioToggle(e.target.checked)}
                        checked={audio.active}
                        inputProps={{
                          'aria-label': audio.active
                            ? t('publisher.mic_control.enabled_aria_label')
                            : t('publisher.mic_control.disabled_aria_label'),
                        }}
                        disabled={audio.disabled}
                      />
                    }
                    infoIcon={
                      micPermissions !== 'granted' ? (
                        <Popper
                          id={camPermissions === 'denied' ? 'blockedMic' : 'unavailableMic'}
                          placement="right"
                          content={renderMicPopover()}
                        >
                          <TooltipIconTrigger>
                            <Icon name="warning" />
                          </TooltipIconTrigger>
                        </Popper>
                      ) : undefined
                    }
                  />
                </div>
                <Box mt={6}>
                  <VUMeter
                    on={audio.active}
                    stream={audioStream}
                    label={t('common:vumeter.vumeter_label')}
                  />
                </Box>
                <Button variant="text" sx={{ mt: 4, pl: 0 }} onClick={openSettings}>
                  <Box display="flex" alignItems="center">
                    <PreviewSettingsIcon name="settings" />
                    <Typography component="span">{t('publisher.btn_settings_label')}</Typography>
                  </Box>
                </Button>
                <FormFooter>{renderJoinButton()}</FormFooter>
              </form>
            </JoinScreenInner>
          </Grid>
        </Grid>
      </JoinScreenInnerWide>
      <Suspense fallback={null}>
        <SettingsModal />
      </Suspense>
    </FormProvider>
  );
};

export default JoinPreview;
