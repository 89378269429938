import { AppThunk } from 'store/store';
import { actionNotificationClosed } from 'features/notifications/notificationsSlice';
import { notification } from 'features/notifications/toast/notification';
import { NotificationId } from 'features/notifications/types';

export const closeActionNotification =
  (id: NotificationId): AppThunk<void> =>
  (dispatch) => {
    dispatch(actionNotificationClosed(id));

    notification.dismiss(id);
  };
