import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { CaptionsSpokenLanguage } from 'features/application/types';
import { CaptionsChunk, CaptionsReceivedPayload, CaptionsState } from 'features/captions/types';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { SDKConnected } from 'features/sdk/sdkStateSlice';
import { UserId } from 'features/users/types';
import { RootState } from 'store/store';

export const initialState: CaptionsState = {
  textReceived: false,
  displayCaptions: false,
  mode: 'end_of_speech',
  chunkOrder: [],
  chunks: {},
  fontSize: 24,
  resetTimers: {},
  spokenLanguage: 'en',
};

const emptyChunk = {
  chunkFinalized: false,
  speechFinalized: false,
  text: '',
  buffer: '',
  tempText: '',
};

export const captionsSlice = createSlice({
  name: 'captions',
  initialState,
  reducers: {
    captionsToggled(state, action: PayloadAction<boolean | undefined>) {
      if (action.payload === undefined) {
        state.displayCaptions = !state.displayCaptions;
      } else {
        state.displayCaptions = action.payload;
      }

      if (state.displayCaptions) {
        // reset to initial state;
        // see if this doesnt conflict with first captionsReceived??;
        // @TODO: see if we need this 'reset' logic
        // state.text = initialState.text;
      } else {
        state.textReceived = false;
      }
    },
    captionsReceived(state, action: PayloadAction<CaptionsReceivedPayload>) {
      const { chunk, timer } = action.payload;

      const userId = chunk.participant.id;
      const lastDisplayedSpeakerId = state.chunkOrder[state.chunkOrder.length - 1];
      const speakerAlreadyDisplayed = state.chunkOrder.includes(userId);
      const speakerInterrupts = lastDisplayedSpeakerId !== userId;

      state.textReceived = true;
      state.resetTimers[userId] = timer;

      // update prompt;
      if (!state.chunks[userId]) {
        state.chunks[userId] = {
          ...emptyChunk,
          participant: chunk.participant,
        };
      }

      let updateOrder = true;

      if (speakerInterrupts) {
        // new prompt from interrupting user, reset text
        state.chunks[userId].text = '';
        state.chunks[userId].tempText = '';
        state.chunks[userId].buffer = '';
      }

      // temp (?) hack to work better with speechmatics output;
      chunk.transcript = chunk.transcript.replace(/^(,|\.|\?|!|\s)/, '');

      // replicate live speech behavior with continuing messages;
      if (state.mode === 'end_of_speech') {
        state.chunks[userId].text += ` ${chunk.transcript}`;

        if (state.chunks[userId].text.length > 2000) {
          state.chunks[userId].text = state.chunks[userId].text.slice(-2000);
        }
      } else if (chunk.chunkFinalized) {
        if (speakerInterrupts) {
          updateOrder = !speakerAlreadyDisplayed;
        }

        state.chunks[userId].buffer += ` ${chunk.transcript}`;

        if (state.chunks[userId].buffer.length > 2000) {
          state.chunks[userId].buffer = state.chunks[userId].buffer.slice(-2000);
        }

        state.chunks[userId].text = state.chunks[userId].buffer;

        state.chunks[userId].tempText = '';
      } else {
        state.chunks[userId].tempText = chunk.transcript;
      }

      //  update order
      if (updateOrder && (speakerInterrupts || !speakerAlreadyDisplayed)) {
        state.chunkOrder = lastDisplayedSpeakerId ? [lastDisplayedSpeakerId, userId] : [userId];
      }
    },
    fontSizeChanged(state, action: PayloadAction<number>) {
      state.fontSize = action.payload;
    },
    captionsReset(state, action: PayloadAction<UserId>) {
      state.chunkOrder = state.chunkOrder.filter((id) => id !== action.payload);

      delete state.chunks[action.payload];
    },
    spokenLanguageChanged(state, action: PayloadAction<CaptionsSpokenLanguage>) {
      state.spokenLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorizeUser.fulfilled, (state, action) => {
        state.mode = action.payload.settings.captionsMode;
        state.spokenLanguage = action.payload.settings.captionsLanguage;
      })
      .addCase(SDKConnected, (state, action) => {
        const options = action.payload;

        if (options.showCaptions !== undefined) {
          state.displayCaptions = options.showCaptions;
        }
      });
  },
});

export const {
  captionsReceived,
  captionsToggled,
  fontSizeChanged,
  captionsReset,
  spokenLanguageChanged,
} = captionsSlice.actions;

export const selectCaptionsDisplayed = (state: RootState) => state.captions.displayCaptions;
export const selectCaptionTextReceived = (state: RootState) => state.captions.textReceived;

export const selectCaptionsResetTimer = (state: RootState, userId: UserId) =>
  state.captions.resetTimers[userId];

export const selectCaptionsText = createSelector(
  (state: RootState) => state.captions,
  (state) => [state.chunkOrder, state.chunks] as [string[], Record<UserId, CaptionsChunk>]
);

export const selectCaptionsFontSize = (state: RootState) => state.captions.fontSize;
export const selectCaptionsSpokenLanguage = (state: RootState) => state.captions.spokenLanguage;
export default captionsSlice.reducer;
