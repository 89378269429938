import { call, fork, put, select } from 'redux-saga/effects';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { User, UserLeftPayload } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { eventBus } from 'utils/eventBus';
import { cleanupUser } from 'features/users/thunks/cleanupUser';
import { RTCClient } from 'utils/webrtc';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { cleanupE2eeUserSaga } from 'features/e2ee/sagas/cleanupE2eeUserSaga';
import { refreshEncryptionKeys } from 'features/e2ee/sagas/refreshEncryptionKeys';
import { board } from 'utils/whiteboard/BoardStateManager';
import { raiseHandNotificationUpdateRequested } from 'features/notifications/actions';
import { handleLeaveNotificationSaga } from 'features/notifications/sagas/handleLeaveNotificationSaga';

export function* onUserLeftSaga(action: PayloadAction<UserLeftPayload>) {
  const { id: userId } = action.payload;

  const user: User = yield select(selectUserById, userId);
  if (!user) {
    // prevent possible race-condition from the backend
    return;
  }

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (!isRecorderSession) {
    yield call(handleLeaveNotificationSaga, user);

    yield call(eventBus.sendMessage, 'userLeft', { userId }, { reason: 'generic' });
  }

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  // cleanup E2EE stuff
  if (e2eeEnabled) {
    yield call(cleanupE2eeUserSaga, user.id, user.e2eeId!);
  }

  yield call(RTCClient.receivingFeed.unloadFeedAudio, userId);

  const cleanupUserThunk: Action = yield call(cleanupUser, userId);
  yield put(cleanupUserThunk);

  yield call(board.removeRemotePointer, userId);

  if (!isRecorderSession) {
    // we have to wait until the user is completely removed from the store
    if (e2eeEnabled) {
      yield fork(refreshEncryptionKeys);
    }

    // update raise hand notification
    yield put(raiseHandNotificationUpdateRequested({ reason: 'user-left' }));
  }
}
