import { createAction } from '@reduxjs/toolkit';
import { Answer, DeleteAnswerPayload, LiveAnswerActionPayload, Question } from 'features/qa/types';

export const askQuestionRequested = createAction<string>('qa/askQuestionRequested');

export const signalingQuestionAsked = createAction<Question>('signaling/questionAsked');

export const answerQuestionRequested = createAction<{
  answer: string;
  questionId: string;
}>('qa/answerQuestionRequested');

export const signalingQuestionAnswered = createAction<Answer>('signaling/questionAnswered');

export const deleteQuestionRequested = createAction<string>('qa/deleteQuestionRequested');

export const signalingQuestionDeleted = createAction<{ id: string }>('signaling/questionDeleted');

export const deleteAnswerRequested = createAction<DeleteAnswerPayload>('qa/deleteAnswerRequested');

export const signalingAnswerDeleted = createAction<DeleteAnswerPayload>('signaling/answerDeleted');

// live answer
export const startLiveAnswerRequested = createAction<string>('qa/startLiveAnswerRequested');
export const signalingQuestionLiveAnswerStarted = createAction<Answer>(
  'signaling/questionLiveAnswerStarted'
);
export const stopLiveAnswerRequested = createAction<LiveAnswerActionPayload>(
  'qa/stopLiveAnswerRequested'
);
export const signalingQuestionLiveAnswerStopped = createAction<Answer>(
  'signaling/questionLiveAnswerStopped'
);

export const cancelLiveAnswerRequested = createAction<LiveAnswerActionPayload>(
  'qa/cancelLiveAnswerRequested'
);

export const signalingQuestionLiveAnswerCancelled = createAction<Answer>(
  'signaling/questionLiveAnswerCancelled'
);

export const questionAnswerTypeResetRequested = createAction('qa/answerTypeResetRequested');
