import { ThemeOptions } from '@mui/material';

export const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const themeBaseOptions: ThemeOptions = {
  breakpoints: {
    values: breakpointValues,
  },
  typography: {
    fontFamily: [
      'system-ui',
      '-apple-system',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      '"Liberation Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(','),
    h1: {
      fontWeight: 400,
      fontSize: '2.5rem',
      letterSpacing: 'normal',
    },
    h2: {
      fontWeight: 400,
      fontSize: '2rem',
      letterSpacing: 'normal',
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.5rem',
      letterSpacing: 'normal',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.5rem',
      letterSpacing: 'normal',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.25rem',
      letterSpacing: 'normal',
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.25,
      letterSpacing: 'normal',
    },
    subtitle1: {
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: 'normal',
    },
    subtitle2: {
      letterSpacing: 'normal',
    },
    body1: {
      lineHeight: 1.4,
      letterSpacing: 'normal',
    },
    body2: {
      letterSpacing: 'normal',
    },
    button: {
      letterSpacing: 'normal',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.44,
    },
    caption: {
      letterSpacing: 'normal',
    },
    overline: {
      letterSpacing: 'normal',
    },
  },
  spacing: 4,
};
