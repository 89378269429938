import { createSelector } from '@reduxjs/toolkit';
import {
  selectCurrentLayoutMode,
  selectHideLayoutControls,
  selectLocalStreamMinimized,
} from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectExposedMode } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';
import { LayoutMode } from 'features/layout/types';
import { selectLocalBroadcastPermissions } from 'features/permissions/permissionsSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';

export const selectHideLocalStream = createSelector(
  [
    selectLocalBroadcastPermissions,
    selectIsRecorderSession,
    selectMaximizedStream,
    selectLocalStreamMinimized,
    selectExposedMode,
  ],
  (allowBroadcast, isRecorder, maximizedStream, minimizeLocal, exposedMode) => {
    if (!allowBroadcast || isRecorder) {
      return true;
    }

    const removeFromGrid = maximizedStream?.kind === 'local' && exposedMode === 'pin';

    return removeFromGrid || minimizeLocal;
  }
);

export const selectFilteredStreams = createSelector(
  [
    selectCurrentLayoutMode,
    selectOrderedStreams,
    selectMaximizedStream,
    selectExposedMode,
    selectLocalStreamMinimized,
  ],
  (layoutMode, orderedStreams, maximizedStream, exposedMode, localStreamMinimized) => {
    if (layoutMode === LayoutMode.tiled && exposedMode === 'maximize' && !localStreamMinimized) {
      return [];
    }

    return orderedStreams.filter(
      (stream) =>
        !(stream.userId === maximizedStream?.userId && stream.feedId === maximizedStream?.feedId)
    );
  }
);

// FIXME: currently you have return true to hide controls, return false to show them.
export const selectSingleUserCanSeeAdditionalControls = createSelector(
  [selectFilteredStreams, selectOrderedStreams, selectHideLocalStream, selectMaximizedStream],
  (filteredStreams, orderedStreams, hideLocalStream, maximizedStream) => {
    if (orderedStreams.length === 0) {
      return true;
    }

    if (maximizedStream) {
      if (filteredStreams.length === 0) {
        // if no users and only screenshare is visible, hide all controls;
        if (
          maximizedStream.kind === 'screenshare-local' ||
          maximizedStream.kind === 'screenshare-remote'
        ) {
          return true;
        }

        return hideLocalStream ? orderedStreams.length < 1 : !orderedStreams.length;
      }

      return false;
    }

    return filteredStreams.length <= 1 && hideLocalStream;
  }
);

export const selectAdditionalControlsDisabled = createSelector(
  [selectHideLayoutControls, selectSingleUserCanSeeAdditionalControls],
  (hideLayoutControls, userCanSeeControls) =>
    hideLayoutControls === undefined ? userCanSeeControls : hideLayoutControls
);

export const selectTileControlsVisibility = createSelector(
  [
    selectCurrentLayoutMode,
    selectSingleUserCanSeeAdditionalControls,
    selectOrderedStreams,
    selectMaximizedStream,
  ],
  (layoutMode, hideControls, orderedStreams, maximizedStream) => {
    if (layoutMode === LayoutMode.auto) {
      return {
        pin: false,
        fullscreen: false,
        minimizeLocal: maximizedStream && orderedStreams.length > 0,
      };
    }

    return {
      pin: !hideControls,
      fullscreen: !hideControls,
      minimizeLocal: !hideControls,
    };
  }
);
