import BaseLoadingIndicator from 'features/room/components/BaseLoadingIndicator';
import RoomLoginForm from 'features/room/components/RoomLoginForm';
import {
  selectIsSDKConnected,
  selectIsSDKEmbed,
  selectSDKInitState,
} from 'features/sdk/sdkStateSlice';
import { useGDPRConfig } from 'hooks/useGDPRConfig';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { setConsentFlag } from 'utils/consentFlag';
import { eventBus } from 'utils/eventBus';
import * as Sentry from '@sentry/react';
import { handleAsyncFormValidation } from 'utils/handleAsyncFormValidation';
import { APIErrorMessage, getAPIDomain, lookupApiErrorMessage } from 'utils/http';
import { logger } from 'utils/logger';
import { isAPIError } from 'utils/types';
import {
  loginFailed,
  loginFinished,
  loginStarted,
  selectRoomEntryProgress,
} from 'features/room/roomSlice';

export interface RoomLoginFormValues {
  login: string;
  consentGiven?: boolean;
}

const RoomLogin = () => {
  const { t } = useTranslation(['join', 'common']);
  const dispatch = useAppDispatch();
  const { consentRequired, tokenUserName } = useGDPRConfig();

  const [loginInBackground, setLoginInBackground] = useState(eventBus.isEmbed || !consentRequired);
  const [requireLoginInput, setRequireLoginInput] = useState(false);

  const entryProgress = useAppSelector(selectRoomEntryProgress);

  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm<RoomLoginFormValues>({
    defaultValues: {
      login: eventBus.initState.username || '',
    },
  });

  const sdkEmbed = useAppSelector(selectIsSDKEmbed);
  const sdkConnected = useAppSelector(selectIsSDKConnected);
  const sdkInitState = useAppSelector(selectSDKInitState);

  const showNameField = !(sdkInitState.username || tokenUserName) || requireLoginInput;

  const submitLogin: SubmitHandler<RoomLoginFormValues> = useCallback(
    async (data) => {
      dispatch(loginStarted());

      if (data.consentGiven) {
        logger.remote({ tier: 1 }).log('User accepted GDPR terms for team: ', getAPIDomain());

        setConsentFlag();
      }

      if (showNameField) {
        const resultAction = await dispatch(authorizeUser(data.login));

        if (authorizeUser.rejected.match(resultAction)) {
          dispatch(loginFailed());

          setRequireLoginInput(true);
          setLoginInBackground(false);

          if (resultAction.payload) {
            handleAsyncFormValidation(resultAction.payload, setError);
          } else {
            const { error } = resultAction;
            Sentry.captureException(error);
            let message: APIErrorMessage | undefined;

            if (isAPIError(error)) {
              message = lookupApiErrorMessage(error);
            }

            setError('root.serverError', {
              type: 'server',
              message: message?.description || t('common:generic_error'),
            });
          }
        }
      } else {
        dispatch(loginFinished());
      }
    },
    [dispatch, setError, t, showNameField]
  );

  useEffect(() => {
    if (sdkEmbed && !consentRequired) {
      if (sdkConnected) {
        if (sdkInitState.username) {
          submitLogin({ login: sdkInitState.username });
        } else {
          setLoginInBackground(false);
        }
      }
    } else {
      setLoginInBackground(false);
    }
  }, [submitLogin, sdkEmbed, sdkConnected, sdkInitState.username, consentRequired]);

  return loginInBackground ? (
    <BaseLoadingIndicator />
  ) : (
    <RoomLoginForm
      onSubmit={handleSubmit(submitLogin)}
      showNameField={showNameField}
      errors={errors}
      control={control}
      isSubmitting={entryProgress === 'authorizing'}
    />
  );
};

export default RoomLogin;
