import { selectBreakoutRoomsStatus } from 'features/breakout-rooms/breakoutRoomsSlice';
import { BreakoutRoomsStatus } from 'features/breakout-rooms/types';
import { notifyCaptionStateSaga } from 'features/captions/sagas/notifyCaptionStateSaga';
import { configureSDKStoredStateSaga } from 'features/sdk/configureSDKStoredStateSaga';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { eventBus } from 'utils/eventBus';
import { PublishingOptions, RTCClient } from 'utils/webrtc';
import { SignalingRoomJoinedPayload, SignalingSocket } from 'services/signaling';
import { signalingRoomJoined } from 'features/room/actions';
import { roomError, roomJoined, roomJoinStarted } from 'features/room/roomSlice';
import * as Sentry from '@sentry/react';
import { establishJanusConnectionSaga } from 'features/room/sagas/establishJanusConnectionSaga';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { JoinRoomData } from 'features/room/types';
import { sframeInitialized } from 'features/e2ee/actions';

export function* joinRoomSaga() {
  try {
    yield put(roomJoinStarted());

    const e2eeEnabled: boolean = yield select(selectE2eeEnabled);

    const joinPayload: JoinRoomData = {};

    if (e2eeEnabled) {
      joinPayload.e2eePublicKey = yield call(E2EEManager.exportPublicKey);
    }

    const breakoutRoomsStatus: BreakoutRoomsStatus = yield select(selectBreakoutRoomsStatus);

    if (breakoutRoomsStatus === 'idle') {
      yield call(SignalingSocket.send, { event: 'joinRoom', data: joinPayload });
    }

    const action: PayloadAction<SignalingRoomJoinedPayload> = yield take(signalingRoomJoined);
    RTCClient.supressErrors = false;

    const { payload: initData } = action;

    yield call(eventBus.sendMessage, 'userJoined', { local: true });

    if (e2eeEnabled) {
      yield take(sframeInitialized);
    }

    const publishingOptions: PublishingOptions = yield call(
      RTCClient.publishingFeed.getJoinPublishingOptions
    );

    RTCClient.publishingFeed.broadcasterLimit = initData.broadcastersLimit;

    yield call(establishJanusConnectionSaga, initData, publishingOptions);

    yield fork(notifyCaptionStateSaga);

    yield put(roomJoined());

    yield call(configureSDKStoredStateSaga, initData.users);
  } catch (error) {
    yield call(Sentry.captureException, error);
    yield put(roomError({ global: true }));
  }
}
