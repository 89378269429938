import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  signalingAllChatMessagesReceived,
  signalingChatMessageDeleted,
} from 'features/chat/actions';
import { PRIVATE_GROUP_CHAT_AVATAR_COLOR, PRIVATE_GROUP_CHAT_ID } from 'features/chat/constants';
import { processMessage } from 'features/chat/utils/processMessage';
import { setupUnreadCounters } from 'features/chat/utils/setupUnreadCounters';
import { senderKeyRatchetFinished } from 'features/e2ee/e2eeSlice';
import { signalingRoomJoined } from 'features/room/actions';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { toolbarPanelClosed, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { UserId } from 'features/users/types';
import { selectUsers } from 'features/users/usersSlice';
import { RootState } from 'store/store';
import { resetMessages } from 'features/chat/utils/resetMessages';
import { findChatTargetUser } from 'features/chat/utils/findChatTargetUser';
import { getPrivateChatSenderId } from 'features/chat/utils/getPrivateChatSenderId';
import { deleteMessage } from 'features/chat/utils/deleteMessage';
import {
  ChatMessage,
  ChatMessageId,
  ChatState,
  ChatTab,
  NewMessagePayload,
  PrivateChatOpenedPayload,
  PrivateChatView,
  UnreadMessageCounterUpdatedPayload,
} from './types';

export const initialState: ChatState = {
  panelOpen: false,
  activeTab: ChatTab.public,
  localUserId: '',
  entities: {},
  publicIds: [],
  messageSentWithCurrentKey: false,
  senders: {},
  unreadCounters: {
    public: 0,
  },
  readMessages: {},
  privateChat: {
    chats: {},
    activeView: {
      name: 'list',
      data: {},
    },
    groupChat: {
      id: PRIVATE_GROUP_CHAT_ID,
      name: '',
      roles: [],
      avatarColor: PRIVATE_GROUP_CHAT_AVATAR_COLOR,
    },
  },
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    activeTabChanged(state, action: PayloadAction<ChatTab>) {
      state.activeTab = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendChatMessage(state, action: PayloadAction<NewMessagePayload>) {
      state.messageSentWithCurrentKey = true;
    },
    messageSent(state, action: PayloadAction<ChatMessage>) {
      processMessage(state, action.payload);
    },
    privateChatOpened(state, action: PayloadAction<PrivateChatOpenedPayload>) {
      const { privateChat } = state;

      state.activeTab = ChatTab.private;

      if (!privateChat.chats[action.payload.id]) {
        privateChat.chats[action.payload.id] = {
          group: action.payload.group,
          lastActivity: new Date().toISOString(),
          messages: [],
        };
      }

      privateChat.activeView = {
        name: 'personal',
        data: {
          id: action.payload.id,
          group: action.payload.group,
        },
      };
    },
    returnedToPrivateChatsView(state) {
      state.privateChat.activeView = {
        name: 'list',
        data: {},
      };
    },
    chatMessagesReset(state) {
      resetMessages(state);
    },
    unreadMessageCounterIncreased(
      state,
      action: PayloadAction<UnreadMessageCounterUpdatedPayload>
    ) {
      state.unreadCounters[action.payload.key] = state.unreadCounters[action.payload.key]
        ? state.unreadCounters[action.payload.key] + action.payload.modifier
        : action.payload.modifier;
    },
    messagesRead(
      state,
      action: PayloadAction<{
        messages: ChatMessageId[];
        chat?: string;
      }>
    ) {
      for (const messageId of action.payload.messages) {
        state.readMessages[messageId] = true;
      }

      if (action.payload.chat) {
        state.unreadCounters[action.payload.chat] = 0;
      }
    },
    publicChatCounterReset(state) {
      state.unreadCounters.public = 0;
    },
    groupChatCounterReset(state) {
      state.unreadCounters[state.privateChat.groupChat.id] = 0;
    },
    privateChatsCounterReset(state) {
      for (const key of Object.keys(state.unreadCounters)) {
        if (key !== 'public' && key !== state.privateChat.groupChat.id) {
          state.unreadCounters[key] = 0;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toolbarPanelOpened, (state, action) => {
        if (action.payload === 'chat') {
          state.panelOpen = true;
        }
      })
      .addCase(toolbarPanelClosed, (state) => {
        state.panelOpen = false;
      })
      .addCase(signalingRoomJoined, (state, action) => {
        state.localUserId = action.payload.user.id;
        state.localUserExternalId = action.payload.user.externalId;

        for (const message of action.payload.chatMessages) {
          processMessage(state, message);
        }

        setupUnreadCounters(state);
      })
      .addCase(signalingAllChatMessagesReceived, (state, action) => {
        const { messages } = action.payload;

        if (messages.length === 0) {
          resetMessages(state);
        } else {
          for (const message of action.payload.messages) {
            processMessage(state, message);
          }

          setupUnreadCounters(state);
        }
      })
      .addCase(signalingChatMessageDeleted, (state, action) => {
        deleteMessage(state, action.payload);
      })
      .addCase(senderKeyRatchetFinished, (state, action) => {
        if (action.payload === 'application') {
          state.messageSentWithCurrentKey = false;
        }
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        Object.assign(state.privateChat.groupChat, {
          name: action.payload.settings.privateGroupChatName,
          roles: action.payload.settings.privateGroupChatRoles,
        });
      });
  },
});

export const {
  sendChatMessage,
  messageSent,
  privateChatOpened,
  returnedToPrivateChatsView,
  chatMessagesReset,
  unreadMessageCounterIncreased,
  activeTabChanged,
  messagesRead,
  privateChatsCounterReset,
  publicChatCounterReset,
  groupChatCounterReset,
} = chatSlice.actions;

export const selectMessageEntities = (state: RootState) => state.chat.entities;
export const selectPublicChatMessageIds = (state: RootState) => state.chat.publicIds;

export const selectPublicChatMessages = createSelector(
  [selectMessageEntities, (state: RootState) => state.chat.publicIds],
  (byId, ids) => {
    const result = [];

    for (const id of ids) {
      result.push(byId[id]);
    }

    return result;
  }
);

export const selectPrivateChatEntities = (state: RootState) => state.chat.privateChat.chats;
export const selectActiveChatTab = (state: RootState) => state.chat.activeTab;
export const selectPrivateChatActiveView = (state: RootState) => state.chat.privateChat.activeView;
export const selectMessageSentWithCurrentKey = (state: RootState) =>
  state.chat.messageSentWithCurrentKey;

export const selectPrivateGroupChat = (state: RootState) => state.chat.privateChat.groupChat;

export const selectChatSenders = (state: RootState) => state.chat.senders;

export const selectPrivateChats = createSelector(
  [selectUsers, selectChatSenders, selectPrivateChatEntities],
  (userEntries, senders, chats) => {
    const result = [];

    for (const [targetChatId, chat] of Object.entries(chats)) {
      const activeUser = findChatTargetUser(userEntries, targetChatId);
      const targetUser = activeUser || senders[targetChatId];

      if (chat.messages?.length && !chat.group) {
        result.push({
          id: getPrivateChatSenderId(targetUser),
          lastActivity: chat.lastActivity,
          name: targetUser.name!,
          avatarColor: targetUser.avatarColor,
          avatarUrl: targetUser.avatarUrl,
          initials: targetUser.initials,
          disabled: !activeUser,
        });
      }
    }

    // order by recent conversation
    result.sort((a, b) => new Date(b.lastActivity).getTime() - new Date(a.lastActivity).getTime());

    return result;
  }
);

export const selectPrivateChat = createSelector(
  [
    selectMessageEntities,
    selectUsers,
    selectPrivateChatEntities,
    selectChatSenders,
    selectPrivateGroupChat,
    (state: RootState, targetChatId: UserId) => targetChatId,
  ],
  (
    messageEntries,
    userEntries,
    chats,
    senders,
    groupChat,
    targetChatId
  ): PrivateChatView | null => {
    const chat = chats[targetChatId];
    const messages = [];

    for (const id of chat.messages) {
      const message = messageEntries[id];
      messages.push(message);
    }

    if (chat.group) {
      return {
        id: groupChat.id,
        name: groupChat.name,
        group: chat.group,
        avatarColor: groupChat.avatarColor,
        messages,
        disabled: false,
      };
    }

    const activeUser = findChatTargetUser(userEntries, targetChatId);
    const targetUser = activeUser || senders[targetChatId];

    if (!targetUser) {
      return null;
    }

    return {
      id: getPrivateChatSenderId(targetUser),
      name: targetUser.name!,
      avatarColor: targetUser.avatarColor,
      avatarUrl: targetUser.avatarUrl,
      initials: targetUser.initials,
      targetId: targetUser.id,
      messages,
      disabled: !activeUser,
    };
  }
);

export const selectChatUnreadMessagesCount = (state: RootState) => {
  let total = 0;

  for (const n of Object.values(state.chat.unreadCounters)) {
    total += n;
  }

  return total;
};

export const selectChatUnreadMessagesCountByKey = (state: RootState, key: string) =>
  state.chat.unreadCounters[key] || 0;

export default chatSlice.reducer;
