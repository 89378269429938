import React, { useEffect, useRef } from 'react';
import {
  MenuList,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuListProps,
  PopperProps as MuiPopperProps,
  PaperProps as MuiPaperProps,
  PopperPlacementType,
} from '@mui/material';

import { useControlled } from '@mui/material/utils';
import { styled } from '@mui/material/styles';

export type MenuProps = MenuListProps & {
  trigger: React.ReactElement;
  id: string;
  open?: boolean;
  transformOrigin?: string;
  placement?: PopperPlacementType;
  PopperProps?: Omit<MuiPopperProps, 'role' | 'open' | 'placement'>;
  PaperProps?: MuiPaperProps;
  onClose?: (event: React.SyntheticEvent | Event) => void;
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  '& .MuiPopper-popper': {
    backfaceVisibility: 'hidden',
  },
}));

const transformOriginMap: Record<PopperPlacementType, string> = {
  'bottom-start': 'left top',
  'right-start': 'left top',
  auto: 'left top',
  'auto-start': 'left top',
  'bottom-end': 'right top',
  'left-start': 'right top',
  'top-start': 'left bottom',
  'right-end': 'left bottom',
  'auto-end': 'left bottom',
  'top-end': 'right bottom',
  'left-end': 'right bottom',
  right: 'left center',
  left: 'right center',
  bottom: 'center top',
  top: 'center bottom',
};

const Menu = React.memo(
  ({
    transformOrigin,
    children,
    id,
    trigger,
    placement = 'bottom-start',
    open: controlledOpen,
    PopperProps,
    PaperProps,
    onClose,
    className,
    ...props
  }: MenuProps) => {
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useControlled({
      controlled: controlledOpen,
      default: false,
      name: 'PopperMenu',
      state: 'open',
    });

    const handleClose = (event: Event | React.SyntheticEvent) => {
      if (anchorRef.current?.contains(event.target as HTMLElement)) {
        return;
      }

      setOpen(false);
      if (onClose && open) {
        onClose(event);
      }
    };

    const handleListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        if (onClose && open) {
          onClose(event);
        }
      } else if (event.key === 'Escape') {
        setOpen(false);
        if (onClose && open) {
          onClose(event);
        }
      }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current && !open) {
        anchorRef.current!.focus();
      }

      prevOpen.current = open;
    }, [open]);

    const { onClick, id: triggerId } =
      typeof trigger !== 'function' ? React.Children.only(trigger).props : ({} as any);

    const handleToggle = (event: React.SyntheticEvent | Event) => {
      setOpen((prevState) => !prevState);
      onClick?.(event);

      if (onClose && !controlledOpen && open) {
        onClose(event);
      }
    };

    const triggerProps = {
      'aria-describedby': id,
      'aria-haspopup': true,
      'aria-controls': open ? id : undefined,
      'aria-expanded': open ? true : undefined,
      ref: anchorRef,
      onClick: handleToggle,
    };

    return (
      <>
        {React.cloneElement(trigger, triggerProps)}
        <StyledPopper
          onResize={undefined}
          onResizeCapture={undefined}
          {...PopperProps}
          className={className}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 12],
              },
            },
            ...(PopperProps?.modifiers || []),
          ]}
          open={open}
          anchorEl={PopperProps?.anchorEl || anchorRef.current}
          role={undefined}
          placement={placement}
          transition={PopperProps?.transition || true}
        >
          {({ TransitionProps, placement: innerPlacement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: transformOrigin || transformOriginMap[innerPlacement],
              }}
            >
              <div className="MuiPopper-popper">
                <Paper elevation={3} {...PaperProps}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id={id}
                      aria-labelledby={triggerId}
                      {...props}
                      onKeyDown={handleListKeyDown}
                    >
                      {React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                          return React.cloneElement(child, {
                            onClick: (event: Event | React.SyntheticEvent) => {
                              handleClose(event);
                              child.props.onClick?.(event);
                            },
                          });
                        }

                        return child;
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </div>
            </Grow>
          )}
        </StyledPopper>
      </>
    );
  }
);

export default Menu;
