import { speechCaptioned } from 'features/captions/actions';
import {
  signalingFileClosed,
  signalingFileOpened,
  signalingFilesReceived,
} from 'features/content-library/actions';
import {
  signalingWhiteboardChanged,
  signalingWhiteboardClosed,
  signalingWhiteboardCursorUpdated,
  signalingWhiteboardOpened,
} from 'features/layout/features/content/actions';
import {
  streamStarted,
  streamStopped,
  screenshareStarted,
  screenshareStopped,
  activeSpeakerChanged,
  audioMutedRemotely,
  audioUnmuteAsked,
  videoUnmuteAsked,
  videoMutedRemotely,
} from 'features/streaming/actions';
import { ActionCreator } from '@reduxjs/toolkit';
import { SignalingReceivedEventType } from 'services/signaling/types';
import {
  signalingConnected,
  pong,
  signalingDisconnected,
  systemUpdate,
} from 'features/application/actions';
import {
  participantsLimitReached,
  sessionEnded,
  sessionLeft,
  signalingBroadcasterLimitReached,
  signalingEntryAccessAwaitingStarted,
  signalingRoomJoined,
  signalingRoomJoinPaused,
  signalingRoomLocked,
  signalingRoomUnlocked,
  signalingSessionAwaitingStarted,
} from 'features/room/actions';
import {
  signalingUserJoined,
  signalingUserLeft,
  signalingHandLowered,
  signalingHandRaised,
  signalingUserKicked,
  signalingWaitingUserJoined,
  signalingWaitingUserLeft,
  signalingWaitingUsersUpdated,
  signalingBulkAccessRejected,
  signalingAccessRejected,
} from 'features/users/actions';
import {
  signalingRecorderJoined,
  signalingRecordingFailed,
  signalingRecordingRequested,
  signalingRecordingStarted,
  signalingRecordingStopped,
  signalingRecordingStopRequested,
} from 'features/recorder/actions';
import {
  signalingAllChatMessagesReceived,
  signalingChatMessageDeleted,
  signalingChatMessageSent,
} from 'features/chat/actions';
import {
  signalingBroadcastAllowed,
  signalingBroadcastDisallowed,
  signalingEditWhiteboardAllowed,
  signalingEditWhiteboardDisallowed,
  signalingRoleChanged,
  signalingScreenshareAllowed,
  signalingScreenshareDisallowed,
} from 'features/permissions/actions';
import {
  signalingEncryptedNameShared,
  signalingEncryptionKeyShared,
  signalingPublicKeyShared,
} from 'features/e2ee/actions';
import {
  signalingBreakoutRoomAdded,
  signalingBreakoutRoomDeleted,
  signalingBreakoutRoomMessageBroadcast,
  signalingBreakoutRoomRenamed,
  signalingBreakoutRoomsCleared,
  signalingBreakoutRoomsCreated,
  signalingBreakoutRoomsReceived,
  signalingBreakoutRoomsStarted,
  signalingBreakoutRoomsStopped,
  signalingBreakoutUserJoined,
  signalingBreakoutUserLeft,
  signalingUsersAssignedToBreakoutRoom,
  signalingUserUnassignedFromBreakoutRoom,
} from 'features/breakout-rooms/actions';
import { fileUploadPrepared } from 'features/whiteboard/actions';
import {
  signalingAnswerDeleted,
  signalingQuestionAnswered,
  signalingQuestionAsked,
  signalingQuestionDeleted,
  signalingQuestionLiveAnswerCancelled,
  signalingQuestionLiveAnswerStarted,
  signalingQuestionLiveAnswerStopped,
} from 'features/qa/actions';
import { signalingFeatureDisabled, signalingFeatureEnabled } from 'features/feature-set/actions';

export const eventMap: Record<SignalingReceivedEventType, ActionCreator<any>> = {
  pong,
  streamStarted,
  streamStopped,
  screenshareStarted,
  screenshareStopped,
  activeSpeakerChanged,
  sessionEnded,
  sessionLeft,
  participantsLimitReached,
  userLeft: signalingUserLeft,
  userJoined: signalingUserJoined,
  connected: signalingConnected,
  roomJoined: signalingRoomJoined,
  disconnect: signalingDisconnected,
  audioMutedRemotely,
  askedToUnmuteAudioRemotely: audioUnmuteAsked,
  videoMutedRemotely,
  askedToUnmuteVideoRemotely: videoUnmuteAsked,
  recordingStarted: signalingRecordingStarted,
  recordingRequested: signalingRecordingRequested,
  recordingStopRequested: signalingRecordingStopRequested,
  recordingFailure: signalingRecordingFailed,
  recordingStopped: signalingRecordingStopped,
  recorderJoined: signalingRecorderJoined,
  chatMessageSent: signalingChatMessageSent,
  chatMessageDeleted: signalingChatMessageDeleted,
  allChatMessagesReceived: signalingAllChatMessagesReceived,
  speechCaptioned,
  handRaised: signalingHandRaised,
  handLowered: signalingHandLowered,
  kicked: signalingUserKicked,
  broadcastAllowed: signalingBroadcastAllowed,
  broadcastDisallowed: signalingBroadcastDisallowed,
  screenshareAllowed: signalingScreenshareAllowed,
  screenshareDisallowed: signalingScreenshareDisallowed,
  roleChanged: signalingRoleChanged,
  systemUpdate,
  sessionNotStarted: signalingSessionAwaitingStarted,
  encryptionKeyShared: signalingEncryptionKeyShared,
  entryAccessRequired: signalingEntryAccessAwaitingStarted,
  roomLocked: signalingRoomLocked,
  roomUnlocked: signalingRoomUnlocked,
  waitingUserJoined: signalingWaitingUserJoined,
  waitingUserLeft: signalingWaitingUserLeft,
  waitingUsersUpdated: signalingWaitingUsersUpdated,
  bulkUserAccessRejected: signalingBulkAccessRejected,
  accessRejected: signalingAccessRejected,
  publicKeyShared: signalingPublicKeyShared,
  encryptedNameShared: signalingEncryptedNameShared,
  whiteboardOpened: signalingWhiteboardOpened,
  whiteboardClosed: signalingWhiteboardClosed,
  whiteboardUpdated: signalingWhiteboardChanged,
  whiteboardCursorUpdated: signalingWhiteboardCursorUpdated,
  editWhiteboardAllowed: signalingEditWhiteboardAllowed,
  editWhiteboardDisallowed: signalingEditWhiteboardDisallowed,
  roomJoinPaused: signalingRoomJoinPaused,
  broadcasterLimitReached: signalingBroadcasterLimitReached,
  fileUploadPrepared,
  breakoutRoomsCreated: signalingBreakoutRoomsCreated,
  breakoutRoomAdded: signalingBreakoutRoomAdded,
  breakoutRoomsReceived: signalingBreakoutRoomsReceived,
  breakoutRoomsCleared: signalingBreakoutRoomsCleared,
  breakoutRoomDeleted: signalingBreakoutRoomDeleted,
  breakoutRoomRenamed: signalingBreakoutRoomRenamed,
  breakoutRoomsStarted: signalingBreakoutRoomsStarted,
  breakoutRoomsStopped: signalingBreakoutRoomsStopped,
  usersAssignedToBreakoutRoom: signalingUsersAssignedToBreakoutRoom,
  userUnassignedFromBreakoutRoom: signalingUserUnassignedFromBreakoutRoom,
  breakoutRoomMessageBroadcasted: signalingBreakoutRoomMessageBroadcast,
  breakoutUserJoined: signalingBreakoutUserJoined,
  breakoutUserLeft: signalingBreakoutUserLeft,
  questionAsked: signalingQuestionAsked,
  questionAnswered: signalingQuestionAnswered,
  questionDeleted: signalingQuestionDeleted,
  answerDeleted: signalingAnswerDeleted,
  questionLiveAnswerStarted: signalingQuestionLiveAnswerStarted,
  questionLiveAnswerStopped: signalingQuestionLiveAnswerStopped,
  questionLiveAnswerCancelled: signalingQuestionLiveAnswerCancelled,
  featureEnabled: signalingFeatureEnabled,
  featureDisabled: signalingFeatureDisabled,
  filesReceived: signalingFilesReceived,
  fileOpened: signalingFileOpened,
  fileClosed: signalingFileClosed,
};
