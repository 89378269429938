import { PermissionsMap, Role, TargetRolesMap } from 'features/permissions/types';
import i18n from 'i18n';
import { getDisplayNameLocaleKey } from 'features/permissions/utils/getDisplayNameLocaleKey';

export const normalizePermissions = (roles: Role[]) => {
  const permissionsMap: PermissionsMap = {};
  const targetRolesMap: TargetRolesMap = {};
  const byRole: Record<string, Role> = {};
  const allRoles = new Set<string>();

  for (const role of roles) {
    const displayNameKey = role.displayName
      .trim()
      .toLowerCase()
      .replaceAll('.', '')
      .replaceAll(/\s+/g, '_');

    const localizationKey = getDisplayNameLocaleKey(displayNameKey);
    if (i18n.exists(localizationKey)) {
      role.displayNameKey = displayNameKey;
      role.displayName = i18n.t(localizationKey);
    }

    byRole[role.name] = role;
    allRoles.add(role.name);
    permissionsMap[role.name] = permissionsMap[role.name] || {};
    targetRolesMap[role.name] = targetRolesMap[role.name] || {};

    for (const permission of role.permissions) {
      if (!permission.targetRoles) {
        permissionsMap[role.name][permission.name] = true;
      } else {
        targetRolesMap[role.name][permission.name] = permission.targetRoles;

        for (const targetRole of permission.targetRoles) {
          permissionsMap[role.name][`${permission.name}_${targetRole}`] = true;
        }
      }
    }
  }

  return {
    permissionsMap,
    byRole,
    allRoles: Array.from(allRoles),
    targetRolesMap,
  };
};
